interface IErrorsMap {
	[index: string]: string;
}

export const notificationsMessagesError: IErrorsMap = {
	the_totp_field_is_required: 'Please enter 2FA code from Authenticator App.',
	invalid_totp_code: 'Invalid totp code.',
	error_blocked: 'Error blocked.',
	the_captcha_field_is_required: 'Please complete the captcha field.',
	quantity_must_be_greater_than_00005_for_base_currency_btc:
		'Quantity must be greater than 0.0005 for base currency btc.',
	not_verified: 'Not verified.',
	// notificationsMessagesPopUp
	bankDelete: 'Bank account has not been deleted.',
	bankSuccessfully: 'Bank account has been added successfully.',
	bankNotCreated: 'Bank account has not been created.',
	bankNotUpdate: 'Bank account has not been updated.',
	bonusCompleted: 'Bonus transfer has been completed.',
	walletSuccessfully: 'Wallet address has been updated successfully.',
	walletUpdated: 'Wallet address has not been updated.',
	walletDeleted: 'Wallet address has not been deleted.',
	walletSuccessfullyCreated: 'Wallet address has been created successfully.',
	walletSuccessfullyNotCreated: 'Wallet address has not been created.',
	// notificationsMessagesError
	bankAccounts: 'Bank accounts were not found.',
	bonusFound: 'Bonus wallets were not found.',
	referralsFound: 'Referrals were not found.',
	transactionFailed: 'Transaction failed.',
	enterTwoFa: 'Please enter your 2FA code.',
	walletAddress: 'Wallet address was not found.',
	walletAddressDeleted: 'Wallet address has not been deleted.',
	// notificationsMessagesErrorServers
	invalid_vault_id: 'Invalid Vault ID.',
	empty_fireblock_vault_id_in_user: 'Empty Fireblocks Vault ID in User.',
	zero_asset_balance: 'Zero asset balance.',
	referral_already_exists: 'Referral already exists.',
	error_while_deleting: 'Error while deleting.',
	action_rejected: 'Action rejected.',
	transaction_is_not_in_pending_status: 'Transaction is not in pending status.',
	the_frozen_balance_is_less_than_the_withdrawal_amount:
		'The frozen balance is less than the withdrawal amount.',
	user_already_rejected: 'User already rejected.',
	user_already_approved: 'User already approved.',
	user_not_verified: 'User not verified.',
	two_fa_already_disabled: '2FA already disabled.',
	the_balance_is_less_than_the_amount: 'The balance is less than the amount.',
	the_frozen_balance_is_less_than_the_amount: 'The frozen balance is less than the amount.',
	min_limit: 'Minimum limit.',
	max_limit: 'Maximum limit.',
	you_can_not_exchange_the_same_asset: 'The same asset cannot be exchanged.',
	asset_is_not_exchangeable: 'Asset is not exchangeable.',
	deny_exchange_fiat_to_fiat: 'Fiat to fiat exchange is denied.',
	wrong_asset_for_exchange: 'Wrong asset for exchange.',
	External_order_creation_error: `External order creation error.`,
	exchange_rate_not_available: 'Value must be between 0 – 100.',
	Value_must_be_between_0_100: `Value must be between 0 – 100.`,
	transaction_error: 'Transaction error.',
	asset_is_not_withdrawable: 'The asset cannot be withdrawn.',
	minimum_limit: 'Minimum limit.',
	hour_limit: 'Hourly limit.',
	daily_limit: 'Daily limit.',
	asset_is_not_depositable: 'The asset cannot be deposited.',
	invalid_transaction_type: 'Invalid transaction type.',
	invalid_transaction_id: 'Invalid transaction ID',
	you_can_update_only_withdraw_address: 'Only withdrawal addresses can be updated.',
	you_can_delete_only_withdraw_address: 'Only withdrawal addresses can be deleted.',
	fireblocks_error: 'Fireblocks error.',
	max_amount_must_be_lower_or_equal_2: 'Maximum amount must be lower or equal to 2.',
	confirmation_is_not_finished: 'Verification is not finished.',
};
export const Errors: IErrorsMap = {
	already_exist: 'Already exist.',
	two_fa_already_disabled: 'Two fa already disabled.',
};
