export enum ApiUserStatus {
	Unverifed = 1,
	Verifed = 2,
	Pending = 3,
	Rejected = 4,
}

export const statusNames: Record<number, string> = {
	[ApiUserStatus.Unverifed]: 'Unverifed',
	[ApiUserStatus.Verifed]: 'Verifed',
	[ApiUserStatus.Pending]: 'Pending',
	[ApiUserStatus.Rejected]: 'Rejected',
};

export const statusClassNames: Record<number, string> = {
	[ApiUserStatus.Unverifed]: 'red-bg',
	[ApiUserStatus.Verifed]: 'green-bg',
	[ApiUserStatus.Pending]: 'yellow-bg',
	[ApiUserStatus.Rejected]: 'red-bg',
};
