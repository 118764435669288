import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Auth from 'layouts/Auth';
import CurrencySelect from 'ui/Formik/Select/CurrencySelect';
import { ICurrencySelectItem } from 'ui/Formik/Select/CurrencySelect/types';
import IconSvg from 'ui/Svg/IconSvg';

import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { getCryptoCurrencyData } from 'redux/reducers/currency/selectors';

import { getDepositAddress, getFee } from 'redux/reducers/deposits/selectors';
import {
	clearDepositAddress,
	depositAddressRequest,
	feeRequest,
} from 'redux/reducers/deposits/reducer';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import { roundingNumber } from 'services/utils/roundingNumber';
import { getFeesByAssetRequest } from 'redux/reducers/transactions/reducer';
import { getTradeInfo } from 'redux/reducers/transactions/selectors';

const DepositCryptoPage = () => {
	const dispatch = useDispatch();
	const cryptoCurrency = useSelector(getCryptoCurrencyData);
	const depositAddress = useSelector(getDepositAddress);
	const tradeInfo = useSelector(getTradeInfo);
	const [selectedCoin, setSelectedCoin] = useState<ICurrencySelectItem | null>(null);
	const [selectedNetwork, setSelectedNetwork] = useState<ICurrencySelectItem | null>(null);
	const [resetCustomSelect, setResetCustomSelect] = useState(false);
	const handleCopy = () => {
		notificationContainer('Сopied to clipboard', 'info');
	};

	const btcNetworksArr = [
		{
			id: 1,
			code: 'Legacy',
			name: 'Bitcoin Legacy',
		},
		{
			id: 2,
			code: 'Segwit',
			name: 'Bitcoin Segwit',
		},
	];

	useEffect(() => {
		if (selectedNetwork?.pivot) {
			dispatch(depositAddressRequest(selectedNetwork.pivot));
		}
	}, [dispatch, selectedNetwork]);

	useEffect(() => {
		setResetCustomSelect(true);
		return () => {
			dispatch(clearDepositAddress());
		};
	}, [dispatch, selectedCoin]);

	useEffect(() => {
		dispatch(currencyDataRequest());
		if (localStorage.asset && cryptoCurrency) {
			const currentCoin =
				cryptoCurrency.find(
					(el: ICurrencySelectItem) => el.id === Number(JSON.parse(localStorage.asset).asset_id),
				) || null;
			setSelectedCoin(currentCoin);
		}
		return () => {
			localStorage.removeItem('asset');
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedCoin) {
			dispatch(getFeesByAssetRequest(selectedCoin.id));
			setSelectedNetwork(selectedCoin.code === 'btc' ? null : selectedCoin?.chains?.[0] || null);
		}
	}, [selectedCoin, dispatch]);

	const fee = useSelector(getFee);
	const x = 0; // узнать бэк
	if (x !== 0) {
		const payload = {
			asset_id: selectedCoin?.id,
			type: 'deposit',
			amount: x,
		};
		dispatch(feeRequest(payload));
	}

	const handleGetBtcAddress = (code: string) => {
		if (code === 'Legacy') {
			return selectedCoin?.addresses?.legacy || '';
		}
		if (code === 'Segwit') {
			return selectedCoin?.addresses?.segwit_address || '';
		}
		return '';
	};

	return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					<div className="wallet-operations">
						<div className="wallet-operations-header-wrap">
							<div className="wallet-operations-header">
								<Link to="/wallets" className="wallet-operations-header__back">
									<IconSvg name="arrow-left" w="24" h="24" />
								</Link>
								<p>Deposit Crypto</p>
							</div>
							<div className="wallet-operations-header-right">
								<Link to="/deposit-fiat" className="button button--size2 button--type2">
									Deposit Fiat
								</Link>
								<Link to="/deposit-fiat" className="wallet-operations-header__back">
									<IconSvg name="arrow-right" w="24" h="24" />
								</Link>
							</div>
						</div>
						<div className="wallet-operations-item coin__text-normal">
							<div className="wallet-operations-item__title">
								<span>1</span>
								<p>Select Cryptocurrency</p>
							</div>
							<CurrencySelect
								type="text"
								dropdownTitle="Select Currency"
								placeholder="Select Currency"
								arr={cryptoCurrency.filter((el) => el.depositable)}
								onChange={setSelectedCoin}
								activeValue={selectedCoin || undefined}
							/>
							{!!selectedCoin && (
								<>
									<div className="select-notification">
										<p>
											Minimum Deposit:{' '}
											<span>
												{roundingNumber(selectedCoin?.deposit_min, selectedCoin?.code)}{' '}
												{selectedCoin?.code.toUpperCase()}
											</span>
										</p>
									</div>
									<div className="select-notification">
										<p>
											Deposit Fee:{' '}
											<span>
												{roundingNumber(tradeInfo?.deposit_fee_fixed, selectedCoin?.code)}{' '}
												{selectedCoin?.code.toUpperCase()}
											</span>
										</p>
									</div>
								</>
							)}
						</div>
						{selectedCoin && (
							<div className="wallet-operations-item coin__text-normal">
								<div className="wallet-operations-item__title">
									<span>2</span>
									<p>Select Network</p>
								</div>
								<CurrencySelect
									type="text"
									dropdownTitle="Select Network"
									placeholder="Select Network"
									arr={selectedCoin.code === 'btc' ? btcNetworksArr : selectedCoin?.chains}
									onChange={setSelectedNetwork}
									resetCustomSelect={resetCustomSelect}
									setResetCustomSelect={setResetCustomSelect}
									activeValue={selectedNetwork || undefined}
								/>
								<div className="error-block">
									<div className="error-block__icon">
										<IconSvg name="error-circle" w="20" h="20" />
									</div>
									<p>
										Ensure the deposit network is correct or assets will be lost. If in doubt, send
										a small test first.
									</p>
								</div>
							</div>
						)}
						{(selectedCoin?.code === 'btc' ? !!selectedNetwork : !!depositAddress) && (
							<>
								<div className="wallet-operations-item">
									<div className="deposit-address">
										<div className="wallet-operations-item__title">
											<span>3</span>
											<p>Deposit Address</p>
										</div>
										<div className="deposit-address__text deposit-address__text--with-warning">
											<p>
												{selectedCoin?.code === 'btc'
													? handleGetBtcAddress(selectedNetwork?.code || '')
													: depositAddress?.address || ''}
											</p>
											<CopyToClipboard
												text={
													selectedCoin?.code === 'btc'
														? handleGetBtcAddress(selectedNetwork?.code || '')
														: depositAddress?.address || ''
												}
												onCopy={handleCopy}
											>
												<button type="button">
													<IconSvg name="copy" w="24" h="24" />
												</button>
											</CopyToClipboard>
										</div>
										<div className="error-block">
											<div className="error-block__icon">
												<IconSvg name="error-circle" w="20" h="20" />
											</div>
											<p>
												If this is your first time depositing, please ensure you send a small test
												transfer first.
											</p>
										</div>
									</div>
								</div>
								{depositAddress?.tag && (
									<div className="wallet-operations-item">
										<div className="deposit-address deposit-address--memo">
											<div className="wallet-operations-item__title">
												<span>4</span>
												<p>MEMO</p>
											</div>
											<div className="deposit-address__text deposit-address__text--with-warning">
												<p>{depositAddress.tag}</p>
												<CopyToClipboard text={depositAddress?.tag || ''} onCopy={handleCopy}>
													<button type="button">
														<IconSvg name="copy" w="24" h="24" />
													</button>
												</CopyToClipboard>
											</div>
											<div className="error-block">
												<div className="error-block__icon">
													<IconSvg name="error-circle" w="20" h="20" />
												</div>
												<p>Memo is required or asset will be lost.</p>
											</div>
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</section>
		</Auth>
	);
};

export default DepositCryptoPage;
