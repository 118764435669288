import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCryptoHistoryRequest } from 'redux/reducers/transactions/reducer';
import {
	getCryptoHistoryList,
	getCryptoHistoryPageCount,
	getCryptoHistoryTotal,
} from 'redux/reducers/transactions/selectors';
import { IGetTransactionHistoryRequestPayload } from 'redux/reducers/transactions/types';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import {
	downloadDepositWithdrawsRequest,
	downloadKeyRequest,
} from 'redux/reducers/download/reducer';
import Filters from '../Filters/Filters';
import Pagination from '../Pagination/Pagination';
import TableBody from './TableBody/TableBody';
import TableHeader from './TableHeader/TableHeader';
import { ILocalFilter } from './types';
import IconSvg from '../../../ui/Svg/IconSvg';
import { IDownloadPayload } from '../../../redux/reducers/download/types';
import { getDownloadKey } from '../../../redux/reducers/download/selectors';
import { getUserData } from '../../../redux/reducers/auth/selectors';

const Crypto = () => {
	const dispatch = useDispatch();
	const key = useSelector(getDownloadKey);
	const user = useSelector(getUserData);
	const [filters, setFilters] = useState({
		date_from: undefined,
		date_to: undefined,
		currency: undefined,
		type: undefined,
		status: undefined,
		tx_id: undefined,
	});
	const [localFilter, setLocalFilter] = useState<ILocalFilter>({
		value: 'all',
		state: 0,
	});
	const changeFilter = (changeFilterValue: string) => {
		if (localFilter.state === 0) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? +localFilter.state + 1 : 1,
			});
		}
		if (localFilter.state === 1) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? +localFilter.state + 1 : 1,
			});
		}
		if (localFilter.state === 2) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? 0 : 1,
			});
		}
	};
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => setPerPage(value);
	const itemList = useSelector(getCryptoHistoryList);
	const pageCount = useSelector(getCryptoHistoryPageCount);
	const total = useSelector(getCryptoHistoryTotal);
	const [currentPage, setCurrentPage] = useState(1);
	const [firstLoading, setFirstLoading] = useState(true);
	useEffect(() => {
		const math = Math.round(total / Number(perPage));
		const params: IGetTransactionHistoryRequestPayload = {
			apiParams: {
				current_page: math + 1 >= currentPage ? currentPage : 1,
				per_page: perPage === undefined ? 100000000 : perPage,
				...filters,
			},
			onFinally: () => setFirstLoading(false),
		};

		if (params.apiParams?.current_page === 1) {
			setCurrentPage(1);
		}
		dispatch(getCryptoHistoryRequest(params));
	}, [dispatch, currentPage, perPage, filters, total]);

	const [fileTypeState, setFileTypeState] = useState('');
	const handleGetFile = (fileType: string) => {
		dispatch(downloadKeyRequest());
		setFileTypeState(fileType);
	};
	const firstUpdate = useRef(true);
	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		setTimeout(() => {
			const params: IDownloadPayload = {
				apiParams: {
					idUser: Number(user?.id),
					fileName: fileTypeState,
					key,
					currency: 'crypto',
				},
				// onFinally: () => setFirstLoading(false),
			};
			dispatch(downloadDepositWithdrawsRequest(params));
		}, 100);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [key]);
	return (
		<>
			<Filters
				filters={filters}
				onChange={setFilters}
				type="crypto"
				setCurrentPage={setCurrentPage}
			/>

			<div className="table-block table-block--mt-0">
				<div className="table-wrapper">
					<div className="table table--transaction-history-crypto">
						<TableHeader localFilter={localFilter} changeFilter={changeFilter} />

						{!firstLoading && <TableBody localFilter={localFilter} itemList={itemList} />}
					</div>
					<div className="table-footer">
						<div className="select-page">
							<PerPageSelect onChange={handleSetPerPage} />
						</div>

						<div className="pagination-block">
							{!firstLoading && pageCount > 1 && (
								<div className="pagination-block">
									<Pagination
										pageCount={pageCount}
										currentPage={currentPage}
										onPageChange={setCurrentPage}
									/>
								</div>
							)}
						</div>
						<div className="button-wrap">
							<button
								onClick={() => handleGetFile('csv')}
								type="button"
								className="button button--size2 button--type2"
							>
								<IconSvg name="csv" w="10" h="5" />
								Download
							</button>
							<button
								onClick={() => handleGetFile('xlsx')}
								type="button"
								className="button button--size2 button--type2"
							>
								<IconSvg name="xlsx" w="10" h="5" />
								Download
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Crypto;
