import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getverificationFiles } from '../auth/reducer';
import { popUpOpen } from '../popUp/reducer';
import {
	disableTwoFaRequest,
	settingsInitState,
	verificationFilesRequest,
	verificationFilesSuccess,
} from './reducer';
import { IVerificationFilesRequestPayloadData } from './types';

// =============================================================:

// =============================================================:
function* verificationFilesWorker(action: PayloadAction<IVerificationFilesRequestPayloadData>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.settings.verification, payload);
		// notificationContainer(notificationsMessagesInfo.documentsSent, 'success');
		yield put(verificationFilesSuccess());
		yield put(getverificationFiles());
		yield put(popUpOpen('verificationSuccess'));
		// payload.history.push('/');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data.errors[0] === 'user_already_approved') {
				notificationContainer('Your account was successfully verified.', 'info');
			} else {
				yield put(popUpOpen('verificationError'));
			}
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* disableTwoFaWorker(action: any) {
	const { payload } = action;
	try {
		yield call(api.settings.disableTwoFa, { totp: payload.totp });
		payload.setTotpForm(false);
		payload.setTwoFaStatus(false);
		notificationContainer('Disable 2FA success.', 'success');
		yield put(showLoading());
	} catch (error) {
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
export function* settingsSaga() {
	yield takeEvery(verificationFilesRequest.type, verificationFilesWorker);
	yield takeEvery(disableTwoFaRequest.type, disableTwoFaWorker);
}
