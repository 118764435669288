import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { popUpOpen } from 'redux/reducers/popUp/reducer';
import { changeAsset } from 'redux/reducers/trade/reducer';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { roundingNumber } from 'services/utils/roundingNumber';

const WalletsTableItem: FC<IWalletItem> = ({ id, asset, balance, frozen_balance, balance_eur }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const type = asset.type === 'fiat' ? 'fiat' : 'crypto';

	const handleClickToDepositPage = useCallback(() => {
		history.push(`deposit-${type}`);
		localStorage.asset = JSON.stringify({ asset_id: asset.id });
	}, [asset.id, history, type]);

	const handleClickToFiatPage = useCallback(() => {
		history.push(`withdraw-${type}`);
		localStorage.asset = JSON.stringify({ asset_id: id });
	}, [history, type, id]);

	const handleClickToTrade = () => {
		dispatch(popUpOpen('goToTrade'));
		dispatch(changeAsset({ type: 'from', asset_id: String(asset.id), asset_code: asset.code }));
		const fromToAsset =
			asset.type === 'fiat'
				? { asset_id: '1', asset_code: 'BTC' }
				: { asset_id: '10', asset_code: 'EUR' };
		dispatch(changeAsset({ type: 'to', ...fromToAsset }));
	};

	return (
		<div>
			<div className="tr">
				<div className="td">
					<div className="td-hidden-name">{asset.name}</div>
					<span className="coin coin--type3 coin--gap-8">
						<span className="coin__icon">
							{/* TODO: Update image path on backend! Replaced it here for now. */}
							<img
								src={asset.img_path.replace(
									'oneify-stage.corp.merehead.xyz',
									'dev1.oneify.cybro.cz',
								)}
								alt={asset.code}
							/>
						</span>
						<span className="coin__text coin__text--fw-400 coin__text---upper">
							{asset.code} <br />
							<span className="coin__text-more coin__text-more--type2">{asset.name}</span>
						</span>
					</span>
				</div>
				<div className="td">
					<div className="td-hidden-name">Balance</div>
					<p>{roundingNumber(Number(balance) + Number(frozen_balance), asset.code)}</p>
				</div>
				<div className="td">
					<div className="td-hidden-name">Available</div>
					<p>{roundingNumber(balance, asset.code)}</p>
				</div>
				<div className="td">
					<div className="td-hidden-name">Pending</div>
					<p>{roundingNumber(frozen_balance, asset.code)}</p>
				</div>
				<div className="td">
					<div className="td-hidden-name">EUR Value</div>
					<p>
						{roundingNumber(
							Number((Number(balance) + Number(frozen_balance)).toFixed(6).slice(0, -1)) > 0
								? balance_eur
								: 0,
							'EUR',
						)}{' '}
						EUR
					</p>
				</div>
				<div className="td">
					<div className="flex flex-e flex--gap-24">
						<button
							type="button"
							className="button button--type-text"
							onClick={handleClickToDepositPage}
							disabled={!asset.depositable}
						>
							Deposit
						</button>

						<button
							type="button"
							className="button button--type-text"
							onClick={handleClickToFiatPage}
							disabled={!asset.withdrawable}
						>
							Withdraw
						</button>

						<button
							type="button"
							className="button button--type-text"
							onClick={handleClickToTrade}
							disabled={!asset.exchangeable}
						>
							Trade
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WalletsTableItem;
