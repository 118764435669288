import { FC } from 'react';
import Auth from 'layouts/Auth';
import TwoFaSettings from 'components/TwoFaSettings/TwoFaSettings';

// ==========================================:
const TwoFaSettingsPage: FC = () => {
	return (
		<Auth title="2fa">
			<TwoFaSettings />
		</Auth>
	);
};
export default TwoFaSettingsPage;
