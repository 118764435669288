import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';

import Auth from 'layouts/Auth';
import { currencyDataRequest, feeDataRequest } from 'redux/reducers/currency/reducer';
import { postFiatDepositInvoiceCreateRequest } from 'redux/reducers/transactions/reducer';
import { getFees, getFiatCurrencyData } from 'redux/reducers/currency/selectors';
import { ICurrencySelectItem } from 'ui/Formik/Select/CurrencySelect/types';
import CurrencySelect from 'ui/Formik/Select/CurrencySelect';
import Input from 'ui/Formik/Input';
import IconSvg from 'ui/Svg/IconSvg';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import InvoiceGenerate from 'layouts-elements/PopUp/InvoiceGenerate/InvoiceGenerate';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IFiatDepositGetFile } from 'services/api/transactions/types';
import { getFee } from '../../../redux/reducers/deposits/selectors';
import { feeRequest } from '../../../redux/reducers/deposits/reducer';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';

const DepositFiatPage = () => {
	const dispatch = useDispatch();
	const fees = useSelector(getFees);

	const fiatCurrency = useSelector(getFiatCurrencyData);
	const [selectedFiat, setSelectedFiat] = useState<ICurrencySelectItem | null>(null);
	const [fiatCode, setFiatCode] = useState('');
	const [resetCustomSelect, setResetCustomSelect] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [fiatCoinPercent, setFiatCoinPercent] = useState<number>(0);
	const closeModal = () => {
		setOpenModal(false);
		// history.push(ROUTES.WALLETS);
	};
	const depositMin = Number(selectedFiat?.deposit_min);
	const depositMax = Number(selectedFiat?.deposit_max);

	const initialValues = {
		currency: '',
		amount: '',
	};

	const validationSchema = yup.object().shape({
		currency: yup.string().required(notificationsInfoFields.currency.required),
		amount: yup
			.number()
			.required(notificationsInfoFields.amount.required)
			.min(depositMin, `${notificationsInfoFields.amount.min} ${depositMin}`)
			.max(depositMax, `${notificationsInfoFields.amount.max} ${depositMax}`),
	});

	const getCalcAmount = (value: string) => {
		const numValue = Number(value);
		let percent = 0;
		let receivedFee = 0;

		if (selectedFiat && fees) {
			percent = fees[selectedFiat.id].deposit_fee_percent;
			const percentFix = fees[selectedFiat.id].deposit_fee_fixed;
			receivedFee = (percent * numValue) / 100;
			setFiatCoinPercent(receivedFee);
			if (receivedFee < percentFix) {
				receivedFee = percentFix;
			}
		}
		const fee = receivedFee > 0 ? receivedFee : '0';
		const receive = numValue - receivedFee > 0 ? numValue - receivedFee : '0';
		return { fee, receive };
	};

	const transactionSubmit = (obj: IFiatDepositGetFile) => {
		dispatch(postFiatDepositInvoiceCreateRequest(obj));
	};

	useEffect(() => {
		dispatch(currencyDataRequest());
		dispatch(feeDataRequest());
		if (localStorage.asset && fiatCurrency) {
			const currentFiat =
				fiatCurrency.find(
					(el: ICurrencySelectItem) => el.id === Number(JSON.parse(localStorage.asset).asset_id),
				) || null;

			if (currentFiat) {
				setSelectedFiat(currentFiat);
			}
		}
		return () => {
			localStorage.removeItem('asset');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!localStorage.asset) {
			const currentFiat =
				fiatCurrency.find((el: ICurrencySelectItem) => el.code.toLowerCase() === 'eur') || null;
			if (currentFiat) {
				setSelectedFiat(currentFiat);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fiatCurrency]);

	useEffect(() => {
		if (selectedFiat) {
			setFiatCode(selectedFiat?.code.toUpperCase());
		}
	}, [selectedFiat]);

	const fee = useSelector(getFee);
	return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					<div className="wallet-operations">
						<div className="wallet-operations-header-wrap">
							<div className="wallet-operations-header">
								<Link to="/wallets" type="button" className="wallet-operations-header__back">
									<IconSvg name="arrow-left" w="24" h="24" />
								</Link>
								<p>Deposit Fiat</p>
							</div>
							<div className="wallet-operations-header-right">
								<Link to="/deposit-crypto" className="button button--size2 button--type2">
									Deposit Crypto
								</Link>
								<Link to="/deposit-crypto" className="wallet-operations-header__back">
									<IconSvg name="arrow-right" w="24" h="24" />
								</Link>
							</div>
						</div>
						<Formik
							validationSchema={validationSchema}
							initialValues={initialValues}
							onSubmit={(values, { resetForm, setSubmitting }) => {
								const amount = getCalcAmount(values.amount);
								transactionSubmit({
									asset_id: Number(selectedFiat?.id),
									amount: Number(values.amount),
									fee: Number(amount.fee),
								});
								setOpenModal(true);
								// resetForm();
								setSubmitting(false);
								// setResetCustomSelect(true);
								// setSelectedFiat(null);
							}}
						>
							{({ isSubmitting, resetForm, setSubmitting, isValid, dirty, values }) => {
								const amount = getCalcAmount(values.amount);
								if (
									Number(values.amount) !== 0 &&
									selectedFiat?.id !== undefined &&
									values.amount !== ''
								) {
									const payload = {
										asset_id: selectedFiat?.id,
										type: 'deposit',
										amount: values.amount,
									};
									dispatch(feeRequest(payload));
								}
								return (
									<Form className="form">
										<div className="wallet-operations-item coin__text-normal">
											<div className="wallet-operations-item__title">
												<span>1</span>
												<p>Select Currency</p>
											</div>
											<div className="authoriztion-item">
												<Field
													type="text"
													placeholder="Select Currency"
													dropdownTitle="Select Currency"
													name="currency"
													required
													component={CurrencySelect}
													arr={fiatCurrency.filter((el) => el.depositable)}
													onChange={setSelectedFiat}
													resetCustomSelect={resetCustomSelect}
													setResetCustomSelect={setResetCustomSelect}
													activeValue={selectedFiat || undefined}
												/>
											</div>
											<div className="wallet-operations-network">
												<div className="wallet-operations-item__title">
													<span>2</span>
													<p>Deposit Method</p>
												</div>
												<div className="wallet-operations-network__item">
													<div className="radio">
														<label className="radio__label">
															<Field className="hidden" type="radio" checked name="pay_with" />
															<span className="radio__item" />
															<span className="radio__text">
																<span className="radio__text-icon">
																	<IconSvg name="bank" w="18" h="18" />
																</span>
																Bank Transfer
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
										<div className="wallet-operations-item amount-input">
											<div className="wallet-operations-item__title">
												<span>3</span>
												<p>Deposit Amount</p>
											</div>
											<Field
												type="number"
												placeholder={
													!selectedFiat
														? ''
														: `Enter ${depositMin.toLocaleString()} - ${depositMax.toLocaleString(
																'en-US',
														  )}`
												}
												name="amount"
												required
												disabled={!fiatCode}
												component={Input}
												addonRight={fiatCode}
											/>
										</div>
										<div className="wallet-operations-item">
											<div className="receive">
												<div className="wallet-operations-item__title">
													<span>4</span>
													<p>Deposit Summary</p>
												</div>
												<div className="receive-amount">
													<p>
														{roundingNumber(Number(values.amount) - Number(fee), fiatCode)}{' '}
														{fiatCode}
													</p>
													<span>
														Deposit Fee:{' '}
														<strong>
															{roundingNumber(Number(fee), fiatCode)} {fiatCode.toUpperCase()}
														</strong>
													</span>
												</div>
											</div>
										</div>
										<div className="wallet-operations-item">
											<button
												type="submit"
												className="button button--full-width button--mb-16"
												disabled={isSubmitting}
											>
												Confirm Deposit
											</button>
											<button
												type="button"
												onClick={() => {
													resetForm();
													setResetCustomSelect(true);
													setSelectedFiat(null);
													setSubmitting(false);
												}}
												className="button button--full-width button--type2"
											>
												Cancel
											</button>
										</div>
									</Form>
								);
							}}
						</Formik>
						<InvoiceGenerate open={openModal} closeModal={closeModal} currencyCode={fiatCode} />
					</div>
				</div>
			</section>
		</Auth>
	);
};

export default DepositFiatPage;
