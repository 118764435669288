export const notificationsMessagesPopUp = {
	bankDelete: 'Bank account has not been deleted.',
	bankSuccessfully: 'Bank account has been added successfully.',
	bankNotCreated: 'Bank account has not been created.',
	bankNotUpdate: 'Bank account has not been updated.',
	bonusCompleted: 'Bonus Transfer was completed.',
	walletSuccessfully: 'Wallet address has been updated successfully.',
	walletUpdated: 'Wallet address has not been updated.',
	walletDeleted: 'Wallet address has not been deleted.',
	walletSuccessfullyCreated: (wallet: string) =>
		`Your wallet address "${wallet}" was added successfully.`,
	walletSuccessfullyNotCreated: 'Wallet address has not been created.',
};
