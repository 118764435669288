import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IBonusSystemApi } from './types';
// ==========================================:
export const bonusSystem: IBonusSystemApi = {
	getBonusSystem: () => http.get(endpoint.bonusSystem.GET_BONUS_WALLETS).then(({ data }) => data),
	getReferralsCount: () =>
		http.get(endpoint.bonusSystem.GET_REFERRALS_COUNT).then(({ data }) => data),
	transferBonusWallet: (payload) =>
		http.post(endpoint.bonusSystem.TRANSFER_BONUS_WALLET, payload).then(({ data }) => data),
};
