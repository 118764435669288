import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getHistoryLoading, getTradeInfo } from 'redux/reducers/transactions/selectors';
import { roundingNumber } from 'services/utils/roundingNumber';

let timeout: ReturnType<typeof setTimeout>;
const MainTradePair = () => {
	const mainTradePair = useSelector(getTradeInfo);
	// console.log(mainTradePair);
	const tradeInfo = useSelector(getTradeInfo);
	const historyLoading = useSelector(getHistoryLoading);

	const [updatePriceAnim, setUpdatePriceAnim] = useState(false);

	useEffect(() => {
		setUpdatePriceAnim(true);
		timeout = setTimeout(() => setUpdatePriceAnim(false), 10000);

		return () => {
			clearTimeout(timeout);
		};
	}, [historyLoading]);

	// const fromAsset = getAssetName(Number(mainTradePair?.from_asset_id));
	// const toAsset = getAssetName(Number(mainTradePair?.to_asset_id));
	// const cryptoAsset = [fromAsset, toAsset].filter((e) => {
	// 	if (e?.type === 'crypto' || e?.type === 'token') {
	// 		return e;
	// 	}
	// 	return null;
	// });
	// const fiatAsset = [
	// 	getAssetName(Number(mainTradePair?.from_asset_id)),
	// 	getAssetName(Number(mainTradePair?.to_asset_id)),
	// ].filter((e) => {
	// 	if (e?.type === 'fiat') {
	// 		return e;
	// 	}
	// 	return null;
	// });
	// console.log(fromAsset);
	// console.log(toAsset);
	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}

	const [oneText, twoText] = splitText(tradeInfo?.pair_code || 'btc_eur');
	return (
		<div className="instant-trade-title">
			<div>
				1 {oneText?.toUpperCase()} ={' '}
				<p className={`instant-trade-price ${updatePriceAnim ? 'updated' : ''}`}>
					{roundingNumber(mainTradePair?.price_for_main_page || 0, twoText)}
				</p>
				{'  '}
				{twoText.toUpperCase()}
			</div>
		</div>
	);
};

export default MainTradePair;
