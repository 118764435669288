import { forwardRef } from 'react';
import { TCustomInputRef, ICustomInputProps } from './types';

export const CustomInput = forwardRef<TCustomInputRef, ICustomInputProps>((props, ref) => {
	// eslint-disable-next-line react/prop-types
	const { onClick, value } = props;
	return (
		<div className="datepicker-input-wrapper" ref={ref} onClick={onClick}>
			<input
				className="input-item input-item--right-icon"
				type="text"
				value={value}
				readOnly
				placeholder="Select"
			/>
			<div className="input-icon input-icon--right">
				<span className="select__current-date-icon">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8 2V5"
							stroke="#3B97D3"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M16 2V5"
							stroke="#3B97D3"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M3.5 9.08984H20.5"
							stroke="#3B97D3"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
							stroke="#3B97D3"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M15.6947 13.6992H15.7037"
							stroke="#3B97D3"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M15.6947 16.6992H15.7037"
							stroke="#3B97D3"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M11.9955 13.6992H12.0045"
							stroke="#3B97D3"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M11.9955 16.6992H12.0045"
							stroke="#3B97D3"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M8.29431 13.6992H8.30329"
							stroke="#3B97D3"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M8.29431 16.6992H8.30329"
							stroke="#3B97D3"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
			</div>
		</div>
	);
});
