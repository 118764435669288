import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { IWalletsTable } from 'components/Wallets/WalletsTable/type';
import WalletsTableItem from 'components/Wallets/WalletsTable/WalletsTableItem/WalletsTableItem';
import WalletsTableHeader from 'components/Wallets/WalletsTable/WalletsTableHeader/WalletsTableHeader';

const WalletsTable: FC<IWalletsTable> = ({ title, walletsList, itemsPerPage, type }) => {
	const [sortMagnitude, setSortMagnitude] = useState<string>('desc');
	const [sortType, setSortType] = useState<string>('balance_eur');

	const sortedWalletsList = useMemo(() => {
		if (!walletsList) return [];

		if (sortMagnitude === 'asc') {
			return [...walletsList].sort((a, b) => {
				const sortFirstItem =
					sortType === 'total'
						? a.balance + a.frozen_balance
						: a.asset[sortType as keyof typeof a.asset] || Number(a[sortType as keyof typeof a]);

				const sortSecondItem =
					sortType === 'total'
						? b.balance + b.frozen_balance
						: b.asset[sortType as keyof typeof b.asset] || Number(b[sortType as keyof typeof b]);

				return sortFirstItem > sortSecondItem ? 1 : -1;
			});
		}

		if (sortMagnitude === 'desc') {
			return [...walletsList].sort((a, b) => {
				const sortFirstItem =
					sortType === 'total'
						? a.balance + a.frozen_balance
						: a.asset[sortType as keyof typeof a.asset] || Number(a[sortType as keyof typeof a]);

				const sortSecondItem =
					sortType === 'total'
						? b.balance + b.frozen_balance
						: b.asset[sortType as keyof typeof b.asset] || Number(b[sortType as keyof typeof b]);

				return sortFirstItem < sortSecondItem ? 1 : -1;
			});
		}

		return walletsList;
	}, [walletsList, sortMagnitude, sortType]);

	const [currentItems, setCurrentItems] = useState<IWalletItem[] | null>(null);
	const [itemOffset, setItemOffset] = useState(0);
	const [pageCount, setPageCount] = useState(0);

	useEffect(() => {
		if (sortedWalletsList) {
			const endOffset = itemOffset + itemsPerPage;
			setCurrentItems(sortedWalletsList.slice(itemOffset, endOffset));
			setPageCount(Math.ceil(sortedWalletsList.length / itemsPerPage));
		}
	}, [itemOffset, itemsPerPage, sortedWalletsList]);

	const handlePageClick = ({ selected }: { selected: number }) => {
		if (sortedWalletsList) {
			const newOffset = (selected * itemsPerPage) % sortedWalletsList.length;
			setItemOffset(newOffset);
		}
	};

	return (
		<div>
			<div className="table-title">
				<p>{title}</p>
			</div>
			<div className="table-block table-block--mt-0">
				<div className="table-wrapper">
					<div className="table table--wallet table--fiat-balance">
						<WalletsTableHeader
							type={type}
							sortType={sortType}
							setSortType={setSortType}
							sortMagnitude={sortMagnitude}
							setSortMagnitude={setSortMagnitude}
						/>
						<div className="table-body">
							{!!currentItems?.length &&
								currentItems.map((wallet) => <WalletsTableItem key={wallet.id} {...wallet} />)}
						</div>
					</div>
				</div>
			</div>
			{!!walletsList && itemsPerPage < walletsList?.length && (
				<div className="pagination-block">
					<ReactPaginate
						breakLabel="..."
						onPageChange={handlePageClick}
						pageCount={pageCount}
						className="pagination"
						activeClassName="active"
						previousClassName="arrow"
						nextClassName="arrow"
						previousLabel={
							<svg
								width="10"
								height="16"
								viewBox="0 0 10 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8.5 1L1.5 8L8.5 15"
									stroke="#777E90"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						}
						nextLabel={
							<svg
								width="10"
								height="16"
								viewBox="0 0 10 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.5 1L8.5 8L1.5 15"
									stroke="#777E90"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						}
					/>
				</div>
			)}
		</div>
	);
};

export default WalletsTable;
