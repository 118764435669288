import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUp } from '../types';

const VerificationErrorPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	return (
		<>
			<Popup open={open} closeOnDocumentClick onClose={closeModal}>
				<div className="modal">
					<div className="close" onClick={closeModal} />
					<div className="popup">
						<div className="popup-header">
							<p className="popup-header__title red">Verification Error</p>
						</div>
						<div className="popup-body">
							<div className="popup-text">
								<p>
									There was an error submitting your information and documents. Please try again.
								</p>
							</div>
						</div>
						<div className="popup-footer">
							<div className="popup-submit">
								<button onClick={closeModal} type="button" className="button button--full-width">
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default VerificationErrorPopUp;
