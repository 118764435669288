import React, { FC } from 'react';
import PhoneInput from 'react-phone-input-2';

interface IPhoneInputField {
	value: string;
	children: React.ReactNode;
	onChange: (inputValue: string) => void;
	enableSearch?: boolean;
	searchClass?: string;
	dropdownClass?: string;
}

const PhoneInputField: FC<IPhoneInputField> = ({
	children,
	onChange,
	value,
	enableSearch,
	searchClass,
	dropdownClass,
}) => {
	const onPhonePrefixChange = (inputValue: string) => {
		onChange(inputValue);
	};

	return (
		<div className="react-tel-input-wrapper">
			{children}
			<PhoneInput
				country="us"
				value={value}
				onChange={onPhonePrefixChange}
				enableSearch={enableSearch}
				dropdownClass={dropdownClass}
				searchClass={searchClass}
				searchPlaceholder="Search"
			/>
		</div>
	);
};

PhoneInputField.defaultProps = {
	enableSearch: false,
	searchClass: '',
	dropdownClass: '',
};

export default PhoneInputField;
