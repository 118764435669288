import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from '../types';

const ErrorPopUp: FC<IPopUp & IPopUpData> = ({ open, closeModal, title, message }) => {
	return (
		<div>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
				}}
			>
				<div className="popup">
					<div className="popup-header">
						<p className="popup-header__title popup-header__title--error">{title || 'Error'}</p>
						{!!message && (
							<div className="popup-text popup-text--mt-24 popup-text--mb-8">
								<p>{message}</p>
							</div>
						)}
					</div>
					<div className="popup-footer">
						<div className="popup-submit">
							<button
								onClick={() => {
									closeModal();
								}}
								type="button"
								className="button button--full-width"
							>
								Ok
							</button>
						</div>
					</div>
				</div>
			</Popup>
		</div>
	);
};

export default ErrorPopUp;
