import React, { FC, useEffect, useMemo, useState } from 'react';
import Select from '../index';
import { ICurrencySelect, ICurrencySelectCustomList, ICurrencySelectItem } from './types';

const CurrencySelectCustomInput: FC<ICurrencySelectItem> = ({ code, name, img_path }) => (
	<span className="coin coin--type3" style={{ display: name && code ? '' : 'none' }}>
		{img_path ? (
			<>
				<span className="coin__icon">
					<img src={img_path} alt={code} />
				</span>
				<span className="coin__text-wrap">
					<span className="coin__text upper__text">
						{code}
						<span className="coin__text-more">{name}</span>
					</span>
				</span>
			</>
		) : (
			<span className="coin__text upper__text">
				{code}
				<span className="coin__text-more">{name}</span>
			</span>
		)}
	</span>
);

const CurrencySelectCustomList: FC<ICurrencySelectCustomList> = ({ arr, onChange }) => (
	<>
		{arr &&
			arr.map((el) => (
				<li key={el.code}>
					<button type="button" onClick={() => onChange(el)}>
						<span className="coin coin--type3 coin__text-normal">
							{el.img_path && (
								<span className="coin__icon">
									<img src={el.img_path} alt={el.code} />
								</span>
							)}
							<span className="coin__text upper__text coin__text---pt-0">
								{el.code} <br />
								<span className="coin__text-more coin__text-more--type2">{el.name}</span>
							</span>
						</span>
					</button>
				</li>
			))}
	</>
);

const CurrencySelect: FC<ICurrencySelect> = ({
	arr,
	resetCustomSelect,
	setResetCustomSelect,
	onChange,
	activeValue,
	...props
}) => {
	const initActiveCurrency = useMemo(
		() => ({
			id: 0,
			code: '',
			name: '',
		}),
		[],
	);

	const [activeCurrency, setActiveCurrency] = useState<ICurrencySelectItem>(initActiveCurrency);
	const [searchCurrency, setSearchCurrency] = useState<ICurrencySelectItem[]>([]);

	useEffect(() => {
		if (arr) {
			setSearchCurrency(arr);
		}
	}, [arr]);

	useEffect(() => {
		if (resetCustomSelect) {
			setActiveCurrency(initActiveCurrency);
			!!setResetCustomSelect && setResetCustomSelect(false);
		}
	}, [initActiveCurrency, resetCustomSelect, setResetCustomSelect]);

	useEffect(() => {
		setActiveCurrency(activeValue || initActiveCurrency);
	}, [activeValue, initActiveCurrency]);

	const onChangeHandler = (el: ICurrencySelectItem) => {
		setActiveCurrency(el);
		!!onChange && onChange(el);
	};
	const customList = {
		list: <CurrencySelectCustomList arr={searchCurrency} onChange={onChangeHandler} />,
		activeElement: activeCurrency.code,
	};

	useEffect(() => {
		if (arr?.length === 1) {
			onChangeHandler(arr[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arr, activeCurrency]);

	return (
		<Select
			{...props}
			customInput={<CurrencySelectCustomInput {...activeCurrency} />}
			customList={customList}
			searchField
			setSearchCurrency={setSearchCurrency}
			arr={arr}
		/>
	);
};

export default CurrencySelect;
