import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from '../../types';
import { IBonusSystemStore } from './types';

// ==========================================:
const getAuthState = (state: IStoreState): IBonusSystemStore => state.bonusSystem;
export const getBonusSystemData = createSelector(
	[getAuthState],
	(bonusSystem: IBonusSystemStore) => bonusSystem.data,
);
export const getReferralsCount = createSelector(
	[getAuthState],
	(bonusSystem: IBonusSystemStore) => bonusSystem.referralsCount,
);
