import React, { FC } from 'react';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from 'layouts-elements/PopUp/types';

const ReferralSuccessPopUp: FC<IPopUp & IPopUpData> = ({ open, closeModal, title, message }) => {
	const history = useHistory();

	const handleGoTo = () => {
		history.push('/wallets');
		closeModal();
	};
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup-window">
				<div className="popup-window__inside">
					{/* <div className="popup">
						<div className="popup-img popup-icon--type2">
							<svg
								width="72"
								height="72"
								viewBox="0 0 72 72"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="36" cy="36" r="36" fill="#F5F5F5" />
								<path
									d="M36 61C49.75 61 61 49.75 61 36C61 22.25 49.75 11 36 11C22.25 11 11 22.25 11 36C11 49.75 22.25 61 36 61Z"
									fill="#30C06E"
									stroke="#30C06E"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M25.375 36L32.45 43.075L46.625 28.925"
									stroke="white"
									strokeWidth="3"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<div className="popup-header">
							<p className="popup-header__title">{title || 'Successful'}</p>
							{!!message && (
								<div className="popup-text popup-text--mt-24 popup-text--type2 popup-text--center">
									<p>{message}</p>
								</div>
							)}
						</div>
						<div className="popup-footer">
							<div className="popup-submit">
								<button onClick={closeModal} type="button" className="button button--full-width">
									Ok
								</button>
							</div>
						</div>
					</div> */}
					<div className="popup popup--width-385">
						<div className="popup-icon popup-icon--type2">
							<svg
								width="72"
								height="72"
								viewBox="0 0 72 72"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="36" cy="36" r="36" fill="#F5F5F5" />
								<path
									d="M36 61C49.75 61 61 49.75 61 36C61 22.25 49.75 11 36 11C22.25 11 11 22.25 11 36C11 49.75 22.25 61 36 61Z"
									fill="#30C06E"
									stroke="#30C06E"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M25.375 36L32.45 43.075L46.625 28.925"
									stroke="white"
									strokeWidth="3"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<div className="popup-header">
							<p className="popup-header__title">Transfer Successful</p>
						</div>
						<div className="popup-body popup-body--type2">
							<div className="popup-text">
								<p>Referral Fee has been transferred to your wallet.</p>
							</div>
						</div>
						<div className="popup-footer">
							<div className="popup-submit popup-submit--mt-24 popup-submit--type2 ">
								<button
									onClick={closeModal}
									type="button"
									className="button button--type2 button--full-width"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default ReferralSuccessPopUp;
