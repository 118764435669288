import { FC } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomInput } from './CustomInput';
import { IDatepicker } from './types';

export const Datepicker: FC<IDatepicker> = ({ value, onChange }) => {
	const [startDate, endDate] = value;
	const changeDate = (dates: [Date, Date]) => {
		const formatStart = dates[0];
		const formatEnd = dates[1] && new Date(dates[1].setHours(23));
		onChange([formatStart, formatEnd]);
	};

	return (
		<div className="DatePicker">
			<p className="select-block__name">Date</p>
			<DatePicker
				selectsRange
				startDate={startDate}
				endDate={endDate}
				onChange={changeDate}
				customInput={<CustomInput />}
				maxDate={new Date()}
			/>
		</div>
	);
};
//
// <DatePicker
// 	selected={value[0]}
// 	onChange={(date: Date) => {
// 		setStartDate(date);
// 		onChange([date, endDate]);
// 	}}
// 	peekNextMonth
// 	showMonthDropdown
// 	showYearDropdown
// 	showTimeSelect
// 	dropdownMode="select"
// 	dateFormat="dd.MM.yyyy: h:mm aa"
// 	customInput={<CustomInput />}
// />
