import React, { FC } from 'react';
import { IWalletAssetItem } from 'redux/reducers/wallets/types';

export interface ITableBodyWalletAddress {
	nickname: string;
	address: string;
	img_path?: string;
	name?: string;
	code?: string;
	asset?: IWalletAssetItem;
	chain: { id: number; name: string; code: string };
}

const TableBodyWalletAddress: FC<ITableBodyWalletAddress> = ({
	nickname,
	address,
	asset,
	name,
	chain,
}) => (
	<div className="payment-methods-body">
		<div className="payment-methods-list payment-methods-list--type3">
			<div className="payment-methods-item">
				<p>Cryptocurrency</p>
				<span className="coin coin--type3">
					<span className="coin__icon">
						<img src={asset?.img_path} alt={asset?.code} />
					</span>
					<span className="coin__text coin__text---upper">
						{asset?.code}
						<span className="coin__text-more">{name}</span>
					</span>
				</span>
			</div>
			<div className="payment-methods-item">
				<p>Network</p>
				<span>{chain.code}</span>
			</div>
			<div className="payment-methods-item">
				<p>Wallet Address</p>
				<span>{address}</span>
			</div>
		</div>
	</div>
);

export default TableBodyWalletAddress;
