import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import HistoryHeader from './HistoryHeader/HistoryHeader';
import Crypto from './Crypto/Crypto';
import Fiat from './Fiat/Fiat';

const DepositsWithdrawals = () => {
	const { path: matchPath } = useRouteMatch();
	return (
		<div className="wallet-operations">
			<HistoryHeader />
			<Switch>
				<Route exact path={`${matchPath}/crypto`} component={Crypto} />
				<Route exact path={`${matchPath}/fiat`} component={Fiat} />

				<Redirect to={`${matchPath}/crypto`} />
			</Switch>
		</div>
	);
};

export default DepositsWithdrawals;
