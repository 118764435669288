import { notificationsMessagesError } from './notificationsMessages/notificationsMessagesError';

// Capitalize first letter
export function capitalize(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
export function trimBottomLine(string: string) {
	const newStr = string.replace(/_/g, ' ');
	return newStr;
}
export function shortNotation(string: string, fromStart: number, fromEnd = 0): string {
	if (string.length <= fromStart + fromEnd) {
		return string;
	}
	const leftPart = string.slice(0, fromStart);
	const rightPart = fromEnd ? string.slice(-fromEnd) : '';

	return `${leftPart}...${rightPart}`;
}

export function splitString(string: string, separator: string) {
	return string.split(separator);
}

export const errorText = (key: string) => {
	if (notificationsMessagesError[key] === undefined) {
		const srt = key.replace(/_/g, ' ');
		const result = srt.charAt(0).toUpperCase() + srt.slice(1);
		return `${result}.`;
	}
	return notificationsMessagesError[key];
};
