/* eslint-disable @typescript-eslint/no-explicit-any */
import fileDefault from '../img/file-blank-solid-240.png';
import fileCSS from '../img/file-css-solid-240.png';
import filePdf from '../img/file-pdf-solid-240.png';
import filePng from '../img/file-png-solid-240.png';
import fileJpg from '../img/file-jpg-solid-240.png';

const ImageConfig: any = {
	default: fileDefault,
	pdf: filePdf,
	png: filePng,
	css: fileCSS,
	jpeg: fileJpg,
};
export default ImageConfig;
