import TermsUse from 'components/TermsUse/TermsUse';
import Dashboard from 'layouts/Dashboard';

const TermsOfUse = () => {
	return (
		<Dashboard title="Terms of Use">
			<TermsUse />
		</Dashboard>
	);
};

export default TermsOfUse;
