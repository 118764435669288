import React from 'react';
import { IBankAccount } from 'redux/reducers/bankAccounts/types';

const TableBodyBankAccount: React.FC<IBankAccount> = ({
	beneficiary_name,
	beneficiary_country,
	beneficiary_address,
	bank_address,
	bank_name,
	bank_country,
	swift_bic,
	account_number,
	note,
}) => (
	<div className="payment-methods-body">
		<div className="payment-methods-list">
			<div className="payment-methods-item">
				<p>Beneficiary Name</p>
				<span>{beneficiary_name}</span>
			</div>
			<div className="payment-methods-item">
				<p>Beneficiary Address</p>
				<span>{beneficiary_address}</span>
			</div>
			<div className="payment-methods-item">
				<p>Beneficiary Country</p>
				<span>{beneficiary_country}</span>
			</div>
			<div className="payment-methods-item">
				<p>Bank Name</p>
				<span>{bank_name}</span>
			</div>
			<div className="payment-methods-item">
				<p>Bank Address</p>
				<span>{bank_address}</span>
			</div>
			<div className="payment-methods-item">
				<p>Bank Country</p>
				<span>{bank_country}</span>
			</div>
			<div className="payment-methods-item">
				<p>SWIFT / BIC</p>
				<span>{swift_bic}</span>
			</div>
			<div className="payment-methods-item">
				<p>Account Number</p>
				<span>{account_number}</span>
			</div>
		</div>
		<div className="payment-methods-list payment-methods-list--type2">
			<div className="payment-methods-item">
				<p>Notes</p>
				<span>{note}</span>
			</div>
		</div>
	</div>
);

export default TableBodyBankAccount;
