import { FC } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { ROUTES } from 'routes/constants';
import { IPopUp } from '../types';
import { roundingNumber } from '../../../services/utils/roundingNumber';

interface IWithdrawPropsData {
	data: {
		amount: string;
		assetName: string;
		bankNickname: string;
		fee: string;
		indicatedAmount: number | string;
		code: string;
	};
}

const WithdrawConfirm: FC<IPopUp & IWithdrawPropsData> = ({ open, closeModal, data }) => {
	const history = useHistory();

	const handleGoToClick = () => {
		history.push(`${ROUTES.DEPOSIT_WITHDRAW}/fiat`);
		closeModal();
	};

	const handleCloseButton = () => {
		history.push(ROUTES.WALLETS.INDEX);
		closeModal();
	};
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup popup--width-480">
				<div className="popup-icon popup-icon--type2">
					<svg
						width="72"
						height="72"
						viewBox="0 0 72 72"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="36" cy="36" r="36" fill="#F5F5F5" />
						<path
							d="M36 61C49.75 61 61 49.75 61 36C61 22.25 49.75 11 36 11C22.25 11 11 22.25 11 36C11 49.75 22.25 61 36 61Z"
							fill="#30C06E"
							stroke="#30C06E"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M25.375 36L32.45 43.075L46.625 28.925"
							stroke="white"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="popup-header">
					<p className="popup-header__title">Withdrawal Request Submitted</p>
				</div>
				<div className="popup-body">
					<div className="successful-info">
						<div className="successful-info__item">
							<p>Currency:</p>
							<span>
								{data.code} ({data.assetName})
							</span>
						</div>
						<div className="successful-info__item">
							<p>Bank Account:</p>
							<span>{data.bankNickname}</span>
						</div>
						<div className="successful-info__item">
							<p>Amount Requested:</p>
							<span>
								{roundingNumber(data.indicatedAmount, data.code)} {data.assetName}
							</span>
						</div>
						<div className="successful-info__item">
							<p>Withdrawal Fee:</p>
							<span>
								{roundingNumber(data.fee, data.code)} {data.assetName}
							</span>
						</div>
						<div className="successful-info__item">
							<p>Withdrawal Amount:</p>
							<span>
								{roundingNumber(Number(data.amount) - Number(data.fee), data.code)} {data.code}
							</span>
						</div>
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--type2">
						<button onClick={handleGoToClick} type="button" className="button button--full-width">
							Withdrawal History
						</button>
						<button
							onClick={handleCloseButton}
							type="button"
							className="button button--type2 button--full-width"
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default WithdrawConfirm;
