import Auth from 'layouts/Auth';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import DepositsWithdrawals from 'components/DepositsWithdrawals/DepositsWithdrawals';

const DepositsWithdrawalsPage = () => {
	return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />

					<DepositsWithdrawals />
				</div>
			</section>
		</Auth>
	);
};

export default DepositsWithdrawalsPage;
