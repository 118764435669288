const HistoryHeader = () => {
	// const { url: matchUrl } = useRouteMatch();

	return (
		<div className="wallet-operations-header-wrap">
			<div className="wallet-operations-header">
				<p>Trade History</p>
			</div>

			{/* <div className="tabs-buttons tabs-buttons--mb-0 tabs-buttons---border"> */}
			{/*	<NavLink to={`${matchUrl}/crypto`} className="button button--size4 button--type3"> */}
			{/*		Crypto */}
			{/*	</NavLink> */}

			{/*	<NavLink to={`${matchUrl}/fiat`} className="button button--size4 button--type3"> */}
			{/*		Fiat */}
			{/*	</NavLink> */}
			{/* </div> */}
		</div>
	);
};

export default HistoryHeader;
