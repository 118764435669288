import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { registrationsInitState, registrationsRequest, registrationsSuccess } from './reducer';
import { popUpOpen } from '../popUp/reducer';
import { IRegistrationsPayloadData, IRegistrationsResponse } from './types';
import { changeSteps } from '../steps/reducer';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';
// =============================================================:
// =============================================================:
function* registrationsWorker(action: PayloadAction<IRegistrationsPayloadData>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IRegistrationsResponse = yield call(
			api.registrations.registrations,
			payload.data,
		);
		notificationContainer(notificationsMessagesInfo.registeredSuccess, 'success');
		yield put(popUpOpen('successEmail'));
		payload.history.push('/registration/important-information');
		yield put(changeSteps({ id: 2, active_step: 'Important Information' }));
		yield put(registrationsSuccess(response));
		// yield put(loginSuccess(response));
	} catch (error) {
		yield put(registrationsInitState());
	} finally {
		yield put(hideLoading());
	}
}
export function* registrationsSaga() {
	yield takeEvery(registrationsRequest.type, registrationsWorker);
}
