import { api } from 'services';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	ILimits,
	ITopPairItem,
	ITradeExchangeRatePayload,
	ITradeExchangeRateResponse,
	ITradeQuoteRequestPayload,
	ITradeQuoteResponse,
	ITradeResponse,
	TApiGetCryptoHistoryResponse,
	TApiGetFiatHistoryResponse,
	TApiGetTradeHistoryResponse,
	IFeesByAssetResponse,
	ICryptoWithdrawRequestWithSuccessFunc,
	IFiatWithdrawRequestWithSuccessFunc,
	IFiatDepositGetFile,
	IFiatDepositGetFileResponse,
} from 'services/api/transactions/types';

import {
	getCryptoHistoryError,
	getCryptoHistoryRequest,
	getCryptoHistorySuccess,
	getFiatHistoryError,
	getFiatHistoryRequest,
	getFiatHistorySuccess,
	getTopPairRequest,
	getTopPairSuccess,
	getTopPairError,
	getTradeExchangeRateRequest,
	getTradeExchangeRateSuccess,
	getTradeHistoryRequest,
	getTradeHistorySuccess,
	getTradeQuoteRequest,
	getTradeQuoteSuccess,
	makeTradeRequest,
	makeTradeSuccess,
	createFiatWithdrawRequest,
	createCryptoWithdrawRequest,
	getFeesByAssetRequest,
	getFeesByAssetSuccess,
	getFeesByAssetError,
	getUserLimitsRequest,
	getUserLimitsSuccess,
	getUserLimitsError,
	postFiatDepositInvoiceCreateRequest,
	postFiatDepositInvoiceCreateSuccess,
	getInvoiceData,
} from './reducer';
import { IApiInvoiceData, TGetTransactionHistoryRequestPayloadUnsafe } from './types';
import { popUpOpen, setPopUpData } from '../popUp/reducer';

function* getCryptoHistoryWorker({
	payload = {},
}: PayloadAction<TGetTransactionHistoryRequestPayloadUnsafe>) {
	const { apiParams, onFinally } = payload;
	try {
		const data: TApiGetCryptoHistoryResponse = yield call(
			api.transactions.getCryptoHistory,
			apiParams,
		);

		yield put(getCryptoHistorySuccess(data));
	} catch (error) {
		yield put(getCryptoHistoryError());
	} finally {
		onFinally?.();
	}
}

function* getFiatHistoryWorker({
	payload = {},
}: PayloadAction<TGetTransactionHistoryRequestPayloadUnsafe>) {
	const { apiParams, onFinally } = payload;
	try {
		const data: TApiGetFiatHistoryResponse = yield call(api.transactions.getFiatHistory, apiParams);

		yield put(getFiatHistorySuccess(data));
	} catch (error) {
		yield put(getFiatHistoryError());
	} finally {
		onFinally?.();
	}
}
function* getTradeHistoryWorker({
	payload = {},
}: PayloadAction<TGetTransactionHistoryRequestPayloadUnsafe>) {
	const { apiParams, onFinally } = payload;
	try {
		const data: TApiGetTradeHistoryResponse = yield call(api.transactions.getTradeHistory, {
			status: 'completed',
			...apiParams,
		});
		yield put(getTradeHistorySuccess(data));
		// eslint-disable-next-line no-empty
	} catch (error) {
		// console.log(error);
	}
}
function* getTradeQuoteWorker({ payload }: PayloadAction<ITradeQuoteRequestPayload>) {
	// console.log(`Всегда должна быть криптой ${payload.quantity}`);
	try {
		const response: ITradeQuoteResponse = yield call(api.transactions.getTradeQuote, payload);
		yield put(getTradeQuoteSuccess(response));
	} catch {
		// put(hideLoading());
	}
}
function* getTradeExchangeRateWorker({ payload }: PayloadAction<ITradeExchangeRatePayload>) {
	try {
		yield put(showLoading());
		const response: ITradeExchangeRateResponse = yield call(
			api.transactions.getTradeExchangeRate,
			payload,
		);
		yield put(getTradeExchangeRateSuccess(response));
		// eslint-disable-next-line no-empty
	} catch (error) {
		// console.log(error);
	}
}
function* makeTradeWorker({ payload }: PayloadAction<ITradeQuoteRequestPayload>) {
	// console.log(`Sell ${payload.quantity}`);
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	// console.log(`Крипта ${payload?.crypto_quantity}`);
	try {
		yield put(showLoading());
		const response: ITradeResponse = yield call(api.transactions.makeTrade, payload);
		yield put(makeTradeSuccess(response));
		yield put(popUpOpen('successTrade'));
		yield put(getTradeHistoryRequest({ apiParams: { per_page: 5 } }));
	} catch (error) {
		// yield put(makeTradeError());
		yield put(popUpOpen('errorTrade'));
	} finally {
		yield put(hideLoading());
	}
}
function* getTopPairWorker() {
	try {
		yield put(showLoading());
		const response: ITopPairItem[] = yield call(api.transactions.getTopPair);
		yield put(getTopPairSuccess(response));
	} catch (error) {
		yield put(getTopPairError());
	} finally {
		yield put(hideLoading());
	}
}

function* createFiatWithdrawWorker({
	payload,
}: PayloadAction<IFiatWithdrawRequestWithSuccessFunc>) {
	try {
		yield call(api.transactions.createFiatWithdraw, {
			asset_id: payload.asset_id,
			bank_account_id: payload.bank_account_id,
			indicated_amount: payload.indicated_amount,
			totp: payload.totp,
		});
		yield put(setPopUpData({ data: payload.data }));
		yield put(popUpOpen('withdrawConfirmed'));
	} catch (error) {
		// notificationContainer(notificationsMessagesError.transactionFailed, 'error');
	}
}

function* createCryptoWithdrawWorker({
	payload,
}: PayloadAction<ICryptoWithdrawRequestWithSuccessFunc>) {
	try {
		yield call(api.transactions.createCryptoWithdraw, {
			asset_id: payload.asset_id,
			chain_id: payload.chain_id,
			address_id: payload.address_id,
			amount: payload.amount,
			totp: payload.totp,
		});
		yield call(() => {
			if (payload.openSuccess) {
				payload?.openSuccess();
			}
		});
	} catch (error) {
		// console.log(error);
	} finally {
		yield put(hideLoading());
	}
}

function* getFeesByAssetRequestWorker({ payload }: PayloadAction<number | string>) {
	try {
		yield put(showLoading());
		const response: IFeesByAssetResponse = yield call(api.transactions.getFeesByAsset, payload);
		yield put(getFeesByAssetSuccess(response));
	} catch (error) {
		yield put(getFeesByAssetError());
	} finally {
		yield put(hideLoading());
	}
}

function* getUserLimitsRequestWorker({ payload }: PayloadAction<number>) {
	try {
		yield put(showLoading());
		const response: ILimits = yield call(api.transactions.getLimitsByAsset, payload);
		yield put(getUserLimitsSuccess(response));
	} catch (error) {
		yield put(getUserLimitsError());
	} finally {
		yield put(hideLoading());
	}
}

function* postFiatDepositInvoiceCreateWorker({ payload }: PayloadAction<IFiatDepositGetFile>) {
	try {
		yield put(showLoading());
		const response: IFiatDepositGetFileResponse = yield call(
			api.transactions.postFiatDepositInvoiceCreate,
			payload,
		);
		const invoice: IApiInvoiceData = yield call(api.transactions.getInvoiceData, response.url_show);
		yield put(getInvoiceData(invoice));
		yield put(postFiatDepositInvoiceCreateSuccess(response));
	} catch (error) {
		yield put(getUserLimitsError());
	} finally {
		yield put(hideLoading());
	}
}

export function* transactionsSaga() {
	yield takeLatest(getCryptoHistoryRequest, getCryptoHistoryWorker);
	yield takeLatest(getFiatHistoryRequest, getFiatHistoryWorker);
	yield takeLatest(getTradeHistoryRequest, getTradeHistoryWorker);
	yield takeEvery(getTradeQuoteRequest, getTradeQuoteWorker);
	yield takeEvery(getTradeExchangeRateRequest, getTradeExchangeRateWorker);
	yield takeEvery(makeTradeRequest, makeTradeWorker);
	yield takeEvery(getTopPairRequest, getTopPairWorker);
	yield takeEvery(createFiatWithdrawRequest.type, createFiatWithdrawWorker);
	yield takeEvery(createCryptoWithdrawRequest.type, createCryptoWithdrawWorker);
	yield takeEvery(getFeesByAssetRequest.type, getFeesByAssetRequestWorker);
	yield takeEvery(postFiatDepositInvoiceCreateRequest.type, postFiatDepositInvoiceCreateWorker);
	yield takeEvery(getUserLimitsRequest.type, getUserLimitsRequestWorker);
}
