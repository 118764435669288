import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from '../types';

const WarningPopUp: FC<IPopUp & IPopUpData> = ({
	open,
	closeModal,
	confirmButtonTitle,
	title,
	message,
	confirmHandler,
}) => {
	const onConfirmClick = () => {
		closeModal();
		!!confirmHandler && confirmHandler();
	};
	const onButtonClick = () => {
		confirmHandler ? onConfirmClick() : closeModal();
	};
	return (
		<div>
			<Popup open={open} closeOnDocumentClick onClose={closeModal}>
				<div className="popup popup--width-480">
					<div className="popup-icon popup-icon--type2">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="72"
							height="72"
							viewBox="0 0 72 72"
							fill="none"
						>
							<circle cx="36" cy="36" r="36" fill="#F5F5F5" />
							<circle cx="36" cy="36" r="30" fill="#FF9F0A" />
							<path
								d="M36.0001 48.1149H26.9101C21.7051 48.1149 19.5301 44.3949 22.0501 39.8499L26.7301 31.4199L31.1401 23.4999C33.8101 18.6849 38.1901 18.6849 40.8601 23.4999L45.2701 31.4349L49.9501 39.8649C52.4701 44.4099 50.2801 48.1299 45.0901 48.1299H36.0001V48.1149Z"
								stroke="#FCFCFD"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M36 29.5V37"
								stroke="#FCFCFD"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M35.9922 41.5H36.0057"
								stroke="#FCFCFD"
								strokeWidth="3"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<div className="popup-header">
						<p className="popup-header__title">{title || 'Warning'}</p>
						{!!message && (
							<div className="popup-text popup-text--mt-24 popup-text--mb-8">
								<p>{message}</p>
							</div>
						)}
					</div>
					<div className="popup-footer">
						<div className="popup-submit">
							<button
								onClick={() => {
									closeModal();
								}}
								type="button"
								className="button button--type2 button--full-width"
							>
								Cancel
							</button>
							<button onClick={onButtonClick} type="button" className="button button--full-width">
								{confirmButtonTitle || 'Confirm'}
							</button>
						</div>
					</div>
				</div>
			</Popup>
		</div>
	);
};

export default WarningPopUp;
