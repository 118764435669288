import { FC, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import { getTradeData } from 'redux/reducers/trade/selectors';
import { getHistoryLoading, getTradeInfo } from 'redux/reducers/transactions/selectors';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { getTradeQuoteRequest, makeTradeRequest } from 'redux/reducers/transactions/reducer';
import { roundingNumber } from 'services/utils/roundingNumber';
import { navList } from 'routes/routesList';
import { IPopUp } from '../types';
import { feeRequest } from '../../../redux/reducers/deposits/reducer';
import { getFee } from '../../../redux/reducers/deposits/selectors';

type TTimeout = ReturnType<typeof setTimeout>;
let timeout: TTimeout;

const ConfirmTrade: FC<IPopUp> = ({ open, closeModal, data }) => {
	const dispatch = useDispatch();
	const currency = useSelector(getCurrencyData);
	const tradeInfo = useSelector(getTradeInfo);
	const trade = useSelector(getTradeData);
	const historyLoading = useSelector(getHistoryLoading);
	const feeNumber = useSelector(getFee);
	const foundFromCurrency = currency.find((el) => el.id === Number(trade.from_asset_id));
	const foundToCurrency = currency.find((el) => el.id === Number(trade.to_asset_id));
	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}

	const [updatePriceAnim, setUpdatePriceAnim] = useState(false);
	const percentfee = tradeInfo?.trade_fee_percent
		? (Number(trade.from_asset_count) * Number(tradeInfo?.trade_fee_percent)) / 100
		: 0;
	const mainFee =
		percentfee && tradeInfo && percentfee > Number(tradeInfo?.trade_fee_fixed)
			? percentfee
			: tradeInfo?.trade_fee_fixed || 0;
	const receivWithoutFee = Number(tradeInfo?.price) * Number(trade.from_asset_count);
	const from = trade?.from_asset_count || 0;
	const newReceive = Number(feeNumber) / (Number(from) / 100);
	const finalRecive =
		(newReceive / 100) * receivWithoutFee === 0
			? receivWithoutFee
			: (newReceive / 100) * receivWithoutFee;
	const finalReciveView =
		Number(receivWithoutFee) - finalRecive === 0
			? finalRecive
			: Number(receivWithoutFee) - finalRecive;
	const autoGetTradeExchangeRate = (assetFrom: string, assetTo: string) => {
		clearTimeout(timeout);
		const params = { from_asset_id: assetFrom, to_asset_id: assetTo };
		//---------------------------------------
		const fiatArrCodeFunc = (arr: any, type: string) => {
			return arr.filter((obj: any) => obj.type === type);
		};

		const fiatArrCode = fiatArrCodeFunc(currency, 'fiat');
		const cryptoArrCode = fiatArrCodeFunc(currency, 'crypto');
		const tokenArrCode = fiatArrCodeFunc(currency, 'token');
		const fiatTrue = fiatArrCode.some((e: any) => {
			return e.code === trade.to_asset_code;
		});
		// quantityToFrom
		// 1) Проверить все ли два поля являються криптвой
		const textCryptoArr = (arr: any) => {
			return arr.map((e: any) => {
				return e.code;
			});
		};
		const cryptoTokenArrCode = [...cryptoArrCode, ...tokenArrCode];
		const cryptocurrenciesArr = textCryptoArr(cryptoTokenArrCode);
		function checkCryptoMatch(cryptocurrencies: any) {
			return (
				cryptocurrencies.includes(trade.from_asset_code) &&
				cryptocurrencies.includes(trade.to_asset_code)
			);
		}

		const cryptoFull = checkCryptoMatch(cryptocurrenciesArr); // две крипты используються значит true
		const [oneText] = splitText(tradeInfo?.pair_code || 'btc_eur');

		const quantityToFrom = () => {
			if (cryptoFull) {
				if (trade.from_asset_code === oneText) {
					return trade.from_asset_count;
				}
				return trade.to_asset_count;
			}
			if (fiatTrue === true) {
				return trade.from_asset_count;
			}
			if (fiatTrue === false) {
				return trade.to_asset_count;
			}
			return null;
		};
		// quantityToFrom
		dispatch(
			getTradeQuoteRequest({
				...params,
				quantity: Number(quantityToFrom()) - mainFee,
			}),
		);
		timeout = setTimeout(() => autoGetTradeExchangeRate(assetFrom, assetTo), 10000);
	};
	const confirmHandler = () => {
		//---------------------------------------
		const fiatArrCodeFunc = (arr: any, type: string) => {
			return arr.filter((obj: any) => obj.type === type);
		};
		const fiatArrCode = fiatArrCodeFunc(currency, 'fiat');
		const cryptoArrCode = fiatArrCodeFunc(currency, 'crypto');
		const tokenArrCode = fiatArrCodeFunc(currency, 'token');
		const cryptoTokenArrCode = [...cryptoArrCode, ...tokenArrCode];
		const fiatTrue = fiatArrCode.some((e: any) => {
			return e.code === trade.to_asset_code;
		});
		// quantityToFrom
		// 1) Проверить все ли два поля являються криптвой
		const textCryptoArr = (arr: any) => {
			return arr.map((e: any) => {
				return e.code;
			});
		};

		const cryptocurrenciesArr = textCryptoArr(cryptoTokenArrCode);
		function checkCryptoMatch(cryptocurrencies: any) {
			return (
				cryptocurrencies.includes(trade.from_asset_code) &&
				cryptocurrencies.includes(trade.to_asset_code)
			);
		}

		const cryptoFull = checkCryptoMatch(cryptocurrenciesArr); // две крипты используються значит true
		const [oneText] = splitText(tradeInfo?.pair_code || 'btc_eur');

		const quantityToFrom = () => {
			if (cryptoFull) {
				if (trade.from_asset_code === oneText) {
					return trade.from_asset_count;
				}
				return trade.to_asset_count;
			}
			if (fiatTrue === true) {
				return trade.from_asset_count;
			}
			if (fiatTrue === false) {
				return trade.to_asset_count;
			}
			return null;
		};
		// quantityToFrom
		dispatch(
			makeTradeRequest({
				from_asset_id: trade.from_asset_id,
				to_asset_id: trade.to_asset_id,
				quantity: Number(trade.from_asset_count),
				crypto_quantity: Number(quantityToFrom()),
			}),
		);
		closeModal();
	};

	useEffect(() => {
		autoGetTradeExchangeRate(trade.from_asset_id, trade.to_asset_id);
		return () => {
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setUpdatePriceAnim(true);
		setTimeout(() => setUpdatePriceAnim(false), 1000);
	}, [historyLoading]);
	// new func
	const fee = useSelector(getFee);
	useEffect(() => {
		const payload = {
			asset_id: trade.from_asset_id,
			type: 'trade',
			amount: trade.from_asset_count,
		};
		dispatch(feeRequest(payload));
	});

	const [oneText, twoText] = splitText(tradeInfo?.pair_code || 'btc_eur');

	// console.log(receivWithoutFee);
	// console.log(finalReciveView);
	// console.log(tradeInfo);

	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup popup--width-480">
				<div className="popup-header">
					<p className="popup-header__title">Confirm Trade</p>
					<p className="popup-header__title bold">
						<span className={`instant-trade-price ${updatePriceAnim ? 'updated' : ''}`}>
							{roundingNumber(receivWithoutFee, foundToCurrency?.code)}{' '}
						</span>
						<span className="element---uppercase">{foundToCurrency?.code}</span>
					</p>
				</div>
				<div className="popup-body">
					<div className="instant-exchange-from-to">
						<div className="instant-exchange-from-to__item">
							<p>SELL</p>
							<span className="coin">
								<span className="coin__icon">
									<img src={foundFromCurrency?.img_path} alt={foundFromCurrency?.code} />
								</span>
								<span className="coin__text coin__text---bold element---uppercase">
									{foundFromCurrency?.code}
								</span>
							</span>
						</div>
						<div className="instant-exchange-from-to__arrow">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M14.4299 6.43005L20.4999 12.5001L14.4299 18.5701"
									stroke="#777E90"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3.5 12.5H20.33"
									stroke="#777E90"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<div className="instant-exchange-from-to__item">
							<p>RECEIVE</p>
							<span className="coin">
								<span className="coin__icon">
									<img src={foundToCurrency?.img_path} alt={foundToCurrency?.code} />
								</span>
								<span className="coin__text coin__text---bold element---uppercase">
									{foundToCurrency?.code}
								</span>
							</span>
						</div>
					</div>
					<div className="instant-exchange-confirm-list">
						<div className="instant-exchange-confirm-list__item">
							<p>You SELL:</p>
							<p className="element---uppercase text---semibold">
								{roundingNumber(trade.from_asset_count, foundFromCurrency?.code)}{' '}
								{foundFromCurrency?.code}
							</p>
						</div>
						<div className="instant-exchange-confirm-list__item">
							<p>You RECEIVE:</p>
							<p className="text---semibold element---uppercase">
								≈ {tradeInfo && roundingNumber(finalReciveView, foundToCurrency?.code)}{' '}
								<span className="element---uppercase">{foundToCurrency?.code}</span>
							</p>
						</div>

						<div className="instant-exchange-confirm-list__item">
							<p>Price:</p>
							<p className="text---semibold">
								1 <span className="element---uppercase">{oneText}</span> ={' '}
								{roundingNumber(tradeInfo?.price_for_main_page, twoText)}{' '}
								<span className="element---uppercase">{twoText}</span>
							</p>
						</div>
						<div className="instant-exchange-confirm-list__item">
							<p>Trade Fee:</p>
							{tradeInfo && (
								<p className="text---semibold">
									{roundingNumber(Number(fee), foundFromCurrency?.code)}{' '}
									<span className="element---uppercase">{foundFromCurrency?.code}</span>
								</p>
							)}
						</div>
					</div>

					<div className="instant-exchange-confirm-list-note">
						<svg
							width="18"
							height="19"
							viewBox="0 0 18 19"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9 17C13.125 17 16.5 13.625 16.5 9.5C16.5 5.375 13.125 2 9 2C4.875 2 1.5 5.375 1.5 9.5C1.5 13.625 4.875 17 9 17Z"
								stroke="#FF9F0A"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M9 6.5V10.25"
								stroke="#FF9F0A"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M8.99585 12.5H9.00259"
								stroke="#FF9F0A"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<p>
							<span>Please Note:</span> Price will update as required every 10 seconds.
						</p>
					</div>
					<div className="instant-exchange-confirm-info">
						<p>
							By clicking on Confirm Trade you are agreeing to our{' '}
							<a href={navList.termsOfUse.path} className="link" onClick={closeModal}>
								Terms of Use
							</a>
							.
						</p>
					</div>
				</div>

				<div className="popup-footer">
					<div className="popup-submit popup-submit--type2">
						<button
							type="button"
							onClick={confirmHandler}
							className="button button--full-width"
							disabled={finalReciveView <= 0}
						>
							Confirm Trade
						</button>
						<button
							type="button"
							onClick={closeModal}
							className="button button--type2 button--full-width"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default ConfirmTrade;
