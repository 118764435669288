import React from 'react';
import Dashboard from '../../layouts/Dashboard';
import CreatePasswordForm from './CreatePasswordForm/CreatePasswordForm';

const CreatePassword = () => {
	return (
		<Dashboard>
			<section className="login-section">
				<div className="container">
					<div className="login">
						<div className="login-form">
							<div className="login-form__title">
								<p>Create New Password</p>
							</div>
							<div className="login-form__text">
								<p>Please enter a strong and unique password.</p>
							</div>
							<CreatePasswordForm />
						</div>
					</div>
				</div>
			</section>
		</Dashboard>
	);
};

export default CreatePassword;
