import React, { FC, MouseEvent, useEffect } from 'react';
import {
	ISortedButton,
	IWalletsTableHeader,
	IWalletsTableHeaderButton,
} from 'components/Wallets/WalletsTable/type';
import { EWalletType } from 'redux/reducers/wallets/types';

const SortedButton: FC<ISortedButton> = ({
	title,
	sortType,
	sortOrderType,
	setSortType,
	sortMagnitude,
	setSortMagnitude,
}) => {
	useEffect(() => {
		if (sortType) {
			setSortMagnitude('desc');
		}
	}, [sortType, setSortMagnitude]);

	const handleSorted = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		setSortType(name);

		if (!sortMagnitude) setSortMagnitude('asc');

		if (sortMagnitude === 'asc') setSortMagnitude('desc');

		if (sortMagnitude === 'desc') setSortMagnitude('');
	};

	return (
		<div className="td">
			<button
				type="button"
				className="td-sorting-arrow-button"
				name={sortOrderType}
				onClick={handleSorted}
			>
				{String(title)}{' '}
				<div className="td-sorting-arrow">
					<span
						className={`td-sorting-arrow__item ${
							sortMagnitude === 'desc' && sortType === sortOrderType ? 'active' : ''
						}`}
					>
						<svg
							width="6"
							height="3"
							viewBox="0 0 6 3"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M0 3L5.4 3L6 3L3 -2.62268e-07L0 3Z" fill="#9D9DBC" />
						</svg>
					</span>
					<span
						className={`td-sorting-arrow__item ${
							sortMagnitude === 'asc' && sortType === sortOrderType ? 'active' : ''
						}`}
					>
						<svg
							width="6"
							height="3"
							viewBox="0 0 6 3"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M6 0H0.6H0L3 3L6 0Z" fill="#9D9DBC" />
						</svg>
					</span>
				</div>
			</button>{' '}
		</div>
	);
};

const WalletsTableHeader: FC<IWalletsTableHeader> = ({
	sortMagnitude,
	setSortMagnitude,
	sortType,
	setSortType,
	type,
}) => {
	const TableHeaderButtons: IWalletsTableHeaderButton[] = [
		{
			title: type === EWalletType.FIAT ? 'Currency' : 'Cryptocurrency',
			sortOrderType: 'code',
		},
		{
			title: 'Balance',
			sortOrderType: 'total',
		},
		{
			title: 'Available',
			sortOrderType: 'balance',
		},
		{
			title: 'Pending',
			sortOrderType: 'frozen_balance',
		},
		{
			title: 'EUR Value',
			sortOrderType: 'balance_eur',
		},
	];
	return (
		<div className="table-header">
			<div className="tr">
				{TableHeaderButtons.map((el) => (
					<SortedButton
						key={el.sortOrderType}
						{...el}
						sortMagnitude={sortMagnitude}
						setSortMagnitude={setSortMagnitude}
						sortType={sortType}
						setSortType={setSortType}
					/>
				))}
				<div className="td td--right">
					<p>Action</p>
				</div>
			</div>
		</div>
	);
};

export default WalletsTableHeader;
