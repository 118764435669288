import { FC } from 'react';

import TradeTableRow from '../TradeTableRow';
import { ITradeTableBodyProps } from './types';

const TradeTableBody: FC<ITradeTableBodyProps> = ({ itemList }) => {
	return (
		<div className="table-body">
			{itemList?.length ? (
				itemList?.map((item) => <TradeTableRow key={item.id} item={item} />)
			) : (
				<div className="td text--center">Transactions is empty</div>
			)}
		</div>
	);
};

export default TradeTableBody;
