export function toMaxDecimals(value: number | string, decimals: number) {
	const maxNoExponent = 1e21;
	const valueNum = Number(value);

	if (decimals < 1 || valueNum >= maxNoExponent) {
		return valueNum.toFixed(decimals);
	}
	return valueNum.toFixed(decimals).replace(/\.?0+$/, '');
}

export function toDinamicDecimals(value: number | string, type: string) {
	if (String(value).slice(-1) === '.') return String(value);
	const decimals = type === 'crypto' ? 5 : 2;

	if (String(value).split('.')[1] && String(value).split('.')[1].length > decimals) {
		const valueNum = Number(value);
		return valueNum.toFixed(decimals + 1).slice(0, -1);
	}

	return value;
}
