/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC, useState } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { Formik, Form, Field } from 'formik';
import Input from 'ui/Formik/Input';
import PhoneInputField from 'ui/PhoneInputField';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { supportRequest } from 'redux/reducers/download/reducer';
import { IPopUp } from '../types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';
import { IGetTransactionHistoryRequestPayload } from '../../../redux/reducers/transactions/types';

const SupportPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [phonePrefix, setPhonePrefix] = useState<string>('1');
	const onChange = (value: string) => {
		setPhonePrefix(value);
	};

	const initialValues = {
		email: '',
		fullName: '',
		phone: '',
		message: '',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(notificationsInfoFields.email.required)
			.email(notificationsInfoFields.email.invalid)
			.max(60, notificationsInfoFields.email.maxLength),
		fullName: yup
			.string()
			.min(1, notificationsInfoFields.fullName.min)
			.max(50, notificationsInfoFields.fullName.max)
			.required(notificationsInfoFields.fullName.required),
		phone: yup
			.string()
			.required(notificationsInfoFields.phone.required)
			.matches(/^\d{9,15}$/, notificationsInfoFields.phone.matches),
		message: yup
			.string()
			.min(2, notificationsInfoFields.message.min)
			.max(255, notificationsInfoFields.message.max),
	});
	return (
		<>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
					history.push('/');
				}}
			>
				<div className="modal">
					<div className="popup popup--big">
						<svg
							onClick={() => {
								closeModal();
							}}
							className="svg-icon"
							style={{
								display: 'flex',
								position: 'absolute',
								width: '24px',
								height: '24px',
								right: '24px',
								top: '24px',
							}}
							viewBox="0 0 1024 1024"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M810.65984 170.65984q18.3296 0 30.49472 12.16512t12.16512 30.49472q0 18.00192-12.32896 30.33088l-268.67712 268.32896 268.67712 268.32896q12.32896 12.32896 12.32896 30.33088 0 18.3296-12.16512 30.49472t-30.49472 12.16512q-18.00192 0-30.33088-12.32896l-268.32896-268.67712-268.32896 268.67712q-12.32896 12.32896-30.33088 12.32896-18.3296 0-30.49472-12.16512t-12.16512-30.49472q0-18.00192 12.32896-30.33088l268.67712-268.32896-268.67712-268.32896q-12.32896-12.32896-12.32896-30.33088 0-18.3296 12.16512-30.49472t30.49472-12.16512q18.00192 0 30.33088 12.32896l268.32896 268.67712 268.32896-268.67712q12.32896-12.32896 30.33088-12.32896z" />
						</svg>
						<div className="popup-header">
							<p className="popup-header__title">Support</p>
						</div>
						<Formik
							validationSchema={validationSchema}
							initialValues={initialValues}
							onSubmit={(obj, { resetForm, setSubmitting }) => {
								// const newObject = {
								// 	...obj,
								// 	phone: phonePrefix + obj.phone,
								// };
								const params: any = {
									apiParams: {
										name: obj.fullName,
										email: obj.email,
										phone: obj.phone,
										message: obj.message,
									},
									// onFinally: () => setFirstLoading(false),
								};

								dispatch(supportRequest(params));
								setSubmitting(false);
								closeModal();
								resetForm();
							}}
							enableReinitialize
							validateOnBlur
						>
							{({ isSubmitting, isValid, dirty }) => (
								<Form className="form">
									<div className="popup-body">
										<div className="popup-text">
											<p>
												Need help? Please submit the form below and our Support Team will be in
												touch.
											</p>
										</div>
										<Field
											title="Email Address"
											type="email"
											placeholder="Email Address"
											name="email"
											required
											component={Input}
										/>
										<Field
											title="Full Name"
											type="text"
											placeholder="Full Name"
											name="fullName"
											required
											component={Input}
										/>
										<p className="input__name">Phone Number</p>
										<PhoneInputField
											onChange={onChange}
											value={phonePrefix}
											enableSearch
											dropdownClass="phone-dropdown"
											searchClass="searchfield"
										>
											<Field
												type="tel"
												placeholder="Phone Number"
												name="phone"
												required
												component={Input}
											/>
										</PhoneInputField>
										<Field
											title="Message"
											type="textarea"
											placeholder="How can we help?"
											name="message"
											required
											component={Input}
										/>
									</div>
									<div className="popup-footer">
										<div className="popup-submit">
											<button type="submit" className="button button--full-width">
												Submit
											</button>
										</div>
									</div>
									<div className="popup-text popup-text--mt-24">
										<p>
											You can also message us on{' '}
											<a
												href="https://wa.link/ls0vvd"
												rel="noreferrer"
												className="link"
												target="_blank"
											>
												WhatsApp
											</a>{' '}
											or{' '}
											<a
												href="https://t.me/oneify"
												rel="noreferrer"
												className="link"
												target="_blank"
											>
												Telegram
											</a>
										</p>
									</div>
									<div className="popup-soc">
										<a href="https://wa.link/ls0vvd">
											<svg
												width="26"
												height="26"
												viewBox="0 0 26 26"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<circle cx="13" cy="13" r="13" fill="#3B97D3" />
												<path
													d="M18.6036 7.325C17.1071 5.825 15.1143 5 12.9964 5C8.625 5 5.06786 8.55714 5.06786 12.9286C5.06786 14.325 5.43214 15.6893 6.125 16.8929L5 21L9.20357 19.8964C10.3607 20.5286 11.6643 20.8607 12.9929 20.8607H12.9964C17.3643 20.8607 21 17.3036 21 12.9321C21 10.8143 20.1 8.825 18.6036 7.325ZM12.9964 19.525C11.8107 19.525 10.65 19.2071 9.63929 18.6071L9.4 18.4643L6.90714 19.1179L7.57143 16.6857L7.41429 16.4357C6.75357 15.3857 6.40714 14.175 6.40714 12.9286C6.40714 9.29643 9.36429 6.33929 13 6.33929C14.7607 6.33929 16.4143 7.025 17.6571 8.27143C18.9 9.51786 19.6643 11.1714 19.6607 12.9321C19.6607 16.5679 16.6286 19.525 12.9964 19.525ZM16.6107 14.5893C16.4143 14.4893 15.4393 14.0107 15.2571 13.9464C15.075 13.8786 14.9429 13.8464 14.8107 14.0464C14.6786 14.2464 14.3 14.6893 14.1821 14.825C14.0679 14.9571 13.95 14.975 13.7536 14.875C12.5893 14.2929 11.825 13.8357 11.0571 12.5179C10.8536 12.1679 11.2607 12.1929 11.6393 11.4357C11.7036 11.3036 11.6714 11.1893 11.6214 11.0893C11.5714 10.9893 11.175 10.0143 11.0107 9.61786C10.85 9.23214 10.6857 9.28571 10.5643 9.27857C10.45 9.27143 10.3179 9.27143 10.1857 9.27143C10.0536 9.27143 9.83929 9.32143 9.65714 9.51786C9.475 9.71786 8.96429 10.1964 8.96429 11.1714C8.96429 12.1464 9.675 13.0893 9.77143 13.2214C9.87143 13.3536 11.1679 15.3536 13.1571 16.2143C14.4143 16.7571 14.9071 16.8036 15.5357 16.7107C15.9179 16.6536 16.7071 16.2321 16.8714 15.7679C17.0357 15.3036 17.0357 14.9071 16.9857 14.825C16.9393 14.7357 16.8071 14.6857 16.6107 14.5893Z"
													fill="white"
												/>
											</svg>
										</a>
										<a href="https://t.me/oneify">
											<svg
												width="26"
												height="26"
												viewBox="0 0 26 26"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<circle cx="13" cy="13" r="13" fill="#3B97D3" />
												<path
													d="M19.2931 9.57736L17.1804 18.0633C17.021 18.6623 16.6054 18.8113 16.0147 18.5292L12.7957 16.5088L11.2424 17.7812C11.0705 17.9276 10.9267 18.05 10.5955 18.05L10.8267 15.2578L16.7929 10.6661C17.0523 10.4691 16.7366 10.3599 16.3897 10.5569L9.01408 14.5124L5.83879 13.666C5.14811 13.4823 5.1356 13.0777 5.98256 12.7955L18.4024 8.72025C18.9775 8.53658 19.4806 8.82938 19.2931 9.57736Z"
													fill="white"
												/>
											</svg>
										</a>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default SupportPopUp;
