/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPopUpData, IPopUpStore } from './types';

// ==========================================:
export const initialState: IPopUpStore = {
	popUpText: '',
	data: {},
};

// ==========================================:
const popUp = createSlice({
	name: '@@popUp',
	initialState,
	reducers: {
		popUpOpen: (state, action: PayloadAction<string>) => {
			const { payload } = action;
			const stateData = state;
			stateData.popUpText = payload;
		},
		setPopUpData: (state, action: PayloadAction<IPopUpData>) => {
			const { payload } = action;
			const stateData = state;
			stateData.data = payload;
		},
		getPopUpClose: (state) => {
			const stateData = state;
			stateData.popUpText = '';
			stateData.data = {};
		},
		popUpInitState: () => initialState,
	},
});
export default popUp.reducer;
export const { popUpInitState, popUpOpen, getPopUpClose, setPopUpData } = popUp.actions;
