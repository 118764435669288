import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import {
	getFeesByAssetRequest,
	getTradeExchangeRateRequest,
	getTradeQuoteRequest,
	getUserLimitsRequest,
	tradeInfoInitState,
} from 'redux/reducers/transactions/reducer';
import { getTradeData } from 'redux/reducers/trade/selectors';
import {
	changeAsset,
	changeAssetCount,
	swapTradeValues,
	tradeInitState,
} from 'redux/reducers/trade/reducer';
import {
	getLimitsByAsset,
	getQuoteError,
	getTradeInfo,
} from 'redux/reducers/transactions/selectors';
import { debounce } from 'ts-debounce';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import { getPopUp } from 'redux/reducers/popUp/selectors';
import { toDinamicDecimals } from 'services/utils/numbers';
import TradeInput from './TradeInput/TradeInput';

type TTimeout = ReturnType<typeof setTimeout>;
let timeout: TTimeout;
const f = (fn: (value: string) => void, value: string) => fn(value);
const fnDebounce = debounce(f, 400) as (fn: (value: string) => void, value: string) => void;

const TradeForm: FC = () => {
	const dispatch = useDispatch();
	const mainCurrency = useSelector(getCurrencyData);
	const currency = mainCurrency.filter((el) => el.exchangeable);
	const wallets = useSelector(getWalletsList);
	const trade = useSelector(getTradeData);
	const tradeInfo = useSelector(getTradeInfo);
	const fromAssetLimits = useSelector(getLimitsByAsset);
	const currentPopup = useSelector(getPopUp);
	const notShowTrade = useSelector(getQuoteError);
	const [sellInputError, setSellInputError] = useState(false);
	const [buyInputError, setBuyInputError] = useState(false);
	const [sellCurrency, setSellCurrency] = useState(currency);
	const [buyCurrency, setBuyCurrency] = useState(currency);
	const [sellType, setSellType] = useState('crypto');
	const [buyType, setBuyType] = useState('fiat');
	const autoGetTradeExchangeRate = (assetFrom: string, assetTo: string) => {
		clearTimeout(timeout);
		if (currentPopup.popUpText === 'successTrade' || currentPopup.popUpText === 'confirmTrade')
			return;
		const params = { from_asset_id: assetFrom, to_asset_id: assetTo };
		dispatch(getTradeExchangeRateRequest(params));
		timeout = setTimeout(() => autoGetTradeExchangeRate(assetFrom, assetTo), 10000);
	};

	useEffect(() => {
		setSellCurrency(currency.filter((el) => el.type !== 'fiat'));
		setBuyCurrency(currency.filter((el) => el.code !== trade.from_asset_code));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainCurrency]);

	const getTradeExchangeRate = (assetId: string, assetType: string) => {
		const fromAsset = assetType === 'from' ? assetId : trade.from_asset_id;
		const toAsset = assetType === 'to' ? assetId : trade.to_asset_id;
		if (fromAsset !== toAsset) {
			autoGetTradeExchangeRate(fromAsset, toAsset);
			dispatch(getUserLimitsRequest(Number(fromAsset)));
		}
	};

	const swapSellAndBuy = () => {
		autoGetTradeExchangeRate(trade.to_asset_id, trade.from_asset_id);
		dispatch(getUserLimitsRequest(Number(trade.to_asset_id)));
		dispatch(swapTradeValues());
		setSellCurrency(buyCurrency);
		setBuyCurrency(sellCurrency);
		setSellType(buyType);
		setBuyType(sellType);
	};

	const calculateSellInputValue = useCallback(
		(value: string) => {
			const count = Number(value);
			if (count && tradeInfo) {
				const price = Number(tradeInfo.price);
				const calculatedValue = count / price;
				dispatch(
					changeAssetCount({
						type: 'from',
						asset_count: String(toDinamicDecimals(calculatedValue, sellType)),
					}),
				);
			}
		},
		[sellType, dispatch, tradeInfo],
	);

	const calculateBuyInputValue = useCallback(
		(value: string) => {
			const count = Number(value);
			if (count && tradeInfo) {
				const price = Number(tradeInfo.price);
				const calculatedValue = price * count;
				dispatch(
					changeAssetCount({
						type: 'to',
						asset_count: String(toDinamicDecimals(calculatedValue, buyType)),
					}),
				);
			}
		},
		[buyType, dispatch, tradeInfo],
	);

	useEffect(() => {
		calculateBuyInputValue(trade.from_asset_count);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tradeInfo?.price]);

	const sellInputChange = (value: string, error: boolean) => {
		dispatch(
			changeAssetCount({
				type: 'from',
				asset_count: String(toDinamicDecimals(value, sellType)),
			}),
		);
		setSellInputError(error);
		!error && fnDebounce(calculateBuyInputValue, value);
	};
	const buyInputChange = (value: string, error: boolean) => {
		dispatch(
			changeAssetCount({
				type: 'to',
				asset_count: String(toDinamicDecimals(value, buyType)),
			}),
		);
		setBuyInputError(error);
		!error && fnDebounce(calculateSellInputValue, value);
	};

	const sellSelectChange = (value: string, id: number) => {
		dispatch(
			changeAsset({
				type: 'from',
				asset_id: id.toString(),
				asset_code: value,
			}),
		);
		getTradeExchangeRate(id.toString(), 'from');
	};
	const buySelectChange = (value: string, id: number) => {
		dispatch(
			changeAsset({
				type: 'to',
				asset_id: id.toString(),
				asset_code: value,
			}),
		);
		getTradeExchangeRate(id.toString(), 'to');
	};
	const fiatArrCodeFunc = (arr: any, type: string) => {
		return arr.filter((obj: any) => obj.type === type);
	};

	const openConfirmTradePopup = () => {
		const fiatArrCode = fiatArrCodeFunc(currency, 'fiat');
		const cryptoArrCode = fiatArrCodeFunc(currency, 'crypto');
		const tokenArrCode = fiatArrCodeFunc(currency, 'token');
		const cryptoTokenArrCode = [...cryptoArrCode, ...tokenArrCode];
		const fiatTrue = fiatArrCode.some((e: any) => {
			return e.code === trade.to_asset_code;
		});
		// quantityToFrom
		// 1) Проверить все ли два поля являються криптвой
		function splitText(text: string) {
			const [oneText, twoText] = text.split('_');
			return [oneText, twoText];
		}
		const textCryptoArr = (arr: any) => {
			return arr.map((e: any) => {
				return e.code;
			});
		};
		const cryptocurrenciesArr = textCryptoArr(cryptoTokenArrCode);
		function checkCryptoMatch(cryptocurrencies: any) {
			return (
				cryptocurrencies.includes(trade.from_asset_code) &&
				cryptocurrencies.includes(trade.to_asset_code)
			);
		}

		const cryptoFull = checkCryptoMatch(cryptocurrenciesArr); // две крипты используються значит true
		const [oneText] = splitText(tradeInfo?.pair_code || 'btc_eur');

		const quantityToFrom = () => {
			if (cryptoFull) {
				if (trade.from_asset_code === oneText) {
					return trade.from_asset_count;
				}
				return trade.to_asset_count;
			}
			if (fiatTrue === true) {
				return trade.from_asset_count;
			}
			if (fiatTrue === false) {
				return trade.to_asset_count;
			}
			return null;
		};
		// quantityToFrom
		dispatch(
			getTradeQuoteRequest({
				from_asset_id: trade.from_asset_id,
				to_asset_id: trade.to_asset_id,
				quantity: Number(quantityToFrom()),
			}),
		);
	};
	useEffect(() => {
		if (!notShowTrade) {
			dispatch(popUpOpen('confirmTrade'));
			dispatch(
				setPopUpData({
					data: sellType,
				}),
			);
			dispatch(getFeesByAssetRequest(Number(trade.from_asset_id)));
		}
	}, [dispatch, notShowTrade, trade.from_asset_id, sellType]);

	useEffect(() => {
		dispatch(getWalletsRequest());
		dispatch(currencyDataRequest());
		dispatch(getUserLimitsRequest(Number(trade.from_asset_id)));

		if (localStorage.asset) {
			const asset = JSON.parse(localStorage.asset);
			if (asset?.asset_id === Number(trade?.to_asset_id)) {
				swapSellAndBuy();
			} else {
				sellSelectChange(asset?.asset_code, Number(asset?.asset_id));
				setSellType(asset.type);
			}
		} else {
			autoGetTradeExchangeRate(trade.from_asset_id, trade.to_asset_id);
		}

		return () => {
			dispatch(tradeInitState());
			dispatch(tradeInfoInitState());
			localStorage.removeItem('asset');
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		autoGetTradeExchangeRate(trade.from_asset_id, trade.to_asset_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPopup]);

	useEffect(() => {
		const changeCurrency = currency.find((el) => el.code === trade.from_asset_code);
		if (changeCurrency?.type === 'fiat') {
			setBuyCurrency(
				currency.filter((el) => el.type !== 'fiat' && el.code !== trade.from_asset_code),
			);
		} else {
			setBuyCurrency(currency.filter((el) => el.code !== trade.from_asset_code));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainCurrency, trade.from_asset_code]);

	useEffect(() => {
		const changeCurrency = currency.find((el) => el.code === trade.to_asset_code);
		if (changeCurrency?.type === 'fiat') {
			setSellCurrency(
				currency.filter((el) => el.type !== 'fiat' && el.code !== trade.to_asset_code),
			);
		} else {
			setSellCurrency(currency.filter((el) => el.code !== trade.to_asset_code));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainCurrency, trade.to_asset_code]);

	return (
		<div>
			<div className="instant-trade-form">
				<div className="instant-trade-form-item">
					<div className="instant-trade-form-item__header">
						<p>You SELL</p>
					</div>
					<TradeInput
						wallets={wallets}
						currency={sellCurrency}
						allCurrency={currency}
						inputError={sellInputError}
						inputValue={trade.from_asset_count}
						selectValue={trade.from_asset_code}
						onInputChange={sellInputChange}
						onSelectChange={sellSelectChange}
						setInputError={setSellInputError}
						limits={fromAssetLimits}
						setType={setSellType}
					/>
				</div>
				<div className="instant-trade-switch-button">
					<button type="button" className="button button--type-icon" onClick={swapSellAndBuy}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.28 10.45L21 6.72998L17.28 3.01001"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M3 6.72998H21"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M6.71997 13.55L3 17.2701L6.71997 20.9901"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M21 17.27H3"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				</div>
				<div className="instant-trade-form-item">
					<div className="instant-trade-form-item__header">
						<p>You RECEIVE</p>
					</div>
					<TradeInput
						currency={buyCurrency}
						allCurrency={currency}
						inputError={buyInputError}
						inputValue={trade.to_asset_count}
						selectValue={trade.to_asset_code}
						onInputChange={buyInputChange}
						onSelectChange={buySelectChange}
						setInputError={setBuyInputError}
						setType={setBuyType}
					/>
				</div>
			</div>
			<button
				type="button"
				className="button button--wide"
				onClick={openConfirmTradePopup}
				disabled={
					!(
						!sellInputError &&
						!buyInputError &&
						Number(trade.from_asset_count) > 0 &&
						!!trade.to_asset_count &&
						trade.from_asset_id !== trade.to_asset_id &&
						!!tradeInfo?.price
					)
				}
			>
				Trade
			</button>
		</div>
	);
};

export default TradeForm;
