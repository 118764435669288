import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { APP_NAME } from 'services/constants/env';
import Content from 'layouts-elements/Content';
import Footer from 'layouts-elements/Footer';
import HeaderTop from 'layouts-elements/Header';

import { getUserData } from 'redux/reducers/auth/selectors';

import { IAuth } from './types';
// ==========================================:
const Auth: FC<IAuth> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';
	const login = useSelector(getUserData);
	const history = useHistory();

	useEffect(() => {
		if (login === null) history.push('/login');
	}, [history, login]);
	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>
			<div className="wrapper">
				{/* <HeaderTop /> */}
				<Content>{children}</Content>
				<Footer />
			</div>
		</>
	);
};

export default Auth;
