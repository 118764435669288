import React, { useState, FC, useRef } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { createPasswordRequest } from 'redux/reducers/auth/reducer';
import { Field, Form, Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import Input from 'ui/Formik/Input';
import { notificationContainer } from 'services/utils/notificationContainer';
import { notificationsMessagesError } from 'services/utils/notificationsMessages/notificationsMessagesError';
import { notificationsInfoFields } from 'services/utils/ipuntFields/ipuntFields';

interface IPasswordField {
	name: string;
	title: string;
	placeholder: string;
}

const PasswordField: FC<IPasswordField> = ({ name, title, placeholder }) => {
	const [isShowPass, setIsShowPass] = useState(false);

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	return (
		<div className="input input--mb-0">
			<p className="input__name">{title}</p>
			<div className="input-wrapper">
				<Field
					type={isShowPass ? 'text' : 'password'}
					placeholder={placeholder}
					name={name}
					required
					component={Input}
					ariaLabel="show-password"
					showPass={isShowPass}
					setIsShowPass={handlePassDisplay}
				/>

				<button
					onClick={() => {
						setIsShowPass(!isShowPass);
					}}
					type="button"
					className="show-pass"
				>
					<svg
						style={{ display: isShowPass ? 'none' : 'block' }}
						className="text-type"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
							stroke="#777E90"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M17.8201 5.76998C16.0701 4.44998 14.0701 3.72998 12.0001 3.72998C8.47009 3.72998 5.18009 5.80998 2.89009 9.40998C1.99009 10.82 1.99009 13.19 2.89009 14.6C3.68009 15.84 4.60009 16.91 5.60009 17.77"
							stroke="#777E90"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993"
							stroke="#777E90"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52"
							stroke="#777E90"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M9.47 14.53L2 22"
							stroke="#777E90"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M22 2L14.53 9.47"
							stroke="#777E90"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					<svg
						style={{ display: isShowPass ? 'block' : 'none' }}
						className="password-type"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
							stroke="#777E90"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
							stroke="#777E90"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};

interface IForm {
	password: string;
	confirmPassword: string;
}
const CreatePasswordForm = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const reCaptchaRef = useRef<ReCAPTCHA>(null);
	const siteKey = process.env.REACT_APP_GOOGLE_CAPTCHA;
	const [reCaptchaKey, setReCaptchaKey] = useState<string | null>(null);

	const onReCaptchaChange = (key: string | null) => {
		setReCaptchaKey(key);
	};

	const passwordChange = (obj: IForm) => {
		const token = history.location.search.slice(1).replace('/', '');
		if (!reCaptchaKey) {
			notificationContainer(notificationsMessagesError.the_captcha_field_is_required, 'error');
		} else {
			dispatch(
				createPasswordRequest({
					data: {
						password: obj.password,
						token,
					},
					history,
				}),
			);
		}
	};

	const initialValues = {
		password: '',
		confirmPassword: '',
	};
	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.required(notificationsInfoFields.validationTexts.passwordRequired)
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				notificationsInfoFields.validationTexts.passwordMatches,
			)
			.max(25, notificationsInfoFields.validationTexts.passwordMax),
		confirmPassword: yup
			.string()
			.required(notificationsInfoFields.validationTexts.confirmPasswordRequired)
			.oneOf(
				[yup.ref('password'), null],
				notificationsInfoFields.validationTexts.confirmPasswordOneOf,
			),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IForm, { setSubmitting, resetForm }) => {
				passwordChange(values);
				setSubmitting(false);
				// resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting }) => (
				<Form className="form">
					<div className="login-form-item">
						<PasswordField name="password" title="Password" placeholder="Enter password" />
					</div>
					<div className="login-form-item">
						<PasswordField
							name="confirmPassword"
							title="Confirm Password"
							placeholder="Confirm password"
						/>
					</div>

					<div className="login-recaptcha">
						<ReCAPTCHA
							ref={reCaptchaRef}
							onChange={onReCaptchaChange}
							sitekey={siteKey || ''}
							hl="en"
						/>
					</div>

					<div className="login-form-item">
						<button
							disabled={isSubmitting}
							className="button button--full-width"
							aria-label="form-submit"
							type="submit"
						>
							Confirm
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default CreatePasswordForm;
