import { IStoreState } from 'redux/types';
import { createSelector } from '@reduxjs/toolkit';
import { IDepositsStore, IDepositAddressData } from './types';

// ==========================================:
const getDepositsState = (state: IStoreState): IDepositsStore => state.deposits;
export const getDeposits = createSelector(
	[getDepositsState],
	(deposits: IDepositsStore) => deposits,
);

// ====================================================:
export const getDepositAddress = createSelector(
	[getDeposits],
	(deposits: IDepositsStore): IDepositAddressData => deposits.depositAddress,
);

// ====================================================:
export const getFee = createSelector(
	[getDeposits],
	(deposits: IDepositsStore): IDepositAddressData => deposits.fee,
);
