import { ITwoFaApi } from './types';
import { http } from '../../http';
import { endpoint } from '../../endpoint';

// ==========================================:
// ==========================================:
export const twoFa: ITwoFaApi = {
	twoFa: () =>
		http.get(endpoint.settings.GENERATE_SECRET_SMS).then((response) => {
			return response.data;
		}),
	twoFaEnable: (payload) =>
		http.post(endpoint.settings.TWO_FA_ENABLE, payload).then((response) => {
			return response.data;
		}),
	twoFaUpdate: (payload) => http.post(endpoint.auth.TWOFA_UPDATE, payload),
};
