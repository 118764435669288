import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IBankAccountApi } from './types';
// ==========================================:
export const bankAccounts: IBankAccountApi = {
	getBankAccounts: (payload) =>
		http.get(endpoint.bankAccounts.BANK_ACCOUNTS, { params: payload }).then(({ data }) => data),
	deleteBankAccount: (id) =>
		http.delete(endpoint.bankAccounts.BANK_ACCOUNT(id)).then(({ data }) => data),
	createBankAccount: (payload) =>
		http.post(endpoint.bankAccounts.BANK_ACCOUNTS, payload).then(({ data }) => data),
	updateBankAccount: (payload) =>
		http.put(endpoint.bankAccounts.BANK_ACCOUNT(payload.id), payload).then(({ data }) => data),
};
