import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { IPopUp } from '../types';

const SuccessLogin: FC<IPopUp> = ({ open, closeModal }) => {
	const history = useHistory();
	return (
		<>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
					history.push('/login');
				}}
			>
				<div className="modal">
					<div
						className="close"
						onClick={() => {
							closeModal();
							// history.push(redirect);
						}}
					/>
					<div className="popup">
						<div className="popup-header">
							<p className="popup-header__title">Registration Confirmed</p>
						</div>
						<div className="popup-body">
							<div className="popup-text">
								<p>
									Thank you! Your password and 2FA security have been set. You are now able to log
									in.
								</p>
							</div>
						</div>
						<div className="popup-footer">
							<div className="popup-submit">
								<button onClick={closeModal} type="button" className="button button--full-width">
									Continue
								</button>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default SuccessLogin;
