import { endpoint } from 'services/endpoint';
import { httpUpload, http } from 'services/http';
import { ISettingsApi } from './types';
// ==========================================:

export const settings: ISettingsApi = {
	verification: (payload) =>
		httpUpload.post(endpoint.settings.VERIFICATION, payload.files).then((response) => {
			return response.data;
		}),
	getTwoFaStatus: () =>
		http.get(endpoint.settings.CHECK_TWO_FA_ENABLED).then((response) => response.data),
	disableTwoFa: (payload) =>
		http.post(endpoint.settings.DISABLE_TWO_FA, payload).then((response) => response.data),
};
