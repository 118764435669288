import React, { FC, useState } from 'react';

import { Selected } from 'ui/Selected/Selected';
import CryptocurrencySelect from '../../../ui/CryptocurrencySelect/CryptocurrencySelect';
import { Datepicker } from '../../../ui/Datepicker/Datepicker';

const Filters: FC<any> = ({ filters, onChange }) => {
	const [resetSelected, setResetSelected] = useState(false);
	const changeFilter = (value: any) => {
		onChange({ ...filters, ...value });
	};

	/// new
	const selectChangeCryptocurrencySell = (value: string | undefined) => {
		onChange({ ...filters, sell: value });
	};
	const selectChangeCryptocurrencyBuy = (value: string | undefined) => {
		onChange({ ...filters, buy: value });
	};
	// DataPicker
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const handleSelectDate = (value: [Date | null, Date | null]) => {
		setStartDate(value[0]);
		setEndDate(value[1]);
		onChange({ ...filters, date_from: value[0], date_to: value[1] });
	};

	const handleReset = () => {
		setResetSelected(!resetSelected);
		onChange({
			date_from: undefined,
			date_to: undefined,
			sell: undefined,
			buy: undefined,
			id: undefined,
		});
		setStartDate(null);
		setEndDate(null);
	};
	return (
		<div className="table-filter">
			<div className="table-filter-left">
				<Datepicker
					value={[startDate === null ? null : startDate, endDate === null ? null : endDate]}
					onChange={handleSelectDate}
				/>

				<div className="select-block select-block--mb-0">
					<p className="select-block__name">Sell</p>
					<CryptocurrencySelect
						onSelectChange={selectChangeCryptocurrencySell}
						resetSelected={resetSelected}
					/>
				</div>
				<div className="select-block select-block--mb-0">
					<p className="select-block__name">Buy</p>
					<CryptocurrencySelect
						onSelectChange={selectChangeCryptocurrencyBuy}
						resetSelected={resetSelected}
					/>
				</div>
				<div className="input input--size2 input--mb-0">
					<div className="input__name">
						<p>Tx ID</p>
					</div>
					<div className="input-wrapper">
						<input
							onChange={(e) => {
								onChange({ ...filters, id: e.target.value });
							}}
							value={filters.id === undefined ? '' : filters.id}
							className="input-item"
							type="text"
							placeholder="Enter TxID"
						/>
					</div>
				</div>
			</div>
			<div className="table-filter-right">
				<button
					type="button"
					className="button button--size2 button--type2 button--fw-500"
					onClick={handleReset}
				>
					Reset Filter
				</button>
			</div>
		</div>
	);
};

export default Filters;
