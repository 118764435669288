import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTopPairRequest } from 'redux/reducers/transactions/reducer';
import { getTopPair } from 'redux/reducers/transactions/selectors';

import TradeForm from 'components/Trade/TradeForm/TradeForm';
import MainTradePair from 'components/Trade/MainTradePair/MainTradePair';
import TradeHistoryWithoutPagination from 'components/TransactionHistory/TradeHistoryWithoutPagination/TradeHistoryWithoutPagination';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import { roundingNumber } from 'services/utils/roundingNumber';

const Trade = () => {
	const dispatch = useDispatch();
	const topPairs = useSelector(getTopPair);

	useEffect(() => {
		dispatch(getTopPairRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="profile-section">
			<div className="page-wrap">
				<WalletSideBar />
				<div className="profile-content">
					<section className="instant-trade-section">
						<div className="container container--full-width">
							<div className="page-title page-title--ta-c">
								<p>Instant Trade</p>
							</div>
							<div className="instant-trade">
								<MainTradePair />
								<TradeForm />
							</div>
						</div>
					</section>
					<section className="instant-coint-list-section">
						<div className="container">
							<div className="main-coint-list main-coint-list--mt-0 main-coint-list--gap-36">
								{!!topPairs?.length &&
									topPairs.map(({ change24h, last_price, asset }) => (
										<div key={asset.id} className="main-coint-list-item">
											<div className="main-coint-list-item__line">
												<span className="coin">
													<span className="coin__icon">
														<img src={asset.img_path} alt={asset.code} />
													</span>
													<span className="coin__text element---uppercase coin__text---bold">
														{asset.code}
													</span>
												</span>
												<div className="main-coint-list-item__price">
													<p>{roundingNumber(last_price, 'EUR')} EUR</p>
												</div>
											</div>
											<div className="main-coint-list-item__line">
												<div className="main-coint-list-item__change">
													<p>24 Hours Change</p>
												</div>
												<div
													className={`main-coint-list-item__change main-coint-list-item__change--ta-r ${
														change24h.toString().includes('-') ? 'red' : 'green'
													}`}
												>
													<p>{change24h} %</p>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</section>
					<section className="instant-trade-history-section">
						<div className="container">
							<div className="page-title page-title--ta-c">
								<p>Trade History</p>
							</div>
							<TradeHistoryWithoutPagination />
						</div>
					</section>
				</div>
			</div>
		</section>
	);
};
export default Trade;
