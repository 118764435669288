import React from 'react';

const IncorrectNotification = () => {
	return (
		<div className="notification notification--icon-error active">
			<div className="notification__close">
				<button type="button">
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M0.799805 0.799988L15.1998 15.2M0.799805 15.2L15.1998 0.799988"
							stroke="#173B58"
						/>
					</svg>
				</button>
			</div>
			<div className="notification__icon">
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M11.9999 21.41H5.93993C2.46993 21.41 1.01993 18.93 2.69993 15.9L5.81993 10.28L8.75993 5C10.5399 1.79 13.4599 1.79 15.2399 5L18.1799 10.29L21.2999 15.91C22.9799 18.94 21.5199 21.42 18.0599 21.42H11.9999V21.41Z"
						fill="#F04438"
					/>
					<path
						d="M12 9V14"
						stroke="white"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M11.9946 17H12.0036"
						stroke="white"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
			<div className="notification__info">
				<div className="notification__title">
					<p>Error</p>
				</div>
				<div className="notification__text">
					<p>You have entered an invalid credentials</p>
				</div>
			</div>
		</div>
	);
};

export default IncorrectNotification;
