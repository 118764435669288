import { api } from 'services';
import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { notificationContainer } from 'services/utils/notificationContainer';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	getBonusSystemRequest,
	getBonusSystemSuccess,
	getReferralsCountRequest,
	getReferralsCountSuccess,
	switchOfBonusSystemLoading,
	transferBonusWalletRequest,
} from './reducer';
import { IBonusSystemItem, IReferralsResponse } from './types';
import { popUpOpen, setPopUpData } from '../popUp/reducer';
import { notificationsMessagesError } from '../../../services/utils/notificationsMessages/notificationsMessagesError';
import { notificationsMessagesPopUp } from '../../../services/utils/notificationsMessages/notificationsMessagesPopUp';

// =============================================================:

// =============================================================:
function* getBonusSystemWorker() {
	try {
		yield put(showLoading());
		const response: IBonusSystemItem[] = yield call(api.bonusSystem.getBonusSystem);
		yield put(getBonusSystemSuccess(response));
	} catch (error) {
		yield put(switchOfBonusSystemLoading());
		notificationContainer(notificationsMessagesError.bonusFound, 'error');
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* getReferralCountWorker() {
	try {
		yield put(showLoading());
		const response: IReferralsResponse = yield call(api.bonusSystem.getReferralsCount);
		yield put(getReferralsCountSuccess(response));
	} catch (error) {
		yield put(switchOfBonusSystemLoading());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* transferBonusWalletWorker({ payload }: PayloadAction<number>) {
	try {
		yield put(showLoading());
		yield call(api.bonusSystem.transferBonusWallet, { balance_id: payload });
		yield put(switchOfBonusSystemLoading());
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bonusCompleted }));
		yield put(popUpOpen('referralSuccessPopUp'));
		yield put(getBonusSystemRequest());
	} catch (error) {
		yield put(switchOfBonusSystemLoading());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
export function* bonusSystemSaga() {
	yield takeEvery(getBonusSystemRequest.type, getBonusSystemWorker);
	yield takeEvery(getReferralsCountRequest.type, getReferralCountWorker);
	yield takeEvery(transferBonusWalletRequest.type, transferBonusWalletWorker);
}
