import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import IconSvg from 'ui/Svg/IconSvg';
import { ISelect } from './types';

interface ICountry {
	value: string;
	label: string;
}

const Select: FC<ISelect> = ({
	touched,
	type,
	field,
	placeholder,
	inputMode,
	onKeyUp,
	title,
	arr,
	dropdownTitle,
	customList,
	customInput,
	buttonName,
	buttonClick,
	disabled,
	searchField,
	setSearchArr,
	setTouched,
	setSearchCurrency,
}) => {
	const [opened, setOpened] = useState<boolean>(false);
	const [customListValue, setCustomListValue] = useState<string>('');
	const [searchValue, setSearchValue] = useState('');
	const selectRef = useRef<HTMLDivElement | null>(null);

	const handleClearSerch = () => {
		setSearchValue('');
	};

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (!selectRef.current?.contains(event.target as Node)) {
				setOpened(false);
				setTouched && setTouched();
			}
		};

		if (opened) {
			document.addEventListener('mousedown', handleOutsideClick);
		}
		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, [opened, setTouched]);

	useEffect(() => {
		if (setSearchArr) {
			if (searchValue) {
				const newArr = arr?.filter(
					(item: ICountry) =>
						item.label?.toLowerCase().includes(searchValue.toLowerCase()) ||
						item.value?.toLowerCase().includes(searchValue.toLowerCase()),
				);
				setSearchArr(newArr);
			}
			if (!searchValue) {
				setSearchArr(arr);
			}
		}

		if (setSearchCurrency) {
			if (!searchValue) {
				setSearchCurrency(arr);
				return;
			}
			const newArr = arr?.filter((el: any) =>
				el.asset
					? el.asset.name.toLowerCase().includes(searchValue.toLowerCase()) ||
					  el.asset.code.toLowerCase().includes(searchValue.toLowerCase())
					: el.name.toLowerCase().includes(searchValue.toLowerCase()) ||
					  el.code.toLowerCase().includes(searchValue.toLowerCase()),
			);
			setSearchCurrency(newArr);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue, setSearchArr, setSearchCurrency]);

	const toggleSelect = () => {
		setOpened(!opened);
	};
	const changeInputValue = (value: string) => {
		field.onChange({ target: { value, name: field.name } });
		setOpened(false);
	};
	const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearchValue(value);
	};

	useEffect(() => {
		if (
			(customList?.activeElement || customList?.activeElement === '') &&
			customList?.activeElement !== customListValue
		) {
			!!field && field.onChange({ target: { value: customList?.activeElement, name: field.name } });

			setCustomListValue(customList?.activeElement);
			setOpened(false);
		}
	}, [customList?.activeElement, customListValue, field]);

	return (
		<div className="select-block">
			{!!title && <p className="select-block__name">{title}</p>}
			<div
				className={`select ${opened ? 'active' : ''} ${disabled ? 'disabled' : ''} ${
					touched ? 'select-block--error' : ''
				}`}
			>
				<button onClick={toggleSelect} type="button" className="select__current">
					{!!customInput && <span className="select__custom">{customInput}</span>}
					<input
						className="select-input"
						{...field}
						type={type}
						placeholder={placeholder}
						inputMode={inputMode || null}
						onKeyUp={onKeyUp}
					/>
					<span className="select__current-arrow">
						<IconSvg name="select-arrow" w="10" h="5" />
					</span>
				</button>
				<div className="select__drop select__drop---top country-select" ref={selectRef}>
					{searchField && (
						<div className="input-wrapper">
							<div className="input-icon">
								<IconSvg name="search" />
							</div>
							<input
								className="input-item input-item--left-icon input-item--right-icon input-item--padding-bottom"
								type="text"
								placeholder="Search"
								value={searchValue}
								onChange={handleChangeSearchValue}
							/>
							<div className="input-icon input-icon--right">
								<button type="button" onClick={handleClearSerch}>
									<IconSvg name="close-circle" />
								</button>
							</div>
						</div>
					)}
					<div className="select__drop-scroll">
						{!searchField && (
							<div className="select__drop-title">
								<p>{dropdownTitle}</p>
								<button type="button" onClick={toggleSelect}>
									<IconSvg name="close" w="16" h="17" />
								</button>
							</div>
						)}
						<div className="select__drop-item">
							<ul>
								{customList
									? customList.list
									: !!arr?.length &&
									  arr.map((el: any, index: number) => (
											<li key={String(el) + index.toString()}>
												<button type="button" onClick={() => changeInputValue(el)}>
													{el}
												</button>
											</li>
									  ))}
							</ul>
						</div>
					</div>
					{buttonName && buttonClick && (
						<button
							type="button"
							onClick={buttonClick}
							className="button button--size2 button--full-width"
						>
							{buttonName}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default Select;
