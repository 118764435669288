import { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Popup from 'reactjs-popup';

import Input from 'ui/Formik/Input';
import CurrencySelect from 'ui/Formik/Select/CurrencySelect';
import { useDispatch, useSelector } from 'react-redux';
import { getCryptoCurrencyData } from 'redux/reducers/currency/selectors';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { ICurrencySelectItem } from 'ui/Formik/Select/CurrencySelect/types';
import { getWalletsList } from 'redux/reducers/wallets/selectors';

import { api } from 'services';
import {
	createWalletAddressRequest,
	updateWalletAddressRequest,
} from 'redux/reducers/walletAddresses/reducer';
import { IAddWalletsValue, IPropsPopUp } from './types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';

const AddWalletAddress: FC<IPropsPopUp> = ({ open, closeModal, walletId, asset_id, create }) => {
	const dispatch = useDispatch();
	const cryptoCurrency = useSelector(getCryptoCurrencyData);
	const wallets = useSelector(getWalletsList);
	const [selectedCoin, setSelectedCoin] = useState<ICurrencySelectItem | null>(null);

	const [selectedNetwork, setSelectedNetwork] = useState<any>(null);
	const [resetCustomSelect, setResetCustomSelect] = useState(false);
	const [valid, setValid] = useState(false);
	const validateWallet = async (address: string) => {
		const searchCode = selectedNetwork?.code;
		const wallet = wallets?.filter((el) => el.asset.code === selectedCoin?.code);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const result = wallet[0].asset.chains.find((obj) => obj.code === searchCode);
		await api.wallets
			.validateAddress({
				address,
				asset_id: selectedNetwork?.pivot?.asset_id || result?.pivot?.asset_id,
				chain_id: selectedNetwork?.pivot?.chain_id || result?.pivot?.chain_id,
			})
			.then(() => setValid(true))
			.catch(() => setValid(true)); // .catch(() => setValid(false)); - на время отключаем проверку
	};
	const validationSchema = yup.object().shape({
		nickname: yup
			.string()
			.required(notificationsInfoFields.nickname.required)
			.matches(/^[\w-,.@ '"`*&?%$!+#№^/()]*$/, notificationsInfoFields.nickname.matches),
		currency: yup.string().required(notificationsInfoFields.currency.required),
		address: yup.string(),
		// .required(notificationsInfoFields.nickname.required)
		// .matches(/^[A-Za-z0-9]*$/, notificationsInfoFields.address.matches), //на время отключаем проверку
		network: yup.string().required(notificationsInfoFields.network.required),
	});

	const balId = wallets
		?.find((el) => el.address.find((address) => address.id === walletId))
		?.address.find((item) => item.id === walletId)?.balance_id;

	const initialValues = {
		nickname:
			(!create &&
				wallets
					?.find((el) => el.address.find((address) => address.id === walletId))
					?.address.find((item) => item.id === walletId)?.nickname) ||
			'',
		currency: '',
		address:
			(!create &&
				wallets
					?.find((el) => el.address.find((address) => address.id === walletId))
					?.address.find((item) => item.id === walletId)?.address) ||
			'',
		network: '',
	};

	useEffect(() => {
		if (walletId && !asset_id && initialValues.nickname) {
			const wallet = wallets
				?.find((el) => el.address.find((address) => address.id === walletId))
				?.address.find((item) => item.id === walletId);
			// const currency =
			// 	cryptoCurrency.find((item) => item.chains.find((chain) => chain.id === wallet?.chain_id)) ||
			// 	null;
			const currency = cryptoCurrency.find((item) => item.id === wallet?.asset_id) || null;
			setSelectedCoin(currency);
			setSelectedNetwork(wallet?.chain || null);
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [walletId, asset_id]);
	const createWalletAddress = (values: { address: string; nickname: string }) => {
		const wallet = wallets?.filter((el) => el.asset.code === selectedCoin?.code);
		if (walletId && !asset_id) {
			const searchCode = selectedNetwork?.code;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const result = wallet[0].asset.chains.find((obj) => obj.code === searchCode);
			dispatch(
				updateWalletAddressRequest({
					address: values.address,
					asset_id: selectedNetwork?.pivot?.asset_id || result?.pivot?.asset_id,
					chain_id: selectedNetwork?.pivot?.chain_id || result?.pivot?.chain_id,
					balance_id: balId || 0,
					id: walletId,
					nickname: values.nickname,
				}),
			);
		} else {
			dispatch(
				createWalletAddressRequest({
					...values,
					...selectedNetwork?.pivot,
					balance_id: wallet?.[0].id,
				}),
			);
		}
	};

	useEffect(() => {
		dispatch(currencyDataRequest());
	}, [dispatch]);

	useEffect(() => {
		setResetCustomSelect(true);
	}, [selectedCoin]);
	useEffect(() => {
		if (asset_id && cryptoCurrency) {
			const currentAsset = cryptoCurrency.find((asset) => asset.id === asset_id) || null;
			setSelectedCoin(currentAsset);
		}
	}, [asset_id, cryptoCurrency]);

	return (
		<Popup open={open} onClose={() => closeModal()}>
			<div className="modal">
				<div className="popup popup--width-520">
					<div className="popup-header">
						<p className="popup-header__title">
							{walletId && !asset_id ? 'Update Wallet Address' : 'Add Wallet Addresses'}
						</p>
					</div>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={async (
							values: IAddWalletsValue,
							{ setFieldError, setSubmitting, resetForm },
						) => {
							await validateWallet(values.address);
							if (valid) {
								// на время отключаем проверку.Когда включат проверку поставить !valid
								// setFieldError(
								// 	'address',
								// 	'Wallet address does not match the selected Cryptocurrency and/or Network.',
								// );  на время отключаем проверку
							} else {
								closeModal();
								createWalletAddress(values);
								resetForm();
								setValid(false);
							}
							setSubmitting(false);
						}}
					>
						{({ isSubmitting }) => (
							<Form className="form">
								<div className="popup-body">
									<Field
										title="Wallet Nickname"
										type="text"
										placeholder="Enter Wallet Nickname"
										name="nickname"
										required
										component={Input}
									/>

									<Field
										type="text"
										title="Cryptocurrency"
										placeholder="Choose an Address here"
										dropdownTitle="Select Cryptocurrency"
										name="currency"
										required
										component={CurrencySelect}
										arr={cryptoCurrency}
										onChange={setSelectedCoin}
										activeValue={selectedCoin || undefined}
									/>

									<Field
										title="Wallet Address"
										type="text"
										placeholder="Enter Wallet Address"
										name="address"
										required
										component={Input}
									/>

									<Field
										type="text"
										title="Network"
										placeholder="Select Network"
										dropdownTitle="Select Network"
										name="network"
										required
										component={CurrencySelect}
										activeValue={selectedNetwork}
										arr={selectedCoin?.chains}
										onChange={setSelectedNetwork}
										resetCustomSelect={resetCustomSelect}
										setResetCustomSelect={setResetCustomSelect}
									/>
								</div>
								<div className="popup-footer">
									<div className="popup-submit popup-submit--type2">
										<button
											type="submit"
											className="button button--full-width"
											disabled={isSubmitting}
										>
											{walletId && !asset_id ? 'Update Wallet Address' : 'Add Wallet Address'}
										</button>
										<button
											type="button"
											onClick={closeModal}
											className="button button--type2 button--full-width"
										>
											Cancel
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</Popup>
	);
};

export default AddWalletAddress;
