import React, { FC, useEffect, useState } from 'react';
import { roundingNumber } from 'services/utils/roundingNumber';

import { capitalize, shortNotation, splitString } from 'services/utils/strings';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { ICryptoTableBodyProps } from '../types';
import { CopyIcon } from '../../../../assets/inline-svg';
import { notificationContainer } from '../../../../services/utils/notificationContainer';
import { getTradeInfo } from '../../../../redux/reducers/transactions/selectors';

const TableBody: FC<ICryptoTableBodyProps> = ({ itemList, localFilter }) => {
	const [itemFilter, setItemFilter] = useState(itemList);

	useEffect(() => {
		if (localFilter.state === 0) {
			setItemFilter(itemList);
		}
		if (localFilter.state === 1) {
			const max = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return a[localFilter.value] - b[localFilter.value];
			});
			setItemFilter(max);
		}
		if (localFilter.state === 2) {
			const min = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return b[localFilter.value] - a[localFilter.value];
			});
			setItemFilter(min);
		}
	}, [itemList, localFilter]);
	const formatDateTime = (sourceDate: string) => {
		const date = new Date(sourceDate);

		const yyyy = date.getFullYear();
		const mo = String(date.getMonth() + 1).padStart(2, '0');
		const dd = String(date.getDate()).padStart(2, '0');
		const hh = String(date.getHours()).padStart(2, '0');
		const mm = String(date.getMinutes()).padStart(2, '0');
		const ss = String(date.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}:${mm}:${ss}`];
	};

	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}

	return (
		<div className="table-body">
			{itemFilter?.length ? (
				itemFilter?.map((item) => {
					const [oneText, twoText] = splitText(item?.pair || 'btc_eur');
					const [date, time] = formatDateTime(item.created_at);
					const idx1 = item.side === 'sell' ? 0 : 1;
					const idx2 = item.side === 'sell' ? 1 : 0;
					const assetFrom = splitString(item.pair, '_')[idx1].toUpperCase();
					const assetTo = splitString(item.pair, '_')[idx2].toUpperCase();
					// const rate = item.side === 'buy' ? 1 / item.rate : item.rate;
					return (
						<div key={item.id} className="tr">
							<div className="td">
								<div className="td-hidden-name">Timestamp</div>
								<p>
									{date} <br />
									<span className="td-more">{time}</span>
								</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Sell</div>
								<p>{capitalize(assetFrom)}</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Buy</div>
								<p>{capitalize(assetTo)}</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Amount Sold</div>
								<p>{roundingNumber(item.quantity, assetFrom)}</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Amount Received</div>
								<p>{roundingNumber(item.amount, assetTo)}</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Exchange Rate</div>
								<p>
									1 {oneText?.toUpperCase()} ={' '}
									<p className="instant-trade-price">
										{roundingNumber(item?.price_b2c2 || 0, twoText)}
									</p>
									{'  '}
									{twoText.toUpperCase()}
								</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Fee</div>
								<p>{`${roundingNumber(item.fee, assetFrom)} ${capitalize(assetFrom)}`}</p>
							</div>
							<div className="td td--right">
								<div className="td-hidden-name">Tx ID</div>
								<p>{item.id}</p>
							</div>
						</div>
					);
				})
			) : (
				<div className="td text--center">Transactions is empty</div>
			)}
		</div>
	);
};
export default TableBody;
