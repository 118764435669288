/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStepsPayloadStore, IStepsStore } from './types';

// ==========================================:
export const initialState: IStepsStore = {
	active_step: 'Create Account',
	id: 1,
};

// ==========================================:2
const steps = createSlice({
	name: '@@steps',
	initialState,
	reducers: {
		changeSteps: (state, action: PayloadAction<IStepsPayloadStore>) => {
			const { payload } = action;
			const stepsState = state;
			stepsState.active_step = payload.active_step;
			stepsState.id = payload.id;
		},
		stepsInitState: () => initialState,
	},
});
export default steps.reducer;
export const { changeSteps } = steps.actions;
