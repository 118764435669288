import { FC } from 'react';
import { IChangeFilter, ILocalFilter } from '../types';

interface IProps {
	localFilter: ILocalFilter;
	changeFilter: IChangeFilter;
}

const TableHeader: FC<any> = ({ localFilter, changeFilter }) => {
	const filterActiveUp = (text: string) => {
		if (localFilter.state === 1 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	const filterActiveDown = (text: string) => {
		if (localFilter.state === 2 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">Timestamp</div>
				<div className="td">Type</div>
				<div className="td">Cryptocurrency</div>
				<div className="td">Network</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('amount');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Gross
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('amount')}`} />
							<div className={`arrow-top ${filterActiveUp('amount')}`} />
						</span>
					</button>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('fee');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Fee
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('fee')}`} />
							<div className={`arrow-top ${filterActiveUp('fee')}`} />
						</span>
					</button>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('net_fee');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Net
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('net_fee')}`} />
							<div className={`arrow-top ${filterActiveUp('net_fee')}`} />
						</span>
					</button>
				</div>
				<div className="td">Destination</div>
				<div className="td">Tx Hash</div>
				<div className="td">Tx ID</div>
				<div className="td td--right">
					<p>Status</p>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
