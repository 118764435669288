import React, { FC } from 'react';
import { IPaymentMethodsHeader } from './types';

const PaymentMethodsHeader: FC<IPaymentMethodsHeader> = ({ title, buttonText, buttonHandler }) => {
	return (
		<div className="wallet-operations-header-wrap">
			<div className="wallet-operations-header">
				<p>{title}</p>
			</div>
			<div className="wallet-operations-header-right">
				<button type="button" className="button button--width-280" onClick={buttonHandler}>
					<svg
						width="14"
						height="14"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1 7H13"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M7 13V1"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					{buttonText}
				</button>
			</div>
		</div>
	);
};

export default PaymentMethodsHeader;
