/* eslint-disable @typescript-eslint/no-floating-promises */

import { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import {
	createBankAccountRequest,
	updateBankAccountRequest,
} from 'redux/reducers/bankAccounts/reducer';

import Input from 'ui/Formik/Input';
import { getBankAccounts } from 'redux/reducers/bankAccounts/selectors';
import { IBankAccountRequestPayload } from 'redux/reducers/bankAccounts/types';
import CountriesSelect from 'ui/Formik/Select/CountriesSelect';
import InputPattern from 'ui/Formik/Input/inputPattern';
import { notificationsInfoFields } from 'services/utils/ipuntFields/ipuntFields';

export interface IBankAccountForm {
	id: number | null;
	closeForm: () => void;
}

export interface ICountry {
	value: string;
	label: string;
}

const BankAccountForm: FC<IBankAccountForm> = ({ id, closeForm }) => {
	const dispatch = useDispatch();
	const bankAccounts = useSelector(getBankAccounts);
	const [countries, setCountries] = useState<[] | ICountry[]>([]);
	const [selectedBeneficiaryCountry, setSelectedBeneficiaryCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	const [selectedBankCountry, setSelectedBankCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	const [resetCustomSelect, setResetCustomSelect] = useState(false);

	useEffect(() => {
		fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code')
			.then((response) => response.json())
			.then((data) => {
				setCountries(data.countries);
			});
	}, []);

	const initialValues = {
		bank_nickname: '',
		beneficiary_name: '',
		beneficiary_address: '',
		beneficiary_country: '',
		bank_name: '',
		bank_address: '',
		bank_country: '',
		swift_bic: '',
		account_number: '',
		note: '',
	};

	const [initialInputValues, setInitialInputValues] =
		useState<IBankAccountRequestPayload>(initialValues);

	useEffect(() => {
		if (id && countries) {
			const bankAccount = bankAccounts.filter((el) => el.id === id);
			setInitialInputValues(bankAccount[0]);
			const beneficiaryCountry = countries.find(
				(item) => item.value === bankAccount[0].beneficiary_country,
			);
			const bankCountry = countries?.find((item) => item.value === bankAccount[0].bank_country);
			beneficiaryCountry && setSelectedBeneficiaryCountry(beneficiaryCountry);
			bankCountry && setSelectedBankCountry(bankCountry);
		}
	}, [bankAccounts, id, countries]);

	const validationSchema = yup.object().shape({
		bank_nickname: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_nickname.required)
			.min(1, notificationsInfoFields.validationMessages.bank_nickname.min)
			.max(100, notificationsInfoFields.validationMessages.bank_nickname.max),
		beneficiary_name: yup
			.string()
			.required(notificationsInfoFields.validationMessages.beneficiary_name.required)
			.min(1, notificationsInfoFields.validationMessages.beneficiary_name.min)
			.max(100, notificationsInfoFields.validationMessages.beneficiary_name.max),
		beneficiary_address: yup
			.string()
			.required(notificationsInfoFields.validationMessages.beneficiary_address.required)
			.min(1, notificationsInfoFields.validationMessages.beneficiary_address.min)
			.max(300, notificationsInfoFields.validationMessages.beneficiary_address.max),
		beneficiary_country: yup
			.string()
			.nullable()
			.required(notificationsInfoFields.validationMessages.beneficiary_country.required),
		bank_name: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_name.required)
			.min(1, notificationsInfoFields.validationMessages.bank_name.min)
			.max(100, notificationsInfoFields.validationMessages.bank_name.max),
		bank_address: yup
			.string()
			.required(notificationsInfoFields.validationMessages.bank_address.required)
			.min(1, notificationsInfoFields.validationMessages.bank_address.min)
			.max(200, notificationsInfoFields.validationMessages.bank_address.max),
		bank_country: yup
			.string()
			.nullable()
			.required(notificationsInfoFields.validationMessages.bank_country.required),
		swift_bic: yup
			.string()
			.required(notificationsInfoFields.validationMessages.swift_bic.required)
			.min(1, notificationsInfoFields.validationMessages.swift_bic.min)
			.max(11, notificationsInfoFields.validationMessages.swift_bic.max),
		account_number: yup
			.string()
			.required(notificationsInfoFields.validationMessages.account_number.required)
			.min(1, notificationsInfoFields.validationMessages.account_number.min)
			.max(16, notificationsInfoFields.validationMessages.account_number.max),
		note: yup.string().max(100, notificationsInfoFields.validationMessages.note.max),
	});

	const onSubmit = (obj: IBankAccountRequestPayload) => {
		id
			? dispatch(updateBankAccountRequest({ ...obj, id }))
			: dispatch(createBankAccountRequest({ ...obj }));
	};

	return (
		<div className="BankAccountForm">
			<div className="wallet-operations-item">
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						<button onClick={closeForm} type="button" className="wallet-operations-header__back">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
									stroke="#173B58"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M20.5 12H3.67"
									stroke="#173B58"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
						<p>{id ? 'Update' : 'Add'} Bank Account</p>
					</div>
				</div>
				<Formik
					validationSchema={validationSchema}
					initialValues={initialInputValues}
					onSubmit={(value, { resetForm, setSubmitting }) => {
						onSubmit({
							...value,
							beneficiary_country: selectedBeneficiaryCountry?.value,
							bank_country: selectedBankCountry?.value,
						});
						closeForm();
						resetForm();
						setSubmitting(false);
					}}
					enableReinitialize
					validateOnBlur
				>
					{({ setFieldTouched, touched, values, errors }) => (
						<Form className="form">
							<div className="form-body wallet-operations-item">
								<Field
									title="Bank Nickname"
									type="text"
									placeholder="Enter Bank Nickname"
									name="bank_nickname"
									required
									component={Input}
								/>
								<Field
									title="Beneficiary Name"
									type="text"
									placeholder="Enter Beneficiary Name"
									name="beneficiary_name"
									required
									component={Input}
								/>
								<Field
									title="Beneficiary Address"
									type="textarea"
									placeholder="Enter Beneficiary Address"
									name="beneficiary_address"
									required
									component={Input}
								/>
								<Field
									title="Beneficiary Country"
									type="text"
									placeholder="Select Country"
									searchField
									name="beneficiary_country"
									component={CountriesSelect}
									arr={countries}
									onChange={setSelectedBeneficiaryCountry}
									setTouched={() => setFieldTouched('beneficiary_country')}
									touched={touched.beneficiary_country}
									resetCustomSelect={resetCustomSelect}
									setResetCustomSelect={setResetCustomSelect}
									activeValue={selectedBeneficiaryCountry || undefined}
								/>

								{touched.beneficiary_country && !values.beneficiary_country && (
									<div className="input-notify input-notify--absolute">
										<span className="input-notify__char">*</span>
										<span className="input-notify__text">{errors.beneficiary_country}</span>
									</div>
								)}

								<Field
									title="Bank Name"
									type="text"
									placeholder="Enter Bank Name"
									name="bank_name"
									required
									component={Input}
								/>
								<Field
									title="Bank Address"
									type="textarea"
									placeholder="Enter Bank Address"
									name="bank_address"
									required
									component={Input}
								/>

								<Field
									title="Bank Country"
									type="text"
									placeholder="Select Country"
									dropdownTitle="Select Country"
									name="bank_country"
									searchField
									component={CountriesSelect}
									arr={countries}
									setTouched={() => setFieldTouched('bank_country')}
									touched={touched.bank_country}
									onChange={setSelectedBankCountry}
									resetCustomSelect={resetCustomSelect}
									setResetCustomSelect={setResetCustomSelect}
									activeValue={selectedBankCountry || undefined}
								/>

								{touched.bank_country && !values.bank_country && (
									<div className="input-notify input-notify--absolute">
										<span className="input-notify__char">*</span>
										<span className="input-notify__text">{errors.bank_country}</span>
									</div>
								)}

								<Field
									title="SWIFT / BIC"
									type="text"
									placeholder="Enter SWIFT / BIC"
									name="swift_bic"
									required
									component={InputPattern}
								/>
								<Field
									title="Account Number"
									type="text"
									placeholder="Enter Account Number"
									name="account_number"
									required
									component={Input}
								/>
								<Field
									title="Notes (Optional)"
									type="textarea"
									placeholder="Enter your text here"
									name="note"
									component={Input}
								/>

								<button type="submit" className="button button--full-width">
									{id ? 'Update ' : 'Add '}Bank Account
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default BankAccountForm;
