import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { downloadFile } from 'services/utils/downloadFile';
import { useSelector } from 'react-redux';
import {
	downloadDepositWithdrawsRequest,
	downloadInitState,
	downloadKeyRequest,
	downloadKeySuccess,
	downloadTradeRequest,
	supportRequest,
	supportSuccess,
} from './reducer';
import { IDownloadKeyResponse, IDownloadPayload } from './types';
import { getDownloadKey } from './selectors';
import { notificationContainer } from '../../../services/utils/notificationContainer';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';

// =============================================================:
function* downloadKeyWorker() {
	try {
		yield put(showLoading());
		const response: IDownloadKeyResponse = yield call(api.download.downloadKey);
		yield put(downloadKeySuccess(response));
	} catch (error) {
		yield put(downloadInitState);
	} finally {
		yield put(hideLoading());
	}
}
function* downloadDepositWithdrawsWorker({ payload }: PayloadAction<IDownloadPayload>) {
	const { apiParams } = payload;
	// eslint-disable-next-line no-debugger
	debugger;
	try {
		yield put(showLoading());
		const response: string = yield call(api.download.downloadDepositWithdraw, apiParams);
		const { origin } = window.location;
		// oneify-dev.corp.merehead.xyz/api/transactions/crypto/report/7/csv/DVGDvhX5HaEX0VrL
		window.location.replace(
			`${origin}/api/transactions/${apiParams.currency}/report/${apiParams.idUser}/${apiParams.fileName}/${apiParams.key}`,
		);
		// yield call(() => {
		// 	if (apiParams?.fileName) {
		// 		downloadFile(
		// 			response,
		// 			`Deposit Withdrawals ${apiParams?.currency} File.${apiParams?.fileName}`,
		// 		);
		// 	}
		// });
	} catch (error) {
		yield put(downloadInitState);
	} finally {
		yield put(hideLoading());
	}
}

function* downloadTradeWorker({ payload }: PayloadAction<IDownloadPayload>) {
	const { apiParams } = payload;

	try {
		yield put(showLoading());
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: string = yield call(api.download.downloadTrade, apiParams);
		window.location.replace(
			`${origin}/api/trade/report/trades/${apiParams.idUser}/${apiParams.fileName}/${apiParams.key}`,
		);
		// yield call(() => {
		// 	if (apiParams?.fileName) {
		// 		downloadFile(response, `Trade File.${apiParams?.fileName}`);
		// 	}
		// });
	} catch (error) {
		yield put(downloadInitState);
	} finally {
		downloadDepositWithdrawsRequest;
		yield put(hideLoading());
	}
}

function* supportWorker({ payload }: PayloadAction<any>) {
	const { apiParams } = payload;
	try {
		yield put(showLoading());
		yield call(api.download.supportPost, apiParams);
		yield put(supportSuccess());
		notificationContainer(notificationsMessagesInfo.supportSuccess, 'success');
	} catch (error) {
		yield put(downloadInitState);
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* downloadSaga() {
	yield takeEvery(supportRequest.type, supportWorker);
	yield takeEvery(downloadKeyRequest.type, downloadKeyWorker);
	yield takeEvery(downloadDepositWithdrawsRequest.type, downloadDepositWithdrawsWorker);
	yield takeEvery(downloadTradeRequest.type, downloadTradeWorker);
}
