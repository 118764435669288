const TradeTableHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">Timestamp</div>
				<div className="td">Sell</div>
				<div className="td">Buy</div>
				<div className="td">Amount Sold</div>
				<div className="td">Amount Received</div>
				<div className="td">Exchange Rate</div>
				<div className="td">Fee</div>
				<div className="td td--right">Tx ID</div>
			</div>
		</div>
	);
};

export default TradeTableHeader;
