import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getTwoFaStatusRequest } from 'redux/reducers/auth/reducer';
import { disableTwoFaRequest } from 'redux/reducers/settings/reducer';
import InputTotp from 'ui/Formik/InputTotp';
import { notificationsInfoFields } from '../../services/utils/ipuntFields/ipuntFields';

const SecuritySettings = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [status, setTwoFaStatus] = useState(false);
	const [totpForm, setTotpForm] = useState(false);

	useEffect(() => {
		dispatch(getTwoFaStatusRequest({ setTwoFaStatus }));
	}, [dispatch]);

	const twoFaButtonHandler = () => {
		if (status) {
			setTotpForm(true);
		} else history.push('/security-settings/2fa');
	};

	const initialValue = {
		totp: '',
	};

	const validationSchema = yup.object().shape({
		totp: yup
			.string()
			.required(notificationsInfoFields.totp.required)
			.length(6, notificationsInfoFields.totp.length)
			.matches(/\d/, notificationsInfoFields.totp.matches),
	});

	const handleBackClick = () => {
		setTotpForm(false);
	};

	return (
		<>
			<section className="setting-section ">
				<div className="container">
					<div className="setting-content">
						{!totpForm ? (
							<div className="setting-content-title">
								<p>Security Settings</p>
							</div>
						) : (
							<div className="setting-content-title">
								<button type="button" className="setting-content-back" onClick={handleBackClick}>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M9.57 5.93018L3.5 12.0002L9.57 18.0702"
											stroke="#173B58"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M20.4999 12H3.66992"
											stroke="#173B58"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</button>
								<p>2FA Settings</p>
							</div>
						)}
						<div className="setting-content-body">
							<div className="setting-content-body-item setting-content-body-item--type2">
								<div className="setting-content-body-item-header setting-content-body-item-header--type2">
									{!totpForm && (
										<div className="setting-content-body-item-header__icon">
											<svg
												width="24"
												height="25"
												viewBox="0 0 24 25"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M2 9.5V7C2 4.51 4.01 2.5 6.5 2.5H9"
													stroke="#173B58"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M15 2.5H17.5C19.99 2.5 22 4.51 22 7V9.5"
													stroke="#173B58"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M22 16.5V18C22 20.49 19.99 22.5 17.5 22.5H16"
													stroke="#173B58"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M9 22.5H6.5C4.01 22.5 2 20.49 2 18V15.5"
													stroke="#173B58"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M17 10V15C17 17 16 18 14 18H10C8 18 7 17 7 15V10C7 8 8 7 10 7H14C16 7 17 8 17 10Z"
													stroke="#173B58"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M19 12.5H5"
													stroke="#173B58"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</div>
									)}
									<p>{status ? 'Disable 2FA' : 'Enable Two-Factor Authentication'}</p>
								</div>
								{totpForm ? (
									<Formik
										initialValues={initialValue}
										validationSchema={validationSchema}
										onSubmit={(values, { setSubmitting, resetForm }) => {
											dispatch(
												disableTwoFaRequest({ totp: values.totp, setTotpForm, setTwoFaStatus }),
											);
											setSubmitting(false);
											resetForm();
										}}
										validateOnBlur
										enableReinitialize
									>
										<Form>
											<div className="enable-2fa-text twofa-title">
												<p>2FA Code from Authenticator App</p>
											</div>
											<div className="form-item--twofa">
												<Field
													type="text"
													placeholder="Please enter the 6-digit code"
													name="totp"
													required
													component={InputTotp}
												/>
											</div>
											<div className="login-form-item">
												<button
													className="button button--full-width"
													aria-label="form-submit"
													type="submit"
												>
													Disable 2FA
												</button>
											</div>
										</Form>
									</Formik>
								) : (
									<>
										<div className="enable-2fa-text">
											<p>
												For the security of your account we strongly recommend enabling 2FA. Once
												enabled, you will need to enter a one-time 6-digit code each time you login
												into your account, withdraw funds or change your password.
											</p>
										</div>
										<button
											onClick={twoFaButtonHandler}
											type="button"
											className={`button button--size2 button--full-width ${
												status ? 'button--type2' : ''
											}`}
										>
											{status ? 'Disable 2FA' : 'Enable 2FA'}
										</button>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default SecuritySettings;
