/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, DragEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { verificationFilesRequest } from 'redux/reducers/settings/reducer';
import { useHistory } from 'react-router';
import uploadImg from 'layouts-elements/DropFileCustom/config/ImageConfig';
import { CloseFileIcon } from 'assets/inline-svg';
import PopUp from '../../../layouts-elements/PopUp/PopUp';
import { getUserData } from '../../../redux/reducers/auth/selectors';

// const filesArrayToBase64Array = async (filesArray: any) => {
// 	const arr = filesArray.map(async (item: any) => {
// 		const b64 = await toBase64(item);
// 		return { file: b64 };
// 	});
// 	return Promise.all(arr);
// };

const DropFileInput = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const [fileList, setFileList] = useState<any>([]);
	const [drag, setDrag] = useState(false);
	const userType = useSelector(getUserData)?.type;
	const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDrag(true);
	};
	const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDrag(false);
	};
	const handleOnDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const updatedList = [...fileList, ...e.dataTransfer.files];
		setFileList(updatedList);
		setDrag(false);
	};

	const onFileDrop = (e: any) => {
		const newFile = e.target.files[0];
		if (newFile) {
			const updatedList = [...fileList, newFile];
			setFileList(updatedList);
		}
	};

	const fileRemove = (file: any) => {
		const updatedList = [...fileList];
		updatedList.splice(fileList?.indexOf(file), 1);
		setFileList(updatedList);
	};
	const submitFile = () => {
		if (!fileList.length) return;
		const fd = new FormData();
		const ins = fileList.length;
		for (let x = 0; x < ins; x += 1) {
			fd.append(`files[${x}]`, fileList[x]);
		}
		dispatch(verificationFilesRequest({ files: fd, history }));
	};
	return (
		<section className="setting-section ">
			<div className="container">
				<div className="setting-content">
					<div className="setting-content-body">
						{userData?.status?.name === 'approved' && (
							<div className="page-title page-title--ta-c">You are already verified</div>
						)}
						{userData?.status?.name === 'pending' && (
							<div className="page-title page-title--ta-c">Your documents are pending</div>
						)}
						{userData?.status?.name === 'unverified' && (
							<>
								<div className="authorization-form authorization-form--type2">
									<div className="setting-content-title-wrap">
										<div className="setting-content-title">
											<p>Verification</p>
										</div>
										<div className="tabs-buttons-nav">
											<button
												type="button"
												className={`button button--type3 button--size4 ${
													userType === 'personal' ? 'active' : 'disabled'
												} `}
											>
												Individual Account
											</button>
											<button
												type="button"
												className={`button button--type3 button--size4 ${
													userType !== 'personal' ? 'active' : 'disabled'
												} `}
											>
												Corporate Account
											</button>
										</div>
									</div>
									<div className="form-body form-body--type2">
										<div className="authoriztion-item">
											<div className="verification-upload-info">
												<p>Documents to Upload</p>
												{userType === 'personal' ? (
													<ul>
														<li>Photo Identification (Passport or Driver`s License)</li>
														<li>Proof of Address (Utility Bill - Dated Within 3 Months)</li>
														<li>Bank Statement (Dated Within 3 Months)</li>
													</ul>
												) : (
													<ul>
														<li>Corporate Structure Chart</li>
														<li>Certificate of Incorporation</li>
														<li>Register of Directors / Office Holders</li>
														<li>Register of Shareholders</li>
														<li>Bank Statement (Dated Within 3 Months)</li>
													</ul>
												)}
											</div>
										</div>
										<div className="authoriztion-item">
											<div
												className={`verification-upload${drag ? ' active' : ''}`}
												onDragStart={handleDragStart}
												onDragLeave={handleDragLeave}
												onDragOver={handleDragStart}
												onDrop={handleOnDrop}
											>
												<label className="verification-upload__button">
													<input className="hidden" type="file" value="" onChange={onFileDrop} />
													<span className="verification-upload__button-content">
														<svg
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M16.44 8.8999C20.04 9.2099 21.51 11.0599 21.51 15.1099V15.2399C21.51 19.7099 19.72 21.4999 15.25 21.4999H8.73998C4.26998 21.4999 2.47998 19.7099 2.47998 15.2399V15.1099C2.47998 11.0899 3.92998 9.2399 7.46998 8.9099"
																stroke="white"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M12 15.0001V3.62012"
																stroke="white"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M15.3499 5.85L11.9999 2.5L8.6499 5.85"
																stroke="white"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
														Upload Files
													</span>
												</label>
												{fileList.length > 0 ? (
													<div className="drop-file-preview">
														{fileList.map((item: any) => (
															<div key={uuidv4()} className="drop-file-preview__item">
																<img src={uploadImg[item.type.split('/')[1]]} alt="" />
																<span
																	className="drop-file-preview__item__del"
																	onClick={() => fileRemove(item)}
																>
																	<CloseFileIcon />
																</span>
															</div>
														))}
													</div>
												) : null}
												<div className="verification-upload__drop">
													<p>Drag & Drop Files</p>
												</div>
											</div>
										</div>
									</div>
									<div className="verification-acount-info">
										<p>Important:</p>
										<ul>
											<li>Bank Statements &amp; Utility Bills MUST be dated within 3 months</li>
											{userType !== 'personal' && (
												<>
													<li>
														Register of Office Holders & Register of Directors MUST be dated with 12
														months
													</li>
													<li>
														If there are additional corporate entities within your structure please
														upload the same documents for each entity.
													</li>
												</>
											)}
										</ul>
									</div>
								</div>
								<div className="flex flex-sb flex--gap-16">
									<button
										onClick={() => {
											history.push('/');
										}}
										type="button"
										className="button button--type2 button--full-width"
									>
										Cancel
									</button>
									<button type="button" className="button button--full-width" onClick={submitFile}>
										Confirm
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<PopUp />
		</section>
	);
};

export default DropFileInput;
