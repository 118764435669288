import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import IconSvg from 'ui/Svg/IconSvg';
import { roundingNumber } from 'services/utils/roundingNumber';

import { ROUTES } from 'routes/constants';
import { IPropsPopUp } from './types';

const SuccessCryptoWithdrawal: FC<IPropsPopUp> = ({
	open,
	closeModal,
	amount,
	address,
	code,
	assetName,
	network,
	fee,
}) => {
	const history = useHistory();
	const handleGoToClick = () => {
		history.push(`${ROUTES.DEPOSIT_WITHDRAW}/crypto`);
		closeModal();
	};
	return (
		<Popup open={open} onClose={closeModal}>
			<div className="modal">
				<div className="popup popup--width-480">
					<div className="popup-icon popup-icon--type2">
						<IconSvg name="check" w="72" h="72" />
					</div>
					<div className="popup-header">
						<p className="popup-header__title">Withdrawal Request Submitted</p>
					</div>
					<div className="popup-body">
						<div className="successful-info">
							<div className="successful-info__item">
								<p>Cryptocurrency:</p>
								<span>
									{code} ({assetName})
								</span>
							</div>
							<div className="successful-info__item">
								<p>Wallet Address:</p>
								<span>{address}</span>
							</div>
							<div className="successful-info__item">
								<p>Network:</p>
								<span>{network.toUpperCase()}</span>
							</div>
							<div className="successful-info__item">
								<p>Amount Requested:</p>
								<span>
									{roundingNumber(Number(amount), code)} {code}
								</span>
							</div>
							<div className="successful-info__item">
								<p>Withdrawal Fee:</p>
								<span>
									{roundingNumber(Number(fee), code)} {code}
								</span>
							</div>
							<div className="successful-info__item">
								<p>Withdrawal Amount:</p>
								<span>
									{roundingNumber(Number(amount) - Number(fee), code)} {code}
								</span>
							</div>
						</div>
					</div>
					<div className="popup-footer">
						<div className="popup-submit popup-submit--type2">
							<button type="button" className="button button--full-width" onClick={handleGoToClick}>
								Withdrawal History
							</button>
							<button
								type="button"
								onClick={closeModal}
								className="button button--type2 button--full-width"
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default SuccessCryptoWithdrawal;
