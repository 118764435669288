/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBonusSystemItem, IBonusSystemStore, IReferralsResponse } from './types';

// ==========================================:
export const initialState: IBonusSystemStore = {
	data: null,
	referralsCount: 0,
	loading: false,
};

// ==========================================:
const bonusSystem = createSlice({
	name: '@@bonusSystem',
	initialState,
	reducers: {
		getBonusSystemRequest: (state) => {
			const newState = state;

			newState.loading = true;
		},
		getBonusSystemSuccess: (state, { payload }: PayloadAction<IBonusSystemItem[]>) => {
			const newState = state;
			newState.data = payload;
			newState.loading = false;
		},
		getReferralsCountRequest: (state) => {
			const newState = state;

			newState.loading = true;
		},
		getReferralsCountSuccess: (state, { payload }: PayloadAction<IReferralsResponse>) => {
			const newState = state;
			newState.loading = false;
			newState.referralsCount = payload.count;
		},
		transferBonusWalletRequest: (state, { payload }: PayloadAction<number>) => {
			const newState = state;

			newState.loading = true;
		},
		switchOfBonusSystemLoading: (state) => {
			const newState = state;

			newState.loading = false;
		},
	},
});

export default bonusSystem.reducer;
export const {
	getBonusSystemRequest,
	getBonusSystemSuccess,
	getReferralsCountRequest,
	getReferralsCountSuccess,
	transferBonusWalletRequest,
	switchOfBonusSystemLoading,
} = bonusSystem.actions;
