/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISettingsStore, IVerificationFilesRequestPayloadData } from './types';

// ==========================================:
export const initialState: ISettingsStore = {
	verificationFilesLoader: false,
};

// ==========================================:
const settings = createSlice({
	name: '@@settings',
	initialState,
	reducers: {
		verificationFilesRequest: (
			state,
			action: PayloadAction<IVerificationFilesRequestPayloadData>,
		) => {
			const stateData = state;
			stateData.verificationFilesLoader = true;
		},
		verificationFilesSuccess: (state) => {
			const stateData = state;
			stateData.verificationFilesLoader = false;
		},
		disableTwoFaRequest: (state, action) => {},
		settingsInitState: () => initialState,
	},
});
export default settings.reducer;
export const {
	verificationFilesRequest,
	verificationFilesSuccess,
	settingsInitState,
	disableTwoFaRequest,
} = settings.actions;
