import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { IPopUp } from '../types';

const RegistrationComplete: FC<IPopUp> = ({ open, closeModal }) => {
	const history = useHistory();
	return (
		<>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
					history.push('/');
				}}
			>
				<div className="modal">
					<div
						className="close"
						onClick={() => {
							closeModal();
							// history.push(redirect);
						}}
					/>
					<div className="popup popup--width-520">
						<div className="popup-header">
							<p className="popup-header__title">Registration Complete</p>
						</div>
						<div className="popup-body">
							<div className="popup-text">
								<p>Thank you. Your application has been received and will be processed shortly.</p>
								<p>Please keep an eye on your email for updates.</p>
								<p>We look forward to working with you.</p>
							</div>
						</div>
						<div className="popup-footer">
							<div className="popup-submit">
								<button
									onClick={() => {
										closeModal();
										history.push('/');
									}}
									type="button"
									className="button button--full-width"
								>
									Ok
								</button>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default RegistrationComplete;
