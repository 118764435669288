import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from 'axios';
import { errorText } from 'services/utils/strings';
import {
	authInitState,
	loginRequest,
	loginSuccess,
	logoutRequest,
	refreshTokenRequest,
	refreshTokenSuccess,
	resetPasswordRequest,
	createPasswordRequest,
	getUserSuccess,
	getUserRequest,
	getTwoFaStatusRequest,
	resetTwoFaRequest,
	generateCodeForTwoFaRequest,
} from './reducer';
import {
	ILoginResponse,
	ILoginPayload,
	IResetPasswordPayload,
	ICreateNewPasswordPayload,
	IResetTwoFaRequestPayload,
	ICodeForTwoFaRequest,
	ICodeForTwoFaResponse,
} from './types';

import { popUpOpen } from '../popUp/reducer';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';
import { twoSuccess } from '../twoFa/reducer';
import { clearError } from '../errors/reducer';

function* loginRequestWorker(action: PayloadAction<ILoginPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield put(clearError());
		const response: ILoginResponse = yield call(api.auth.login, payload.data);
		notificationContainer(notificationsMessagesInfo.loginSuccess, 'success', 'Login');
		yield put(loginSuccess(response));
		payload.history.push('/trade');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// yield put(setErrorMsg(error.response?.data.errors[0]));
		}
	} finally {
		yield put(hideLoading());
	}
}
function* logoutRequestWorker(action: PayloadAction<ILoginPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.auth.logout);
		notificationContainer(notificationsMessagesInfo.logout, 'success', 'Logout');
		payload.history.push('/');
		yield put(authInitState());
	} catch (error) {
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* refreshTokenWorker() {
	try {
		yield put(showLoading());
		const response: ILoginResponse = yield call(api.auth.refreshToken);
		yield put(refreshTokenSuccess(response));
	} catch (error) {
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* resetPasswordWorker(action: PayloadAction<IResetPasswordPayload>) {
	try {
		yield put(showLoading());
		yield call(api.auth.resetPassword, action.payload);
		yield put(popUpOpen('resetPasswordSuccess'));
	} catch (error) {
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* createPasswordWorker(action: PayloadAction<ICreateNewPasswordPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.auth.newPassword, action.payload.data);
		payload.history.push('/login');
	} catch (error) {
		// notificationContainer('Error', 'error');
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getUserWorker() {
	try {
		const response: ILoginResponse = yield call(api.auth.getUser);
		yield put(getUserSuccess(response.user_data));
	} catch (error) {
		// notificationContainer('Error', 'error');
	}
}

function* getTwoFaStatusWorker(action: any) {
	const { payload } = action;
	try {
		const response: { status: string } = yield call(api.settings.getTwoFaStatus);
		if (response.status === '2fa_enabled') {
			payload.setTwoFaStatus(true);
		}
	} catch (error) {
		// notificationContainer('Error', 'error');
	}
}

function* reset2FAWorker({ payload }: PayloadAction<IResetTwoFaRequestPayload>) {
	try {
		yield call(api.auth.resetTwoFa, payload);
		yield put(popUpOpen('resetTwoFaSuccess'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// notificationContainer(errorText(error.response?.data.errors[0]), 'error');
		}
	}
}

function* getCodeForTwoFaWorker({ payload }: PayloadAction<ICodeForTwoFaRequest>) {
	try {
		const response: ICodeForTwoFaResponse = yield call(api.auth.resetTwoFaCode, payload);
		yield put(twoSuccess(response));
		// eslint-disable-next-line no-empty
	} catch (error) {}
}

// =============================================================:
export function* authSaga() {
	yield takeEvery(loginRequest.type, loginRequestWorker);
	yield takeEvery(logoutRequest.type, logoutRequestWorker);
	yield takeEvery(refreshTokenRequest.type, refreshTokenWorker);
	yield takeEvery(resetPasswordRequest.type, resetPasswordWorker);
	yield takeEvery(createPasswordRequest.type, createPasswordWorker);
	yield takeEvery(getUserRequest.type, getUserWorker);
	yield takeEvery(getTwoFaStatusRequest.type, getTwoFaStatusWorker);
	yield takeEvery(resetTwoFaRequest, reset2FAWorker);
	yield takeEvery(generateCodeForTwoFaRequest, getCodeForTwoFaWorker);
}
