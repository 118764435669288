import { FC } from 'react';
import Popup from 'reactjs-popup';
import { useSelector } from 'react-redux';
import { getInvoiceData, getInvoiceFiles } from 'redux/reducers/transactions/selectors';
import { roundingNumber } from 'services/utils/roundingNumber';

import { IPopUp } from '../types';

export interface ITransactionProps {
	currencyCode: string;
}

const InvoiceGenerate: FC<IPopUp & ITransactionProps> = ({ open, closeModal, currencyCode }) => {
	const invoiceFiles = useSelector(getInvoiceFiles);
	const invoiceData = useSelector(getInvoiceData);
	return (
		<Popup open={open} onClose={closeModal}>
			<div className="modal">
				<div className="popup popup--width-480">
					<div className="popup-header">
						<p className="popup-header__title">Deposit Instructions</p>
					</div>
					<div className="popup-body">
						<div className="invoice">
							<div className="invoice-header">
								<p>TRANSFER FUNDS TO BANK ACCOUNT BELOW</p>
							</div>
							<div className="invoice-list">
								<div className="invoice-list__item">
									<p>Deposit Amount:</p>
									<span>
										{roundingNumber(invoiceData?.amount)} {currencyCode}
									</span>
								</div>
								<div className="invoice-list__item">
									<p>Deposit Fee:</p>
									<span>
										{invoiceData?.fee} {currencyCode}
									</span>
								</div>
								<div className="invoice-list__item">
									<p>Currency:</p>
									<span>{currencyCode}</span>
								</div>
								{/* <div className="invoice-list__item">
									<p>Deposit Method:</p>
									<span>{invoiceData?.deposit_method}</span>
								</div> */}
								{/* <div className="invoice-list__item invoice-list__item--type2">
									<p>Account Holder:</p>
									<span>{invoiceData?.account_holder}</span>
								</div> */}
								<div className="invoice-list__line" />
								<div className="invoice-list__item">
									<p>Beneficiary Name:</p>
									<span>{invoiceData?.beneficiary_name}</span>
								</div>
								<div className="invoice-list__item">
									<p>Beneficiary Address:</p>
									<span>{invoiceData?.beneficiary_address}</span>
								</div>
								<div className="invoice-list__item">
									<p>Bank Name:</p>
									<span>{invoiceData?.bank_name}</span>
								</div>
								<div className="invoice-list__item">
									<p>Bank Address:</p>
									<span>{invoiceData?.bank_address}</span>
								</div>
								<div className="invoice-list__item">
									<p>Account Number:</p>
									<span>{invoiceData?.account_number}</span>
								</div>
								<div className="invoice-list__item">
									<p>SWIFT / BIC:</p>
									<span>{invoiceData?.swift}</span>
								</div>
								<div className="invoice-list__item invoice-list__item--type2">
									<p>Transfer Reference:</p>
									<span>{invoiceData?.transfer_reference}</span>
								</div>
								{/* <div className="invoice-list__item invoice-list__item--type2">
									<p>IMPORTANT:</p>
									<span>{invoiceData?.important}</span>
								</div> */}
							</div>
						</div>
					</div>
					<div className="popup-footer">
						<div className="popup-submit popup-submit--type2">
							<a href={invoiceFiles?.url_download} className="button button--full-width">
								Download Instructions
							</a>
							<button
								type="button"
								onClick={closeModal}
								className="button button--type2 button--full-width"
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default InvoiceGenerate;
