import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { IPopUp } from '../types';

const VerificationPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	const history = useHistory();
	return (
		<>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
					history.push('/');
				}}
			>
				<div className="modal">
					<div
						className="close"
						onClick={() => {
							closeModal();
							// history.push(redirect);
						}}
					/>
					<div className="popup">
						<div className="popup-header">
							<p className="popup-header__title">Documents Sent</p>
						</div>
						<div className="popup-body">
							<div className="popup-text">
								<p>Thank you. Your documents have been sent to our Compliance Team for review.</p>
							</div>
						</div>
						<div className="popup-footer">
							<div className="popup-submit">
								<button onClick={closeModal} type="button" className="button button--full-width">
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default VerificationPopUp;
