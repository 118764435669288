import { FC, useEffect, useState } from 'react';

import { capitalize } from 'services/utils/strings';
import { statusClassNames, statusNames } from 'components/TransactionHistory/utils';
import { toMaxDecimals } from 'services/utils/numbers';
import { ICryptoTableBodyProps } from '../types';
import { roundingNumber } from '../../../../services/utils/roundingNumber';

const TableBody: FC<ICryptoTableBodyProps> = ({ itemList, localFilter }) => {
	const [itemFilter, setItemFilter] = useState(itemList);
	useEffect(() => {
		if (localFilter.state === 0) {
			setItemFilter(itemList);
		}
		if (localFilter.state === 1) {
			const max = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return a[localFilter.value] - b[localFilter.value];
			});
			setItemFilter(max);
		}
		if (localFilter.state === 2) {
			const min = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return b[localFilter.value] - a[localFilter.value];
			});
			setItemFilter(min);
		}
	}, [itemList, localFilter]);
	const formatDateTime = (sourceDate: string) => {
		const date = new Date(sourceDate);

		const yyyy = date.getFullYear();
		const mo = String(date.getMonth() + 1).padStart(2, '0');
		const dd = String(date.getDate()).padStart(2, '0');
		const hh = String(date.getHours()).padStart(2, '0');
		const mm = String(date.getMinutes()).padStart(2, '0');
		const ss = String(date.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}:${mm}:${ss}`];
	};

	return (
		<div className="table-body">
			{itemFilter?.length ? (
				itemFilter?.map((item) => {
					const [date, time] = formatDateTime(item.created_at);
					return (
						<div key={item.id} className="tr">
							<div className="td">
								<div className="td-hidden-name">Timestamp</div>
								<p>
									{date} <br />
									<span className="td-more">{time}</span>
								</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Type</div>
								<p>{capitalize(item.type)}</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Currency</div>
								<span className="coin coin--type3">
									<span className="coin__icon">
										<img src={item?.asset?.img_path} alt="" />
									</span>
									<span className="coin__text ">
										{item?.asset?.code.toUpperCase()} <br />
										<span className="coin__text-more coin__text-more--type2">
											{item?.asset.name}
										</span>
									</span>
								</span>
							</div>
							<div className="td">
								<div className="td-hidden-name">Bank name</div>
								<p>{item?.bank?.bank_name ? item?.bank?.bank_name : '----'}</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Gross</div>
								<p>{roundingNumber(item.gross_fee)}</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Fee</div>
								<p>{roundingNumber(item.fee)}</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Net</div>
								<p>{roundingNumber(item.net_fee)}</p>
							</div>
							{/* <div className="td"> */}
							{/*	<div className="td-hidden-name">Tx ID</div> */}
							{/*	<p>???</p> */}
							{/* </div> */}
							<div className="td td--right">
								<div className="td-hidden-name">Status</div>
								<p className={statusClassNames[item.status]}>{statusNames[item.status]}</p>
							</div>
						</div>
					);
				})
			) : (
				<div className="td text--center">Transactions is empty</div>
			)}
		</div>
	);
};

export default TableBody;
