import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Auth from 'layouts/Auth';
import { getUserData } from 'redux/reducers/auth/selectors';
import { getUserRequest } from 'redux/reducers/auth/reducer';
import { statusClassNames, statusNames } from './utiils';

const AccountDetails = () => {
	const dispatch = useDispatch();
	const user = useSelector(getUserData);

	useEffect(() => {
		dispatch(getUserRequest());
	}, [dispatch]);
	return (
		<>
			<Auth>
				<section className="setting-section ">
					<div className="container">
						<div className="setting-content">
							<div className="setting-content-title">
								<p>Account Details</p>
							</div>
							<div className="setting-content-body">
								<div className="setting-content-body-item">
									<div className="setting-content-body-item-header">
										<div className="setting-content-body-item-header__icon">
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
													fill="#7C7CA6"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M8.75 9C8.75 7.20509 10.2051 5.75 12 5.75C13.7949 5.75 15.25 7.20509 15.25 9C15.25 10.7949 13.7949 12.25 12 12.25C10.2051 12.25 8.75 10.7949 8.75 9ZM12 7.25C11.0335 7.25 10.25 8.03351 10.25 9C10.25 9.96649 11.0335 10.75 12 10.75C12.9665 10.75 13.75 9.96649 13.75 9C13.75 8.03351 12.9665 7.25 12 7.25Z"
													fill="#7C7CA6"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M9.99977 15.25C7.74825 15.25 5.90517 17.0012 5.75914 19.2154C5.73189 19.6287 5.37473 19.9416 4.96141 19.9144C4.5481 19.8871 4.23514 19.53 4.2624 19.1166C4.46007 16.1194 6.95289 13.75 9.99977 13.75H13.9998C17.0426 13.75 19.5329 16.1131 19.7364 19.1048C19.7645 19.518 19.4523 19.8758 19.039 19.9039C18.6258 19.932 18.268 19.6198 18.2398 19.2065C18.0895 16.9966 16.2483 15.25 13.9998 15.25H9.99977Z"
													fill="#7C7CA6"
												/>
											</svg>
										</div>
										<p>User data</p>
									</div>
									<div className="setting-user-data">
										<div className="setting-user-data-item">
											<div className="setting-user-data-item__title">
												<p>Full Name:</p>
											</div>
											<div className="setting-user-data-item__info">
												<p>
													{user?.data?.first_name} {user?.data?.last_name}
												</p>
											</div>
										</div>
										<div className="setting-user-data-item">
											<div className="setting-user-data-item__title">
												<p>Email:</p>
											</div>
											<div className="setting-user-data-item__info">
												<p>{user?.email}</p>
											</div>
										</div>
										<div className="setting-user-data-item">
											<div className="setting-user-data-item__title">
												<p>Phone Number:</p>
											</div>
											<div className="setting-user-data-item__info">
												<p>{user?.data?.phone}</p>
											</div>
										</div>
										{user?.type === 'company' ? (
											<>
												<div className="setting-user-data-item">
													<div className="setting-user-data-item__title">
														<p>Position:</p>
													</div>
													<div className="setting-user-data-item__info">
														<p>{user?.data?.position}</p>
													</div>
												</div>
												<div className="setting-user-data-item">
													<div className="setting-user-data-item__title">
														<p>Company Name:</p>
													</div>
													<div className="setting-user-data-item__info">
														<p>{user?.data?.company_name}</p>
													</div>
												</div>
											</>
										) : null}

										<div className="setting-user-data-item">
											<div className="setting-user-data-item__title">
												<p>Verification:</p>
											</div>
											{user && (
												<div className="setting-user-data-item__info">
													<p>
														<span
															className={`${statusClassNames[user?.status_id]} green-bg--fz-12`}
														>
															{statusNames[user?.status_id]}
														</span>
													</p>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Auth>
		</>
	);
};
export default AccountDetails;
