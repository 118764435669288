/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import FormControl from 'ui/Formik/FormConrol';
import { IAdminInput } from './types';

// ==========================================:
const InputTotp: FC<IAdminInput> = (props) => {
	const { ariaLabel, type, field, placeholder, inputMode, onKeyUp, disabled } = props;

	return (
		<FormControl ariaLabel={ariaLabel} {...props}>
			<input
				className="input-item"
				{...field}
				type={type}
				placeholder={placeholder}
				onKeyUp={onKeyUp}
				disabled={disabled}
				inputMode={inputMode || null}
				onKeyPress={(event) => {
					if (!/[0-9]/.test(event.key)) {
						event.preventDefault();
					}
				}}
			/>
		</FormControl>
	);
};

export default InputTotp;
