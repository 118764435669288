import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getTradeInfo } from 'redux/reducers/transactions/selectors';
import { getTradeData } from 'redux/reducers/trade/selectors';
import { changeAsset, tradeInitState } from 'redux/reducers/trade/reducer';
import {
	getTopPairRequest,
	getTradeExchangeRateRequest,
	tradeInfoInitState,
} from 'redux/reducers/transactions/reducer';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IPopUp } from '../types';
import { feeRequest } from '../../../redux/reducers/deposits/reducer';
import { getFee } from '../../../redux/reducers/deposits/selectors';

const SuccessTrade: FC<IPopUp> = ({ open, closeModal }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const trade = useSelector(getTradeData);
	const tradeInfo = useSelector(getTradeInfo);
	const mainTradePair = useSelector(getTradeInfo);
	const closeHandler = () => {
		// dispatch(tradeInitState());
		// dispatch(tradeInfoInitState());
		// dispatch(getTopPairRequest());
		dispatch(getWalletsRequest());
		// dispatch(
		// 	getTradeExchangeRateRequest({
		// 		from_asset_id: '1',
		// 		to_asset_id: '10',
		// 	}),
		// );
		dispatch(changeAsset({}));
		closeModal();
	};

	const viewTransactionsHandler = () => {
		history.push('/transactions-trade/trade');
		closeModal();
	};

	const percentfee = tradeInfo?.trade_fee_percent
		? (Number(trade.from_asset_count) * Number(tradeInfo?.trade_fee_percent)) / 100
		: 0;
	const mainFee =
		percentfee && tradeInfo && percentfee > Number(tradeInfo?.trade_fee_fixed)
			? percentfee
			: tradeInfo?.trade_fee_fixed || 0;

	const receivWithoutFee = Number(tradeInfo?.price) * (Number(trade.from_asset_count) - mainFee);
	const fee = useSelector(getFee);
	const payload = {
		asset_id: tradeInfo?.from_asset_id,
		type: 'trade',
		amount: tradeInfo?.quantity,
	};
	dispatch(feeRequest(payload));
	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}

	const [oneText, twoText] = splitText(tradeInfo?.pair_code || 'btc_eur');
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeHandler}>
			<div className="popup popup--width-480">
				<div className="popup-icon popup-icon--type2">
					<svg
						width="72"
						height="72"
						viewBox="0 0 72 72"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="36" cy="36" r="36" fill="#F5F5F5" />
						<path
							d="M36 61C49.75 61 61 49.75 61 36C61 22.25 49.75 11 36 11C22.25 11 11 22.25 11 36C11 49.75 22.25 61 36 61Z"
							fill="#30C06E"
							stroke="#30C06E"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M25.375 36L32.45 43.075L46.625 28.925"
							stroke="white"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="popup-header">
					<p className="popup-header__title">Trade Successful</p>
					<div className="popup-text popup-text--mt-24 popup-text--mb-8">
						<p>You will receive</p>
					</div>

					<p className="popup-header__title">
						{roundingNumber(receivWithoutFee, trade?.to_asset_code)}{' '}
						<span className="element---uppercase">{trade?.to_asset_code}</span>
					</p>
				</div>
				<div className="popup-body">
					<div className="successful-info">
						<div className="successful-info__item">
							<p>You SOLD:</p>
							<span>
								{roundingNumber(trade.from_asset_count, trade?.from_asset_code)}{' '}
								<span className="element---uppercase">{trade?.from_asset_code}</span>
							</span>
						</div>
						<div className="successful-info__item">
							<p>You RECEIVE:</p>
							<span>
								{roundingNumber(receivWithoutFee, trade?.to_asset_code)}{' '}
								<span className="element---uppercase">{trade?.to_asset_code}</span>
							</span>
						</div>
						<div className="successful-info__item">
							<p>Price:</p>
							<span>
								1 <span className="element---uppercase">{oneText?.toUpperCase()}</span> ={' '}
								{roundingNumber(mainTradePair?.price_for_main_page || 0, twoText)}
								<span className="element---uppercase"> {twoText.toUpperCase()}</span>
							</span>
						</div>
						<div className="successful-info__item">
							<p>Trade Fee:</p>
							<span>
								{roundingNumber(Number(fee), trade?.from_asset_code)}{' '}
								<span className="element---uppercase">{trade?.from_asset_code}</span>
							</span>
						</div>
					</div>
				</div>

				<div className="popup-footer">
					<div className="popup-submit popup-submit--type2">
						<button
							type="button"
							onClick={viewTransactionsHandler}
							className="button button--full-width"
						>
							Trade History
						</button>
						<button
							type="button"
							onClick={closeHandler}
							className="button button--type2 button--full-width"
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default SuccessTrade;
