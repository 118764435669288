import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { navList } from 'routes/routesList';
import IconSvg from 'ui/Svg/IconSvg';
import Logo from 'assets/img/sidebar_logo.svg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { logoutRequest } from '../../../redux/reducers/auth/reducer';

const WalletSideBar = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const getNavLinkClass = (path: string[]) => {
		if (path.indexOf(location.pathname) !== -1) {
			return 'sidebar-nav-item__link active';
		}
		return 'sidebar-nav-item__link';
	};

	const submenuParentClickHandler = (event: any) => {
		const target = event.target.closest('.sidebar-nav-item__link');
		if (target.classList.contains('active')) {
			target.classList.remove('active');
		} else {
			target.classList.add('active');
		}
	};

	return (
		<div className="sidebar-nav">
			<div className="sidebar-nav__logo">
				<img src={Logo} alt="" />
			</div>
			<div className="sidebar-nav-item">
				<nav className="sidebar-nav-item__list">
					<ul>
						<li>
							<NavLink to={navList.wallets.path} activeClassName="active">
								<div className="sidebar-nav-item__icon">
									<IconSvg name="wallet" w="20" h="20" />
								</div>
								<div className="sidebar-nav-item__text">
									<p>Wallets</p>
								</div>
							</NavLink>
						</li>
						<li>
							<NavLink to={navList.trade.path} activeClassName="active">
								<div className="sidebar-nav-item__icon">
									<IconSvg name="trade" w="20" h="20" />
								</div>
								<div className="sidebar-nav-item__text">
									<p>Trade</p>
								</div>
							</NavLink>
						</li>
						<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
							<div
								className={getNavLinkClass([
									navList.depositFiat.path,
									navList.depositCrypto.path,
									navList.withdrawFiat.path,
									navList.withdrawCripto.path,
								])}
								onClick={submenuParentClickHandler}
							>
								<div className="sidebar-nav-item__icon">
									<IconSvg name="payments" w="20" h="20" />
								</div>
								<div className="sidebar-nav-item__text">
									<p>Payments</p>
								</div>
							</div>
							<div className="sidebar-nav-item__submenu">
								<ul>
									<li>
										<NavLink
											to={navList.depositFiat.path}
											className={getNavLinkClass([
												navList.depositFiat.path,
												navList.depositCrypto.path,
											])}
											activeClassName=""
										>
											<div className="sidebar-nav-item__icon">
												<IconSvg name="deposit" w="20" h="20" />
											</div>
											<div className="sidebar-nav-item__text">
												<p>Deposit</p>
											</div>
										</NavLink>
									</li>
									<li>
										<NavLink
											to={navList.withdrawFiat.path}
											className={getNavLinkClass([
												navList.withdrawFiat.path,
												navList.withdrawCripto.path,
											])}
											activeClassName=""
										>
											<div className="sidebar-nav-item__icon">
												<IconSvg name="withdrawal" w="20" h="20" />
											</div>
											<div className="sidebar-nav-item__text">
												<p>Withdrawal</p>
											</div>
										</NavLink>
									</li>
								</ul>
							</div>
						</li>
						<li>
							<NavLink to={navList.paymentMethods.path} activeClassName="active">
								<div className="sidebar-nav-item__icon">
									<IconSvg name="beneficiaries" w="20" h="20" />
								</div>
								<div className="sidebar-nav-item__text">
									<p>Beneficiaries</p>
								</div>
							</NavLink>
						</li>
						{/* <li> */}
						{/*	<NavLink to={navList.transactionHistory.path} activeClassName="active"> */}
						{/*		<div className="sidebar-nav-item__icon"> */}
						{/*			<IconSvg name="wallet" w="24" h="24" /> */}
						{/*		</div> */}
						{/*		<div className="sidebar-nav-item__text"> */}
						{/*			<p>Transaction History</p> */}
						{/*		</div> */}
						{/*	</NavLink> */}
						{/* </li> */}
						<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
							<div
								className={getNavLinkClass([
									'/transactions-trade/trade',
									'/deposits-withdrawals/crypto',
								])}
								onClick={submenuParentClickHandler}
							>
								<div className="sidebar-nav-item__icon">
									<IconSvg name="history" w="20" h="20" />
								</div>
								<div className="sidebar-nav-item__text">
									<p>History</p>
								</div>
							</div>
							<div className="sidebar-nav-item__submenu">
								<ul>
									<li>
										<NavLink
											to={navList.transactionsTrade.path}
											className={getNavLinkClass(['/transactions-trade/trade'])}
											activeClassName=""
										>
											<div className="sidebar-nav-item__icon">
												<IconSvg name="trade-history" w="24" h="24" />
											</div>
											<div className="sidebar-nav-item__text">
												<p>Trade History</p>
											</div>
										</NavLink>
									</li>
									<li>
										<NavLink
											to={navList.depositsWithdrawals.path}
											className={getNavLinkClass(['/deposits-withdrawals/crypto'])}
											activeClassName=""
										>
											<div className="sidebar-nav-item__icon">
												<IconSvg name="deposit-withdrawal" w="24" h="24" />
											</div>
											<div className="sidebar-nav-item__text">
												<p>Deposits & Withdrawals</p>
											</div>
										</NavLink>
									</li>
								</ul>
							</div>
						</li>
						<li className="sidebar-nav-item__list-item sidebar-nav-item__list-item--has-children">
							<NavLink
								to="/account-details"
								className={getNavLinkClass([
									'/verification',
									'/security-settings',
									'/bonus-system',
								])}
								// onClick={submenuParentClickHandler}
							>
								<div className="sidebar-nav-item__icon">
									<IconSvg name="account-settings" w="20" h="20" />
								</div>
								<div className="sidebar-nav-item__text">
									<p>Account Settings</p>
								</div>
							</NavLink>
							<div className="sidebar-nav-item__submenu">
								<ul>
									<li>
										<NavLink
											to="/verification"
											className={getNavLinkClass(['/verification'])}
											activeClassName=""
										>
											<div className="sidebar-nav-item__icon">
												<IconSvg name="verification" w="24" h="24" />
											</div>
											<div className="sidebar-nav-item__text">
												<p>Verification</p>
											</div>
										</NavLink>
									</li>
									<li>
										<NavLink
											to="/security-settings"
											className={getNavLinkClass(['/security-settings'])}
											activeClassName=""
										>
											<div className="sidebar-nav-item__icon">
												<IconSvg name="security-settings" w="24" h="24" />
											</div>
											<div className="sidebar-nav-item__text">
												<p>Security</p>
											</div>
										</NavLink>
									</li>
									<li>
										<NavLink
											to="/bonus-system"
											className={getNavLinkClass(['/bonus-system'])}
											activeClassName=""
										>
											<div className="sidebar-nav-item__icon">
												<IconSvg name="referrals" w="24" h="24" />
											</div>
											<div className="sidebar-nav-item__text">
												<p>Referrals</p>
											</div>
										</NavLink>
									</li>
									<li>
										<button
											onClick={() => {
												dispatch(logoutRequest({ history }));
											}}
											type="button"
											className="sidebar-nav-item__link"
										>
											<div className="sidebar-nav-item__icon">
												<IconSvg name="logout" w="24" h="24" />
											</div>
											<div className="sidebar-nav-item__text">
												<p>Log Out</p>
											</div>
										</button>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default WalletSideBar;
