import { FC, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { capitalize, shortNotation } from 'services/utils/strings';
import { notificationContainer } from 'services/utils/notificationContainer';
import { CopyIcon } from 'assets/inline-svg';
import { statusClassNames, statusNames } from 'components/TransactionHistory/utils';
import { ICryptoTableBodyProps } from '../types';
import { roundingNumber } from '../../../../services/utils/roundingNumber';

const TableBody: FC<ICryptoTableBodyProps> = ({ itemList, localFilter }) => {
	const [itemFilter, setItemFilter] = useState(itemList);
	useEffect(() => {
		if (localFilter.state === 0) {
			setItemFilter(itemList);
		}

		if (localFilter.state === 1) {
			const max = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return a[localFilter.value] - b[localFilter.value];
			});
			setItemFilter(max);
		}
		if (localFilter.state === 2) {
			const min = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return b[localFilter.value] - a[localFilter.value];
			});
			setItemFilter(min);
		}
	}, [itemList, localFilter]);
	const formatDateTime = (sourceDate: string) => {
		const date = new Date(sourceDate);

		const yyyy = date.getFullYear();
		const mo = String(date.getMonth() + 1).padStart(2, '0');
		const dd = String(date.getDate()).padStart(2, '0');
		const hh = String(date.getHours()).padStart(2, '0');
		const mm = String(date.getMinutes()).padStart(2, '0');
		const ss = String(date.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}:${mm}:${ss}`];
	};

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard', 'info');
	};

	return (
		<div className="table-body">
			{itemFilter?.length ? (
				itemFilter?.map((item) => {
					const [date, time] = formatDateTime(item.created_at);
					return (
						<div key={item.id} className="tr">
							<div className="td td--nowrap">
								<div className="td-hidden-name">Timestamp</div>
								<p>
									{date}
									<br />
									<span className="td-more">{time}</span>
								</p>
							</div>
							<div className="td td--nowrap">
								<div className="td-hidden-name">Type</div>
								<p>{capitalize(item.type)}</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Cryptocurrency</div>
								<p>{item.asset.code.toUpperCase()}</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Network</div>
								<p>
									{item.chain.name} <br />
									<span className="td-more">
										{item.chain.name} Smart Chain ({item.chain.code})
									</span>
								</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Gross</div>
								<p>
									{roundingNumber(item.gross_fee, item.asset.code)} {item.asset.code.toUpperCase()}
								</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Fee</div>
								<p>
									{roundingNumber(item.fee, item.asset.code)} {item.asset.code.toUpperCase()}
								</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Net</div>
								<p>{roundingNumber(item.net_fee, item.asset.code)}</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Destination</div>
								<p className="copy-button">
									{item.destination ? shortNotation(item.destination, 3) : '-'}
									{item.destination && (
										<CopyToClipboard text={item.destination} onCopy={handleCopy}>
											<button type="button">
												<CopyIcon />
											</button>
										</CopyToClipboard>
									)}
								</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">Tx hash</div>
								<p className="copy-button">
									{item.tx_hash ? shortNotation(item.tx_hash, 2, 2) : '-'}
									{item.tx_hash && (
										<CopyToClipboard text={item.tx_hash} onCopy={handleCopy}>
											<button type="button">
												<CopyIcon />
											</button>
										</CopyToClipboard>
									)}
								</p>
							</div>
							<div className="td">
								<div className="td-hidden-name">TxID</div>
								<p>{item?.id}</p>
								{/* <p className="copy-button"> */}
								{/*	<p>{item.tx_id !== null ? shortNotation(item.tx_id, 2, 2) : '-'}</p> */}
								{/*	{item.tx_id && ( */}
								{/*		<CopyToClipboard text={item.tx_id} onCopy={handleCopy}> */}
								{/*			<button type="button"> */}
								{/*				<CopyIcon /> */}
								{/*			</button> */}
								{/*		</CopyToClipboard> */}
								{/*	)} */}
								{/* </p> */}
							</div>
							<div className="td td--right">
								<div className="td-hidden-name">Status</div>
								<p className={statusClassNames[item.status]}>{statusNames[item.status]}</p>
							</div>
						</div>
					);
				})
			) : (
				<div className="td text--center">Transactions is empty</div>
			)}
		</div>
	);
};

export default TableBody;
