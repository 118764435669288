import { FC } from 'react';
import Auth from 'layouts/Auth';
import SecuritySettings from 'components/SecuritySettings/SecuritySettings';

// ==========================================:
const SecuritySettingsPage: FC = () => {
	return (
		<Auth title="Security settings">
			<SecuritySettings />
		</Auth>
	);
};
export default SecuritySettingsPage;
