import Auth from 'layouts/Auth';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import TransactionsTrade from 'components/TransactionsTrade/TransactionsTrade';

const TransactionsTradesPage = () => {
	return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />

					<TransactionsTrade />
				</div>
			</section>
		</Auth>
	);
};

export default TransactionsTradesPage;
