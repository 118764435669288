import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import L from 'i18n-react';
import { confirmationPasswordRequest } from 'redux/reducers/confirmation/reducer';
import { notificationsInfoFields } from 'services/utils/ipuntFields/ipuntFields';
import Input from '../../../ui/Formik/Input';
import { IRegisterSubmitValue } from './types';
import { popUpOpen } from '../../../redux/reducers/popUp/reducer';

const CreatePassword = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
	const registerPasswordSubmit = (obg: IRegisterSubmitValue) => {
		const { password, confirmPassword } = obg;
		if (password === confirmPassword) {
			const url = window.location.toString();
			const token = url.split('token=').pop()?.split('&')[0];
			localStorage.confirmationToken = token;
			dispatch(confirmationPasswordRequest({ token, password, history }));
		}
	};
	const initialValues = {
		password: '',
		confirmPassword: '',
	};
	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.required(notificationsInfoFields.passwordValidation.required)
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				notificationsInfoFields.passwordValidation.matches,
			)
			.max(25, notificationsInfoFields.passwordValidation.maxLength),
		confirmPassword: yup
			.string()
			.required(notificationsInfoFields.passwordValidation.required)
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				notificationsInfoFields.passwordValidation.matches,
			)
			.oneOf([yup.ref('password'), null], notificationsInfoFields.passwordValidation.passwordsMatch)
			.max(25, notificationsInfoFields.passwordValidation.maxLength),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleConfirmPassDisplay = (): void => {
		setIsShowConfirmPass(!isShowConfirmPass);
	};
	return (
		<>
			<section className="create-account-section">
				<div className="container">
					<div className="create-account-progress">
						<div className="create-account-progress__step active">
							<div className="create-account-progress__step-number">1</div>
							<div className="create-account-progress__step-text">
								<p>Create Password</p>
							</div>
						</div>
						<div className="create-account-progress__line">
							<svg
								width="328"
								height="27"
								viewBox="0 0 328 27"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle className="fill" cx="7.5" cy="13.5" r="7.5" fill="#E6E8EC" />
								<rect className="fill" x="14" y="13" width="300" height="1" fill="#E6E8EC" />
								<circle className="stroke" cx="320.5" cy="13.5" r="7" stroke="#E6E8EC" />
							</svg>
						</div>
						<div className="create-account-progress__step">
							<div className="create-account-progress__step-number">2</div>
							<div className="create-account-progress__step-text">
								<p>2FA Setup</p>
							</div>
						</div>
					</div>
					<>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={(values: IRegisterSubmitValue, { resetForm, setSubmitting }) => {
								registerPasswordSubmit(values);
								setSubmitting(false);
								resetForm();
							}}
							validateOnBlur
							enableReinitialize
						>
							{({ isSubmitting }) => (
								<Form className="form">
									<div className="authorization-form">
										<h2 className="form-title">Create Password</h2>
										<p className="form-subtitle">
											Welcome! Please, create password for your account
										</p>
										<div className="form-body">
											<div className="authoriztion-item">
												<div className="login-form-item">
													<div className="input input--mb-0">
														<p className="input__name">Password</p>
														<div className="input-wrapper">
															<Field
																type={isShowPass ? 'text' : 'password'}
																placeholder="Enter Your Password"
																name="password"
																required
																component={Input}
																ariaLabel="show-password"
																showPass={isShowPass}
																setIsShowPass={handlePassDisplay}
															/>

															<button
																onClick={() => {
																	setIsShowPass(!isShowPass);
																}}
																type="button"
																className="show-pass"
															>
																<svg
																	style={{ display: isShowPass ? 'none' : 'block' }}
																	className="text-type"
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M17.8201 5.76998C16.0701 4.44998 14.0701 3.72998 12.0001 3.72998C8.47009 3.72998 5.18009 5.80998 2.89009 9.40998C1.99009 10.82 1.99009 13.19 2.89009 14.6C3.68009 15.84 4.60009 16.91 5.60009 17.77"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M9.47 14.53L2 22"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M22 2L14.53 9.47"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																</svg>
																<svg
																	style={{ display: isShowPass ? 'block' : 'none' }}
																	className="password-type"
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																</svg>
															</button>
														</div>
													</div>
												</div>
											</div>
											<div className="authoriztion-item">
												<div className="login-form-item">
													<div className="input input--mb-0">
														<p className="input__name">Confirm Password</p>
														<div className="input-wrapper">
															<Field
																type={isShowConfirmPass ? 'text' : 'password'}
																placeholder="Confirm password"
																name="confirmPassword"
																required
																component={Input}
																ariaLabel="show-confirm-password"
																showPass={isShowConfirmPass}
																setIsShowConfirmPass={handleConfirmPassDisplay}
															/>

															<button
																onClick={() => {
																	setIsShowConfirmPass(!isShowConfirmPass);
																}}
																type="button"
																className="show-pass"
															>
																<svg
																	style={{ display: isShowConfirmPass ? 'none' : 'block' }}
																	className="text-type"
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M17.8201 5.76998C16.0701 4.44998 14.0701 3.72998 12.0001 3.72998C8.47009 3.72998 5.18009 5.80998 2.89009 9.40998C1.99009 10.82 1.99009 13.19 2.89009 14.6C3.68009 15.84 4.60009 16.91 5.60009 17.77"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M9.47 14.53L2 22"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M22 2L14.53 9.47"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																</svg>
																<svg
																	style={{ display: isShowConfirmPass ? 'block' : 'none' }}
																	className="password-type"
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
																		stroke="#777E90"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																</svg>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="form-footer form-footer--column">
											<button
												disabled={isSubmitting}
												aria-label="form-submit"
												type="submit"
												className="button button--full-width"
											>
												Continue
											</button>
											<button
												onClick={() => {
													dispatch(popUpOpen('supportSuccess'));
												}}
												type="button"
												className="button button--type2 button--full-width"
											>
												Support
											</button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</>

					{/*	<div className="authorization-form"> */}
					{/*	<h2 className="form-title">Create Password</h2> */}
					{/*	<p className="form-subtitle">Welcome! Please, create password for your account</p> */}
					{/*	<div className="form-body"> */}
					{/*		<div className="authoriztion-item"> */}
					{/*			<div className="input input--mb-0"> */}
					{/*				<p className="input__name">Password</p> */}
					{/*				<div className="input-wrapper"> */}
					{/*					<input className="input-item " type="text" placeholder="Enter Your Password" /> */}
					{/*					<button type="button" className="show-pass"> */}
					{/*						<svg */}
					{/*							className="text-type" */}
					{/*							width="24" */}
					{/*							height="24" */}
					{/*							viewBox="0 0 24 24" */}
					{/*							fill="none" */}
					{/*							xmlns="http://www.w3.org/2000/svg" */}
					{/*						> */}
					{/*							<path */}
					{/*								d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*							<path */}
					{/*								d="M17.8201 5.76998C16.0701 4.44998 14.0701 3.72998 12.0001 3.72998C8.47009 3.72998 5.18009 5.80998 2.89009 9.40998C1.99009 10.82 1.99009 13.19 2.89009 14.6C3.68009 15.84 4.60009 16.91 5.60009 17.77" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*							<path */}
					{/*								d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*							<path */}
					{/*								d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*							<path */}
					{/*								d="M9.47 14.53L2 22" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*							<path */}
					{/*								d="M22 2L14.53 9.47" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*						</svg> */}
					{/*						<svg */}
					{/*							className="password-type" */}
					{/*							width="24" */}
					{/*							height="24" */}
					{/*							viewBox="0 0 24 24" */}
					{/*							fill="none" */}
					{/*							xmlns="http://www.w3.org/2000/svg" */}
					{/*						> */}
					{/*							<path */}
					{/*								d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*							<path */}
					{/*								d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*						</svg> */}
					{/*					</button> */}
					{/*				</div> */}
					{/*			</div> */}
					{/*		</div> */}
					{/*		<div className="authoriztion-item"> */}
					{/*			<div className="input input--mb-0"> */}
					{/*				<p className="input__name">Confirm Password</p> */}
					{/*				<div className="input-wrapper"> */}
					{/*					<input */}
					{/*						className="input-item " */}
					{/*						type="password" */}
					{/*						placeholder="Confirm Your Password" */}
					{/*					/> */}
					{/*					<button type="button" className="show-pass"> */}
					{/*						<svg */}
					{/*							className="text-type" */}
					{/*							width="24" */}
					{/*							height="24" */}
					{/*							viewBox="0 0 24 24" */}
					{/*							fill="none" */}
					{/*							xmlns="http://www.w3.org/2000/svg" */}
					{/*						> */}
					{/*							<path */}
					{/*								d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*							<path */}
					{/*								d="M17.8201 5.76998C16.0701 4.44998 14.0701 3.72998 12.0001 3.72998C8.47009 3.72998 5.18009 5.80998 2.89009 9.40998C1.99009 10.82 1.99009 13.19 2.89009 14.6C3.68009 15.84 4.60009 16.91 5.60009 17.77" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*							<path */}
					{/*								d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*							<path */}
					{/*								d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*							<path */}
					{/*								d="M9.47 14.53L2 22" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*							<path */}
					{/*								d="M22 2L14.53 9.47" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*						</svg> */}
					{/*						<svg */}
					{/*							className="password-type" */}
					{/*							width="24" */}
					{/*							height="24" */}
					{/*							viewBox="0 0 24 24" */}
					{/*							fill="none" */}
					{/*							xmlns="http://www.w3.org/2000/svg" */}
					{/*						> */}
					{/*							<path */}
					{/*								d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*							<path */}
					{/*								d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z" */}
					{/*								stroke="#777E90" */}
					{/*								strokeWidth="1.5" */}
					{/*								strokeLinecap="round" */}
					{/*								strokeLinejoin="round" */}
					{/*							/> */}
					{/*						</svg> */}
					{/*					</button> */}
					{/*				</div> */}
					{/*			</div> */}
					{/*		</div> */}
					{/*	</div> */}
					{/*	<div className="form-footer form-footer--column"> */}
					{/*		<button type="button" className="button button--full-width"> */}
					{/*			Continue */}
					{/*		</button> */}
					{/*		<button type="button" className="button button--type2 button--full-width"> */}
					{/*			Support */}
					{/*		</button> */}
					{/*	</div> */}
					{/* </div> */}
				</div>
			</section>
		</>
	);
};
export default CreatePassword;
