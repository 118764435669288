/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IBankAccountsStore,
	IBankAccountRequestPayload,
	IBankAccountRequestPayloadWithId,
	IBankAccountGetResponse,
	IBankAccountsPageInfo,
} from './types';

// ==========================================:
export const initialState: IBankAccountsStore = {
	data: {
		current_page: 1,
		data: [],
		first_page_url: '',
		from: 1,
		last_page: 1,
		last_page_url: '',
		links: [],
		next_page_url: '',
		path: '',
		per_page: 5,
		prev_page_url: '',
		to: 1,
		id: 1,
		total: 1,
	},
	loading: false,
};

// ==========================================:
const bankAccounts = createSlice({
	name: '@@bankAccounts',
	initialState,
	reducers: {
		getBankAccountsRequest: (state) => {
			const newState = state;

			newState.loading = true;
		},
		getBankAccountsSuccess: (state, action: PayloadAction<IBankAccountGetResponse>) => {
			const { payload } = action;
			const newState = state;
			newState.data = payload;
			newState.loading = false;
		},
		deleteBankAccountRequest: (state, action: PayloadAction<{ id: number }>) => {
			const newState = state;

			newState.loading = true;
		},
		createBankAccountRequest: (state, action: PayloadAction<IBankAccountRequestPayload>) => {
			const newState = state;
			newState.loading = true;
		},
		updateBankAccountRequest: (state, action: PayloadAction<IBankAccountRequestPayloadWithId>) => {
			const newState = state;

			newState.loading = true;
		},
		changeBankAccountsPage: (state, action: PayloadAction<IBankAccountsPageInfo>) => {
			const newState = state;
			newState.data.current_page = action.payload.current_page;
			newState.data.per_page = action.payload.per_page;
			newState.loading = true;
		},
	},
});

export default bankAccounts.reducer;
export const {
	getBankAccountsRequest,
	getBankAccountsSuccess,
	deleteBankAccountRequest,
	createBankAccountRequest,
	updateBankAccountRequest,
	changeBankAccountsPage,
} = bankAccounts.actions;
