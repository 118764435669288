import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import {
	depositAddressRequest,
	depositAddressSuccess,
	depositsInitState,
	feeRequest,
	feeSuccess,
} from './reducer';
import { IDepositAddressRequest, IDepositAddressResponse } from './types';

// =============================================================:
function* depositAddressRequestWorker(action: PayloadAction<IDepositAddressRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IDepositAddressResponse = yield call(api.deposits.getDepositAddress, payload);

		yield put(depositAddressSuccess(response));
	} catch (error) {
		yield put(depositsInitState);
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* feeRequestWorker(action: PayloadAction<any>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: any = yield call(api.deposits.getFee, payload);
		yield put(feeSuccess(Number(response.toFixed(6))));
	} catch (error) {
		yield put(depositsInitState);
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
export function* depositsSaga() {
	yield takeEvery(depositAddressRequest.type, depositAddressRequestWorker);
	yield takeEvery(feeRequest.type, feeRequestWorker);
}
