import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { getWalletsCryptoList } from 'redux/reducers/wallets/selectors';
import { getCryptoCurrencyData } from 'redux/reducers/currency/selectors';
import { getWalletAddresses } from 'redux/reducers/walletAddresses/selectors';
import {
	createWalletAddressRequest,
	updateWalletAddressRequest,
} from 'redux/reducers/walletAddresses/reducer';

import Input from 'ui/Formik/Input';
import CurrencySelect from 'ui/Formik/Select/CurrencySelect';
import { ICurrencySelectItem } from 'ui/Formik/Select/CurrencySelect/types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';

export interface IWalletAddressForm {
	id: number | null;
	closeForm: () => void;
}

const WalletAddressForm: FC<IWalletAddressForm> = ({ closeForm, id }) => {
	const dispatch = useDispatch();
	const wallets = useSelector(getWalletsCryptoList);
	const walletAddresses = useSelector(getWalletAddresses);
	const cryptoCurrency = useSelector(getCryptoCurrencyData);

	const [selectedCoin, setSelectedCoin] = useState<ICurrencySelectItem | null>(null);
	const [selectedNetwork, setSelectedNetwork] = useState<ICurrencySelectItem | null>(null);

	useEffect(() => {
		dispatch(currencyDataRequest());
		dispatch(getWalletsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [initialInputValues, setInitialInputValues] = useState({
		nickname: '',
		address: '',
	});

	const validationSchema = yup.object().shape({
		nickname: yup
			.string()
			.required(notificationsInfoFields.nickname.required)
			.min(1, notificationsInfoFields.nickname.min)
			.max(100, notificationsInfoFields.nickname.max),
		address: yup
			.string()
			.required(notificationsInfoFields.address.required)
			.min(1, notificationsInfoFields.address.min)
			.max(100, notificationsInfoFields.address.max),
	});
	useEffect(() => {
		if (id) {
			const walletAddress = walletAddresses.filter((el) => el.id === id);
			setInitialInputValues({
				nickname: walletAddress[0].nickname,
				address: walletAddress[0].address,
			});
			const currency = cryptoCurrency?.filter(
				(el) => el.chains[0].id === walletAddress[0].chain_id,
			);
			setSelectedCoin(currency[0]);
			setSelectedNetwork(currency[0].chains[0]);
		}
	}, [cryptoCurrency, walletAddresses, id]);

	const onCurrencyChange = (el: ICurrencySelectItem) => {
		setSelectedCoin(el);
		setSelectedNetwork(null);
	};

	const onSubmit = (obj: { nickname: string; address: string }) => {
		const wallet = wallets?.filter((el) => el.asset.code === selectedCoin?.code);
		if (wallet?.length && selectedNetwork?.pivot) {
			const requestObj = { ...obj, ...selectedNetwork.pivot, balance_id: wallet[0].id };
			id
				? dispatch(updateWalletAddressRequest({ ...requestObj, id }))
				: dispatch(createWalletAddressRequest(requestObj));
		}
		setSelectedCoin(null);
		setSelectedNetwork(null);
	};

	return (
		<>
			<div className="wallet-operations-header-wrap">
				<div className="wallet-operations-header">
					<button onClick={closeForm} type="button" className="wallet-operations-header__back">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
								stroke="#173B58"
								strokeWidth="1.5"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M20.5 12H3.67"
								stroke="#173B58"
								strokeWidth="1.5"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
					<p>{id ? 'Update' : 'Add'} Wallet Address</p>
				</div>
			</div>
			<Formik
				validationSchema={validationSchema}
				initialValues={initialInputValues}
				onSubmit={(obj, { resetForm, setSubmitting }) => {
					setSubmitting(false);
					resetForm();
					onSubmit(obj);
					closeForm();
				}}
				enableReinitialize
				validateOnBlur
			>
				{({ isSubmitting, isValid, dirty }) => (
					<Form className="form">
						<div className="form-body wallet-operations-item coin__text-normal">
							<CurrencySelect
								title="Coin"
								type="text"
								dropdownTitle="Select Currency"
								placeholder="Select Currency"
								arr={cryptoCurrency}
								onChange={onCurrencyChange}
								activeValue={selectedCoin || undefined}
							/>

							<CurrencySelect
								title="Network"
								type="text"
								dropdownTitle="Select Network"
								placeholder="Choose Bank Account"
								arr={selectedCoin?.chains}
								onChange={setSelectedNetwork}
								activeValue={selectedNetwork || undefined}
							/>

							<Field
								title="Wallet Nickname"
								type="text"
								placeholder="Enter Wallet Nickname"
								name="nickname"
								required
								component={Input}
							/>

							<Field
								title="Wallet Address"
								type="text"
								placeholder="Enter Wallet Address"
								name="address"
								required
								component={Input}
							/>

							<button
								type="submit"
								disabled={!(isValid && dirty && selectedCoin && selectedNetwork) || isSubmitting}
								className="button button--full-width"
							>
								Confirm
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default WalletAddressForm;
