import { useMemo } from 'react';

export const useFormatDateTime = (sourceDate: string) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const today = new Date(sourceDate);

	const date = useMemo(() => {
		const yyyy = today.getFullYear();
		const mo = String(today.getMonth() + 1).padStart(2, '0');
		const dd = String(today.getDate()).padStart(2, '0');
		const hh = String(today.getHours()).padStart(2, '0');
		const mm = String(today.getMinutes()).padStart(2, '0');
		const ss = String(today.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}-${mm}-${ss}`];
	}, [today]);
	return date;
};
