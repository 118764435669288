import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import HistoryHeader from './HistoryHeader/HistoryHeader';
import Trade from './Trade/Trade';

const TransactionsTrade = () => {
	const { path: matchPath } = useRouteMatch();
	return (
		<div className="wallet-operations">
			<HistoryHeader />
			<Switch>
				<Route exact path={`${matchPath}/trade`} component={Trade} />
				{/* <Route exact path={`${matchPath}/crypto`} component={Crypto} /> */}
				{/* <Route exact path={`${matchPath}/fiat`} component={Fiat} /> */}

				<Redirect to={`${matchPath}/trade`} />
			</Switch>
		</div>
	);
};

export default TransactionsTrade;
