/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IWalletAddressStore,
	IWalletAddressGetResponse,
	IWalletAddressRequestPayload,
	IWalletAddressRequestPayloadWithId,
	IWalletAddressesPageInfo,
} from './types';

// ==========================================:
export const initialState: IWalletAddressStore = {
	data: {
		current_page: 1,
		data: [],
		first_page_url: '',
		from: 1,
		last_page: 1,
		last_page_url: '',
		links: [],
		next_page_url: '',
		path: '',
		per_page: 5,
		prev_page_url: '',
		to: 1,
		id: 1,
		total: 1,
	},
	loading: false,
};

// ==========================================:2
const walletAddresses = createSlice({
	name: '@@walletAddresses',
	initialState,
	reducers: {
		getWalletAddressesRequest: (state) => {
			const newState = state;

			newState.loading = true;
		},
		getWalletAddressesSuccess: (state, action: PayloadAction<IWalletAddressGetResponse>) => {
			const newState = state;
			newState.data = action.payload;
			newState.loading = false;
		},
		deleteWalletAddressRequest: (state, action: PayloadAction<{ id: number; page?: number }>) => {
			const newState = state;

			newState.loading = true;
		},
		createWalletAddressRequest: (state, action: PayloadAction<IWalletAddressRequestPayload>) => {
			const newState = state;
			newState.loading = true;
		},
		updateWalletAddressRequest: (
			state,
			action: PayloadAction<IWalletAddressRequestPayloadWithId>,
		) => {
			const newState = state;

			newState.loading = true;
		},
		changeWalletAddressesPage: (state, action: PayloadAction<IWalletAddressesPageInfo>) => {
			const newState = state;
			newState.data.current_page = action.payload.current_page;
			newState.data.per_page = action.payload.per_page;
			newState.loading = true;
		},
	},
});

export default walletAddresses.reducer;
export const {
	getWalletAddressesRequest,
	getWalletAddressesSuccess,
	updateWalletAddressRequest,
	deleteWalletAddressRequest,
	createWalletAddressRequest,
	changeWalletAddressesPage,
} = walletAddresses.actions;
