/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITradeExchangeRatePayload } from 'services/api/transactions/types';
import { toDinamicDecimals, toMaxDecimals } from 'services/utils/numbers';
import { ITradeChangeAssetCountPayload, ITradeChangeAssetPayload, ITradeStore } from './types';

// ==========================================:
export const initialState: ITradeStore = {
	from_asset_id: '1',
	from_asset_code: 'btc',
	from_asset_count: '',
	to_asset_id: '10',
	to_asset_code: 'eur',
	to_asset_count: '',
};

// ==========================================:
const trade = createSlice({
	name: '@@trade',
	initialState,
	reducers: {
		swapTradeValues: (state) => {
			const newState = state;
			const stateCopy = { ...state };
			newState.from_asset_id = stateCopy.to_asset_id;
			newState.from_asset_code = stateCopy.to_asset_code;
			newState.from_asset_count = stateCopy.to_asset_count;
			newState.to_asset_id = stateCopy.from_asset_id;
			newState.to_asset_code = stateCopy.from_asset_code;
			newState.to_asset_count = stateCopy.from_asset_count;
		},
		changeAsset: (state, { payload }: PayloadAction<ITradeChangeAssetPayload>) => {
			const newState = state;
			if (payload.type === 'from') {
				newState.from_asset_id = payload.asset_id || '';
				newState.from_asset_code = payload.asset_code || '';
			} else if (payload.type === 'to') {
				newState.to_asset_id = payload.asset_id || '';
				newState.to_asset_code = payload.asset_code || '';
			}
			newState.from_asset_count = '';
			newState.to_asset_count = '';
		},

		changeAssetCount: (state, { payload }: PayloadAction<ITradeChangeAssetCountPayload>) => {
			const newState = state;
			if (payload.type === 'from') {
				newState.from_asset_count = payload.asset_count;
			} else if (payload.type === 'to') {
				newState.to_asset_count = payload.asset_count;
			}
		},

		tradeInitState: () => initialState,
	},
});

export default trade.reducer;
export const { swapTradeValues, changeAsset, changeAssetCount, tradeInitState } = trade.actions;
