import TwoFaRegistrations from 'components/Confirmation/TwoFaRegistrations/TwoFaRegistrations';
import Dashboard from 'layouts/Dashboard';
import { FC } from 'react';

const RegistrationTwaFaPage: FC = () => {
	return (
		<Dashboard title="Registration TwoFa">
			<TwoFaRegistrations />
		</Dashboard>
	);
};
export default RegistrationTwaFaPage;
