import React, { FC, useEffect, useState } from 'react';
// import { TriangleArrowDownIcon2, CheckIcon } from 'assets/inline-svg';
import { useSelect } from 'hooks/useSelect';
import { capitalize, trimBottomLine } from 'services/utils/strings';

export const Selected: FC<any> = ({ changeFilter, selectedItemsArray, text, resetSelected }) => {
	const [selectedItems, setSelectedItems] = useState<any[]>([
		{
			id: 0,
			text: 'All',
		},
	]);
	useEffect(() => {
		setSelectedItems([...selectedItems, ...selectedItemsArray]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [selectedItem, setSelectedItem] = useState<any>(selectedItems[0]);
	useEffect(() => {
		setSelectedItem(selectedItems[0]);
	}, [selectedItems, resetSelected]);
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const handleSelect = (value: any) => {
		if (changeFilter) {
			changeFilter({ [text]: value.text === 'All' ? undefined : value.text });
		}
		setSelectedItem(value);
		setOpen(false);
	};

	return (
		<div className="select-block select-block--mb-0">
			<div className="select-block__title">
				<p className="select-block__name">{trimBottomLine(capitalize(text))}</p>
			</div>
			<div className={`select select--type-choice ${open ? 'active' : ''}`}>
				<button type="button" className="select__current" ref={triggerRef} onClick={toggleOpen}>
					<div className="selectInput">
						{trimBottomLine(
							capitalize(selectedItems.find((opt: any) => opt?.text === selectedItem?.text)?.text),
						)}
					</div>
					<span className="select__current-arrow">
						<svg
							width="10"
							height="5"
							viewBox="0 0 10 5"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M10 0H1H0L5 5L10 0Z" fill="#777E90" />
						</svg>
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll select__drop-scroll--type2">
						<div className="select__drop-item">
							<ul>
								{selectedItems.map((e: any) => (
									<li key={e.id}>
										<button
											type="button"
											className={selectedItem.text === e.text ? 'active' : ''}
											onClick={() => handleSelect(e)}
										>
											<div className="selectInput">
												<span>{trimBottomLine(capitalize(e.text))}</span>
											</div>
											<span>
												{trimBottomLine(capitalize(selectedItem.text)) ===
													trimBottomLine(capitalize(e.text))}
											</span>
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
