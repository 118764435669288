import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ITwoFoStore } from './types';

// ==========================================:
const getTwoFaState = (state: IStoreState): ITwoFoStore => state.twoFa;
export const getTwoFa = createSelector([getTwoFaState], (twoFa: ITwoFoStore) => twoFa);

// ====================================================:
export const getTwoFaData = createSelector([getTwoFa], (twoFa: ITwoFoStore) => twoFa.twoFaDate);
