import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import Input from 'ui/Formik/Input';
import { resetPasswordRequest } from 'redux/reducers/auth/reducer';
import ReCAPTCHA from 'react-google-recaptcha';
import { IResetPasswordPayload } from 'redux/reducers/auth/types';
import InputTotp from 'ui/Formik/InputTotp';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';

interface IForm {
	email: string;
	totp: string;
	reason: string;
}

const ResetPasswordForm = () => {
	const dispatch = useDispatch();
	const siteKey = process.env.REACT_APP_GOOGLE_CAPTCHA;
	const reCaptchaRef = useRef<ReCAPTCHA>(null);
	const [reCaptchaKey, setReCaptchaKey] = useState<string | null>(null);
	const [check, setCheck] = useState(false);
	const onReCaptchaChange = (key: string | null) => {
		setReCaptchaKey(key);
	};

	const onSubmit = (obj: IResetPasswordPayload) => {
		dispatch(resetPasswordRequest(obj));
	};

	const initialValues = {
		email: '',
		totp: '',
		reason: '',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(notificationsInfoFields.email.required)
			.email(notificationsInfoFields.email.invalid)
			.max(60, notificationsInfoFields.email.maxLength),
		totp: yup
			.string()
			.required(notificationsInfoFields.totp.required)
			.length(6, notificationsInfoFields.totp.length)
			.matches(/\d/, notificationsInfoFields.totp.matches),
		reason: yup
			.string()
			.required(notificationsInfoFields.reason.required)
			.max(120, notificationsInfoFields.reason.max),
	});
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IForm, { setSubmitting }) => {
				onSubmit({
					email: values.email,
					totp: values.totp,
					reason: values.reason,
					captcha: reCaptchaKey || '',
				});
				setSubmitting(false);
				reCaptchaRef?.current?.reset();
				// resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting }) => (
				<Form className="form">
					<div className="login-form-item">
						<div className="input input--mb-0">
							<p className="input__name">Email</p>
							<div className="input-wrapper">
								<Field type="email" placeholder="Email" name="email" required component={Input} />
							</div>
						</div>
					</div>
					<div className="login-form-item">
						<div className="input input--mb-0">
							<p className="input__name">Authentication Code</p>
							<div className="input-wrapper">
								<Field
									type="text"
									placeholder="Code"
									name="totp"
									required
									component={InputTotp}
									link={ROUTES.RESET_TWOFA}
									linkTitle="Reset 2FA Code"
								/>
							</div>
						</div>
					</div>
					<div className="login-form-item">
						<div className="input input--mb-0">
							<p className="input__name">Reason</p>
							<div className="input-wrapper">
								<Field placeholder="Reason" name="reason" required component={Input} />
							</div>
						</div>
					</div>

					<div className="login-recaptcha">
						<ReCAPTCHA
							ref={reCaptchaRef}
							onChange={onReCaptchaChange}
							sitekey={siteKey || ''}
							hl="en"
						/>
					</div>

					<div className="login-form-item">
						<button
							disabled={isSubmitting}
							className="button button--full-width"
							aria-label="form-submit"
							type="submit"
						>
							Confirm
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ResetPasswordForm;
