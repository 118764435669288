import { useField } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Select from '../index';
import { ICountrySelect, ICountrySelectCustomList, ICountrySelectItem } from './types';

const CountrySelectCustomInput: FC<ICountrySelectItem> = ({ label, value }) => (
	<span className="coin coin--type3">
		<span className="coin__text">{label || 'Select Country'}</span>
	</span>
);

const CountrySelectCustomList: FC<ICountrySelectCustomList> = ({ arr, onChange }) => (
	<>
		{arr?.length ? (
			arr.map((el) => (
				<li key={el.value}>
					<button type="button" onClick={() => onChange(el)}>
						<span className="coin coin--type3 coin__text-normal">
							<span className="coin__text upper__text coin__text---pt-0">{el.label}</span>
						</span>
					</button>
				</li>
			))
		) : (
			<span className="coin coin--type3 coin__text-normal">
				<span className="coin__text upper__text coin__text---pt-0">No Items</span>
			</span>
		)}
	</>
);

const CountriesSelect: FC<ICountrySelect> = ({
	name,
	title,
	touched,
	arr,
	resetCustomSelect,
	setTouched,
	setResetCustomSelect,
	onChange,
	activeValue,
	...props
}) => {
	const initActiveCurrency = useMemo(
		() => ({
			label: '',
			value: '',
		}),
		[],
	);

	const [activeCurrency, setActiveCurrency] = useState<ICountrySelectItem>(initActiveCurrency);
	const [searchArr, setSearchArr] = useState<ICountrySelectItem[]>([]);

	useEffect(() => {
		if (arr) {
			setSearchArr(arr);
		}
	}, [arr]);

	useEffect(() => {
		if (resetCustomSelect) {
			setActiveCurrency(initActiveCurrency);
			!!setResetCustomSelect && setResetCustomSelect(false);
		}
	}, [initActiveCurrency, resetCustomSelect, setResetCustomSelect]);

	useEffect(() => {
		setActiveCurrency(activeValue || initActiveCurrency);
	}, [activeValue, initActiveCurrency]);

	const onChangeHandler = (el: ICountrySelectItem) => {
		setActiveCurrency(el);
		!!onChange && onChange(el);
	};
	const customList = {
		list: <CountrySelectCustomList arr={searchArr} onChange={onChangeHandler} />,
		activeElement: activeCurrency.label,
	};

	useEffect(() => {
		if (arr?.length === 1) {
			onChangeHandler(arr[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arr, activeCurrency]);

	return (
		<>
			<p className="input__name">{title}</p>
			<Select
				{...props}
				setTouched={setTouched}
				touched={touched && !activeValue?.value}
				customInput={<CountrySelectCustomInput {...activeCurrency} />}
				setSearchArr={setSearchArr}
				customList={customList}
				arr={arr}
			/>
		</>
	);
};

export default CountriesSelect;
