import { FormikValues } from 'formik';
import { FC } from 'react';
import FormControl from 'ui/Formik/FormConrol';
import { IAdminInput } from './types';

// ==========================================:
const Input: FC<IAdminInput & FormikValues> = (props) => {
	const { ariaLabel, type, placeholder, field, className, onKeyUp, disabled, inputMode } = props;

	return (
		<FormControl ariaLabel={ariaLabel} {...props}>
			{type !== 'textarea' ? (
				<input
					className={`input-item ${String(className)}`}
					{...field}
					type={type}
					placeholder={placeholder}
					onKeyUp={onKeyUp}
					disabled={disabled}
					inputMode={inputMode || null}
				/>
			) : (
				<textarea
					className="input-item textarea_item"
					{...field}
					type={type}
					placeholder={placeholder}
					onKeyUp={onKeyUp}
					inputMode={inputMode || null}
				/>
			)}
		</FormControl>
	);
};

export default Input;
