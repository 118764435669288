import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
import { IInit } from './type';
/* eslint-disable no-debugger */
const Init: FC<IInit> = ({ setStore }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setStore(true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return <></>;
};

// Init.propTypes = {
// 	setStore: PropTypes.func.isRequired,
// };

export default Init;
// eslint-disable-next-line
