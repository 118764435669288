import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ICurrencyDataItem } from 'redux/reducers/currency/types';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { ILimits } from 'services/api/transactions/types';
import { roundingNumber } from 'services/utils/roundingNumber';

export interface ITradeInput {
	inputValue: string;
	inputError: boolean;
	selectValue: string;
	setInputError: (value: boolean) => void;
	onInputChange: (value: string, withError: boolean) => void;
	onSelectChange: (value: string, id: number) => void;
	currency?: ICurrencyDataItem[];
	allCurrency?: ICurrencyDataItem[];
	wallets?: IWalletItem[] | null;
	limits?: ILimits | null;
	setType: (value: string) => void;
}

const TradeInput: React.FC<ITradeInput> = ({
	inputValue,
	selectValue,
	inputError,
	onInputChange,
	onSelectChange,
	setInputError,
	currency,
	wallets,
	limits,
	setType,
	allCurrency,
}) => {
	const selectRef = useRef<HTMLDivElement | null>(null);

	const [selectList, setSelectList] = useState(currency);
	const [withError, setWithError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [selectOpen, setSelectOpen] = useState(false);
	const [availableBalance, setAvailableBalance] = useState('0');
	const [filteredSelectValue, setFilteredSelectValue] = useState<ICurrencyDataItem | undefined>(
		undefined,
	);

	const maxValue =
		!!limits && limits.limit_max < Number(availableBalance)
			? limits.limit_max
			: Number(availableBalance);

	const getAvailableBalance = useMemo(
		() => (value: string) => {
			const foundAvailableBalance = wallets?.find((el) => el.asset.code === value);
			const validateAvailableBalance =
				foundAvailableBalance && Number(foundAvailableBalance?.balance) > 0.001
					? foundAvailableBalance.balance
					: '0';
			setAvailableBalance(validateAvailableBalance);
			return validateAvailableBalance;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectValue, wallets],
	);

	const inputValidate = (value: string) => {
		// const numberValue = Number(value);
		// const validateZero = Number.isNaN(numberValue) ? true : numberValue > 0;
		const validateLimitMin = limits ? limits?.limit_min <= Number(value.replace(/,/g, '')) : true;
		const validateLimitMax = limits
			? Number(roundingNumber(maxValue, selectValue).replace(/,/g, '')) >=
			  Number(value.replace(/,/g, ''))
			: true;
		// !validateZero && setErrorMessage('Value must be greater than 0');

		!validateLimitMin &&
			setErrorMessage(`Min Amount is ${roundingNumber(limits?.limit_min, selectValue) || 0}`);
		!validateLimitMax &&
			setErrorMessage(`Max Amount is ${roundingNumber(maxValue, selectValue) || 1}`);
		const validate = !(validateLimitMin && validateLimitMax);
		setWithError(validate);
		return validate;
	};

	const chooseAllAvailableBalance = () => {
		const newValue = roundingNumber(availableBalance, selectValue).replace(/,/g, '');
		onInputChange(newValue, false);
	};

	const searchInSelect = (val: string) => {
		const filteredSelectList = selectList?.filter(({ code }) => code.includes(val.toLowerCase()));
		setSelectList(filteredSelectList);
	};

	const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const result = e.target.value.replace(/[^\d.]/g, '').replace(/^([^.]*\.)|\./g, '$1');
		const validate = inputValidate(result);
		onInputChange(result, validate);
	};

	const selectChangeHandler = (value: string, id: number, currencyType: string) => {
		setSelectOpen(false);
		!!onSelectChange && onSelectChange(value, id);
		setType(currencyType);
	};

	const formatValue = inputValue.includes('.')
		? inputValue.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
		: Number(inputValue).toLocaleString('en-US');

	useEffect(() => {
		setSelectList(currency?.filter((el) => el.code !== selectValue));
	}, [currency, selectValue]);

	useEffect(() => {
		if (wallets) {
			getAvailableBalance(selectValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectValue, wallets]);

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (!selectRef.current?.contains(event.target as Node)) {
				setSelectOpen(false);
			}
		};

		if (selectOpen) {
			document.addEventListener('mousedown', handleOutsideClick);
		}
		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, [selectOpen]);

	useEffect(() => {
		if (inputError || inputValue) {
			const validate = inputValidate(inputValue);
			setInputError(validate);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue, selectValue, availableBalance, limits]);

	useEffect(() => {
		setFilteredSelectValue(
			allCurrency?.find((el) => el.code.toLowerCase() === selectValue.toLowerCase()),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectValue, allCurrency]);

	return (
		<div className="form instant-trade-form-item__field instant-trade-form-item__field--exchange">
			<div className="input">
				<div className={`input-wrapper ${withError ? 'input--error' : ''}`}>
					<input
						className="input-item input-item--type3 input-item--pr50"
						type="text"
						placeholder={
							limits
								? `${roundingNumber(limits?.limit_min, selectValue)} - ${roundingNumber(
										maxValue,
										selectValue,
								  )}`
								: '0.01'
						}
						value={formatValue}
						onChange={inputChangeHandler}
					/>
					{!!wallets && (
						<div className="input-icon input-icon--auto input-icon--right">
							<button type="button" className="input-button" onClick={chooseAllAvailableBalance}>
								MAX
							</button>
						</div>
					)}
				</div>
				{withError && (
					<div className="input-notify">
						<span className="input-notify__text">{errorMessage}</span>
					</div>
				)}
				{!!wallets && (
					<div className="input__notification input__notification--type4">
						<p>
							Available Balance: <span>{roundingNumber(availableBalance, selectValue)}</span>{' '}
							<span className="element---uppercase input__notification---span-color">
								{selectValue}
							</span>
						</p>
					</div>
				)}
			</div>
			<div className="select-block">
				<div className={`select select--type3 ${selectOpen ? 'active' : ''}`}>
					<button
						type="button"
						className="select__current element---uppercase"
						onClick={() => setSelectOpen(!selectOpen)}
					>
						<span className="coin coin--type3 coin__text-normal">
							{filteredSelectValue?.img_path && (
								<span className="coin__icon">
									<img src={filteredSelectValue?.img_path} alt={selectValue} />
								</span>
							)}
							<span className="coin__text upper__text">
								{filteredSelectValue?.code || selectValue}
							</span>
						</span>
						<span className="select__current-arrow">
							<svg
								width="10"
								height="5"
								viewBox="0 0 10 5"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M10 0H1H0L5 5L10 0Z" fill="#777E90" />
							</svg>
						</span>
					</button>
					<div ref={selectRef} className="select__drop select__drop---top">
						<div className="input">
							<div className="input-wrapper">
								<input
									className="input-item"
									type="text"
									placeholder="Select Currency"
									onChange={(e) => searchInSelect(e.target.value)}
								/>
								<div className="input-icon input-icon--right">
									<svg
										width="20"
										height="21"
										viewBox="0 0 20 21"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M1.04199 9.24984C1.04199 4.99266 4.49315 1.5415 8.75033 1.5415C13.0075 1.5415 16.4587 4.99266 16.4587 9.24984C16.4587 13.507 13.0075 16.9582 8.75033 16.9582C4.49315 16.9582 1.04199 13.507 1.04199 9.24984ZM8.75033 2.7915C5.1835 2.7915 2.29199 5.68302 2.29199 9.24984C2.29199 12.8167 5.1835 15.7082 8.75033 15.7082C12.3171 15.7082 15.2087 12.8167 15.2087 9.24984C15.2087 5.68302 12.3171 2.7915 8.75033 2.7915Z"
											fill="#9D9DBC"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M5.95065 6.03433C6.66631 5.31872 7.65675 4.875 8.74962 4.875C9.84249 4.875 10.8329 5.31872 11.5486 6.03435C11.7926 6.27843 11.7926 6.67416 11.5486 6.91823C11.3045 7.16231 10.9088 7.16231 10.6647 6.91823C10.1739 6.42745 9.49767 6.125 8.74962 6.125C8.00158 6.125 7.32534 6.42744 6.8345 6.91825C6.59042 7.16232 6.19469 7.1623 5.95062 6.91822C5.70655 6.67413 5.70657 6.2784 5.95065 6.03433Z"
											fill="#9D9DBC"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M13.4008 13.9003C13.6449 13.6563 14.0406 13.6563 14.2847 13.9003L17.8203 17.4359C18.0643 17.68 18.0643 18.0757 17.8203 18.3198C17.5762 18.5638 17.1805 18.5638 16.9364 18.3198L13.4008 14.7842C13.1568 14.5401 13.1568 14.1444 13.4008 13.9003Z"
											fill="#9D9DBC"
										/>
									</svg>
								</div>
							</div>
						</div>
						<div className="select__drop-scroll">
							<div className="select__drop-item">
								<ul>
									{!!selectList?.length &&
										selectList.map(({ id, code, type, img_path, name }) => (
											<li key={id}>
												<button
													className="element---uppercase"
													onClick={() => selectChangeHandler(code, id, type)}
													type="button"
												>
													<span className="coin coin--type3 coin__text-normal">
														{img_path && (
															<span className="coin__icon">
																<img src={img_path} alt={code} />
															</span>
														)}
														<span className="coin__text upper__text coin__text---pt-0">
															{code} <br />
															<span className="coin__text-more coin__text-more--type2">{name}</span>
														</span>
													</span>
												</button>
											</li>
										))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TradeInput;
