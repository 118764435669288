import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import popupIconMail from 'assets/dist/img/popup-icon-mail.png';
import { IPopUp } from '../types';

const SuccessEmail: FC<IPopUp> = ({ open, closeModal }) => {
	const history = useHistory();
	return (
		<>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
					history.push('/');
				}}
			>
				<div className="modal">
					<div
						className="close"
						onClick={() => {
							closeModal();
							// history.push(redirect);
						}}
					/>
					<div className="popup popup--width-520">
						<div className="popup-header">
							<p className="popup-header__title">Verify Your Email</p>
						</div>
						<div className="popup-body">
							<div className="popup-text">
								<p>Please check your email for a link to verify your email address.</p>
								<p>Once verified, youll be able to continue.</p>
							</div>
							<div className="popup-icon">
								<img src={popupIconMail} alt="" />
							</div>
							<div className="popup-text">
								<p>
									Didnt receive an email?{' '}
									<a href="!#" className="link">
										Resend
									</a>
								</p>
							</div>
						</div>
						<div className="popup-footer">
							<div className="popup-submit">
								<button
									onClick={() => {
										closeModal();
										// history.push(redirect);
									}}
									type="button"
									className="button button--full-width"
								>
									Ok
								</button>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default SuccessEmail;
