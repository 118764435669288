import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IWalletsApi } from './types';

// ==========================================:
// ==========================================:
export const wallets: IWalletsApi = {
	getWallets: (payload) =>
		http.get(endpoint.wallets.WALLETS, { params: payload }).then((response) => {
			return response.data;
		}),
	validateAddress: (params) => http.post(endpoint.wallets.VALIDATE_WALLET, params),
};
