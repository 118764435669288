import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IWalletsResponsePayload, IWalletsStore, IGetWalletsRequestPayload } from './types';

// ==========================================:
export const initialState: IWalletsStore = {
	walletsList: null,
	walletsBalance: null,
	walletsLoader: false,
};

// ==========================================:
const wallets = createSlice({
	name: '@@wallets',
	initialState,
	reducers: {
		getWalletsRequest: (state, { payload }: PayloadAction<IGetWalletsRequestPayload>) => {
			const walletsRequestState = state;

			if (!payload?.disableLoading) {
				walletsRequestState.walletsLoader = true;
			}
		},

		getWalletsSuccess: (state, action: PayloadAction<IWalletsResponsePayload>) => {
			const { payload } = action;
			const walletsSuccessState = state;

			const newWallets = payload?.wallets?.sort((a, b) => {
				if (a.asset.code > b.asset.code) return 1;
				if (a.asset.code < b.asset.code) return -1;
				return 0;
			});

			walletsSuccessState.walletsList = newWallets || null;
			walletsSuccessState.walletsLoader = false;
			walletsSuccessState.walletsBalance = { fiat: payload.fiat, crypto: payload.crypto };
		},

		updateWalletsSuccess: (state, action: PayloadAction<IWalletsResponsePayload>) => {
			const { payload } = action;
			const updateWalletsSuccessState = state;

			updateWalletsSuccessState.walletsList = payload.wallets;
			updateWalletsSuccessState.walletsBalance = { fiat: payload.fiat, crypto: payload.crypto };
			updateWalletsSuccessState.walletsLoader = false;
		},

		walletsInitState: () => initialState,
	},
});

export default wallets.reducer;
export const { getWalletsRequest, getWalletsSuccess, updateWalletsSuccess, walletsInitState } =
	wallets.actions;
