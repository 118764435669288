import React, { FC, useEffect, useMemo, useState } from 'react';
import Select from '../index';
import { ICurrencySelectCustomList, IWalletAddress, IWalletAddressSelect } from './types';

const CurrencySelectCustomInput: FC<IWalletAddress> = ({ address }) => (
	<span className="coin coin--type3" style={{ display: address ? '' : 'none' }}>
		<span className="coin__text ">{address}</span>
	</span>
);

const CurrencySelectCustomList: FC<ICurrencySelectCustomList> = ({ arr, onChange }) => (
	<>
		{arr &&
			arr.map((el) => (
				<li key={el.id}>
					<button type="button" onClick={() => onChange(el)}>
						<span className="coin coin--type3">
							<span className="coin__text ">
								{el.nickname} <br />
							</span>
						</span>
					</button>
				</li>
			))}
	</>
);

const WalletAddressSelect: FC<IWalletAddressSelect> = ({
	arr,
	resetCustomSelect,
	setResetCustomSelect,
	onChange,
	...props
}) => {
	const initActiveCurrency = useMemo(() => ({ nickname: '', address: '' }), []);
	const [activeCurrency, setActiveCurrency] = useState<IWalletAddress>(initActiveCurrency);
	console.log(props?.form?.values?.wallet);
	useEffect(() => {
		setActiveCurrency(initActiveCurrency);
	}, [initActiveCurrency, props.form.values.wallet]);
	// useEffect(() => {
	// 	if (resetCustomSelect) {
	// 		setActiveCurrency(initActiveCurrency);
	// 		!!setResetCustomSelect && setResetCustomSelect(false);
	// 	}
	// }, [initActiveCurrency, resetCustomSelect, setResetCustomSelect]);

	const onChangeHandler = (el: IWalletAddress) => {
		setActiveCurrency(el);
		!!onChange && onChange(el);
	};

	const customList = {
		list: <CurrencySelectCustomList arr={arr} onChange={onChangeHandler} />,
		activeElement: activeCurrency.address,
	};

	return (
		<Select
			{...props}
			customInput={<CurrencySelectCustomInput {...activeCurrency} />}
			customList={customList}
		/>
	);
};

export default WalletAddressSelect;
