export const notificationsMessagesInfo = {
	loginSuccess: 'You are logged in.',
	logout: 'You are logged out.',
	CompanySaved: 'Company information has been saved.',
	registeredSuccess: 'You have successfully registered.',
	documentsSent: 'Documents sent.',
	twoFaSuccess: '2FA successful.',
	passwordSuccessfully: 'Password has been changed successfully.',
	emailSuccessfully: 'Email address has been changed successfully.',
	generationSuccessful: 'Generation has been completed successfully.',
	reset2FASuccess: 'We have just sent you an email message.',
	supportSuccess: 'Message sent.',
};
