import { FC } from 'react';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import image from 'assets/dist/img/success-icon.svg';
import { ROUTES } from 'routes/constants';
import { IPopUp } from '../types';

const SuccessUpdateTwoFaPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	const history = useHistory();
	const handleCloseModal = () => {
		history.push(ROUTES.LOGIN);
		closeModal();
	};

	return (
		<Popup open={open} closeOnDocumentClick onClose={handleCloseModal}>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
				<button className="popup__close-btn" onClick={handleCloseModal} />
				<div className="popup-header">
					<p className="popup-header__title">Successful</p>
				</div>
				<div className="popup-body">
					<div className="popup-img">
						<img src={image} alt="icon" />
					</div>
					<div className="popup-text popup-text--mt popup-text--mb-0 popup-text--center">
						<p>Your 2FA code has been successfully reset.</p>
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit">
						<button type="button" className="button button--full-width" onClick={handleCloseModal}>
							Continue
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default SuccessUpdateTwoFaPopUp;
