// ==========================================:
import React from 'react';
import logo from 'assets/dist/img/logo-footer.svg';
import { popUpOpen } from 'redux/reducers/popUp/reducer';
import { navList } from 'routes/routesList';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

const Footer = () => {
	const dispatch = useDispatch();
	return (
		<footer className="footer">
			<div className="footer-container">
				<a href="!#" className="logo">
					<img alt="Logo" src={logo} />
				</a>
				<div className="footer-nav">
					<ul>
						<li>
							<NavLink to={navList.termsOfUse.path}>Terms of Use</NavLink>
						</li>
						<li>
							<NavLink to={navList.privacyPolicy.path}>Privacy Policy</NavLink>
						</li>
						<li>
							<button
								onClick={() => {
									dispatch(popUpOpen('supportSuccess'));
								}}
								type="submit"
								id="footer_support_btn"
							>
								Support
							</button>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
};
export default Footer;
