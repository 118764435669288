import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import { getTradeData } from 'redux/reducers/trade/selectors';
import { tradeInitState } from 'redux/reducers/trade/reducer';
import { tradeInfoInitState } from 'redux/reducers/transactions/reducer';
import { IPopUp } from '../types';

const ErrorTrade: FC<IPopUp> = ({ open, closeModal }) => {
	const dispatch = useDispatch();
	const trade = useSelector(getTradeData);
	const closeHandler = () => {
		closeModal();
		dispatch(tradeInitState());
		dispatch(tradeInfoInitState());
	};
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeHandler}>
			<div className="popup">
				<div className="popup-header">
					<p className="popup-header__title red">Exchange error</p>
				</div>
				<div className="popup-body">
					<div className="popup-text">
						<p>
							Your account does not have enough{' '}
							<span className="element---uppercase">{trade?.from_asset_code}</span> to conduct an
							exchange operation.
						</p>
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit">
						<button type="button" onClick={closeHandler} className="button button--full-width">
							Ok
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default ErrorTrade;
