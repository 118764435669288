import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import Popup from 'reactjs-popup';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { createCryptoWithdrawRequest } from 'redux/reducers/transactions/reducer';
import { roundingNumber } from 'services/utils/roundingNumber';
import InputTotp from 'ui/Formik/InputTotp';
import IconSvg from 'ui/Svg/IconSvg';
import { IPropsPopUp } from './types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';

const ConfirmCryptoWithdrawal: FC<IPropsPopUp> = ({
	open,
	closeModal,
	openSuccess,
	amount,
	fee,
	address,
	network,
	code,
	assetName,
	asset_id,
	chain_id,
	address_id,
}) => {
	const dispatch = useDispatch();
	const transactionSubmit = (totp: string) => {
		dispatch(
			createCryptoWithdrawRequest({
				asset_id: Number(asset_id),
				chain_id: Number(chain_id),
				amount: Number(amount),
				address_id: Number(address_id),
				totp,
				openSuccess,
			}),
		);
	};

	const validationSchema = yup.object().shape({
		totp: yup
			.string()
			.required(notificationsInfoFields.totp.required)
			.length(6, notificationsInfoFields.totp.length)
			.matches(/\d/, notificationsInfoFields.totp.matches),
	});

	return (
		<Popup open={open} onClose={closeModal}>
			<div className="modal">
				<div className="popup popup--width-520">
					<div className="popup-header">
						<p className="popup-header__title">Confirm Withdrawal</p>
					</div>
					<Formik
						initialValues={{ totp: '' }}
						validationSchema={validationSchema}
						onSubmit={(value) => {
							transactionSubmit(value.totp);
							closeModal();
						}}
					>
						{() => (
							<Form className="form">
								<div className="popup-body">
									<div className="successful-info">
										<div className="successful-info__item">
											<p>Cryptocurrency:</p>
											<span>
												{code} ({assetName})
											</span>
										</div>
										<div className="successful-info__item">
											<p>Wallet Address:</p>
											<span>{address}</span>
										</div>
										<div className="successful-info__item">
											<p>Network:</p>
											<span>{network.toUpperCase()}</span>
										</div>
										<div className="successful-info__item">
											<p>Amount Requested:</p>
											<span>
												{roundingNumber(Number(amount), code)} {code}
											</span>
										</div>
										<div className="successful-info__item">
											<p>Withdrawal Fee:</p>
											<span>
												{roundingNumber(Number(fee), code)} {code}
											</span>
										</div>
										<div className="successful-info__item">
											<p>Withdrawal Amount:</p>
											<span>
												{roundingNumber(Number(amount) - Number(fee), code)} {code}
											</span>
										</div>
									</div>
									<div className="error-block">
										<div className="error-block__icon">
											<IconSvg name="error-circle" w="20" h="20" />
										</div>
										<p>
											Ensure that the Withdrawal Address and Network are correct. If in doubt,
											please send a small test withdrawal first. Withdrawals cannot be cancelled or
											reversed.
										</p>
									</div>
									<div className="amount-input">
										<Field
											title="Authentication Code"
											type="text"
											placeholder="Please enter valid Authentication Code"
											name="totp"
											required
											component={InputTotp}
										/>
									</div>
								</div>
								<div className="popup-footer">
									<div className="popup-submit popup-submit--type2">
										<button
											type="submit"
											className="button button--full-width"
											aria-label="form-submit"
										>
											Confirm Withdrawal
										</button>
										<button
											type="button"
											onClick={closeModal}
											className="button button--type2 button--full-width"
										>
											Cancel
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</Popup>
	);
};

export default ConfirmCryptoWithdrawal;
