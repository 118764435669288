import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBonusSystemData, getReferralsCount } from 'redux/reducers/bonusSystem/selectors';
import {
	getBonusSystemRequest,
	getReferralsCountRequest,
	transferBonusWalletRequest,
} from 'redux/reducers/bonusSystem/reducer';

import Auth from 'layouts/Auth';
import { popUpOpen } from 'redux/reducers/popUp/reducer';
import { roundingNumber } from '../../../services/utils/roundingNumber';

const BonusSystem = () => {
	const dispatch = useDispatch();
	const referralsCount = useSelector(getReferralsCount);
	const bonusSystemData = useSelector(getBonusSystemData);
	useEffect(() => {
		dispatch(getBonusSystemRequest());
		dispatch(getReferralsCountRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const transferBonusWallet = (id: number) => {
		dispatch(transferBonusWalletRequest(id));
	};
	return (
		<Auth>
			<section className="bonus-system-section">
				<div className="container">
					<div className="bonus-system">
						<div className="page-title">
							<p>Referrals</p>
						</div>
						<div className="bonus-title">
							<p>Referred Clients: {referralsCount}</p>
						</div>
						<div className="bonus-title bonus-title--type2">
							<p>Referral Fees</p>
						</div>
						<div className="bonus-system-list">
							{!!bonusSystemData &&
								bonusSystemData.map(({ id, asset, balance }) => (
									<div className="bonus-system-item" key={id}>
										<div className="bonus-system-item__currency">
											<p>Award Balance:</p>
											<span className="coin coin--type4">
												<span className="coin__icon">
													<img src={asset.img_path} alt={asset.code} />
												</span>
												<span className="coin__text element---uppercase">{asset.code}</span>
											</span>
										</div>
										<div className="bonus-system-item__balance">
											<p>{roundingNumber(balance, asset?.code)}</p>
										</div>
										<div className="bonus-system-item__button">
											<button
												type="button"
												className="button button--size2"
												// disabled={!(balance > 0)}
												onClick={() => {
													if (balance > 0) {
														transferBonusWallet(id);
													}
												}}
											>
												Transfer to Wallet
											</button>
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</section>
		</Auth>
	);
};

export default BonusSystem;
