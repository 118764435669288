import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { changeSourceOfFunds } from './reducer';
import { ICompanyData } from './types';
import { getCompanyData } from './selectors';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';
// =============================================================:

// =============================================================:
function* companyDataRequestWorker() {
	try {
		yield put(showLoading());
		const companyData: ICompanyData = yield select(getCompanyData);
		yield call(api.confirmation.updateCompanyData, companyData);
		notificationContainer(notificationsMessagesInfo.CompanySaved, 'success');
	} catch (error) {
		// console.log(error)
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* companySaga() {
	yield takeEvery(changeSourceOfFunds.type, companyDataRequestWorker);
}
