import React, { FC, useEffect, useMemo, useState } from 'react';
import Select from '../index';
import { IWalletItem, IWalletSelect, IWalletSelectCustomList } from './types';
import { roundingNumber } from '../../../../services/utils/roundingNumber';

const WalletSelectCustomInput: FC<IWalletItem> = (props) => {
	const {
		asset: { code, name, img_path: imgPath },
	} = props;
	return (
		<span className="coin coin--type3" style={{ display: name && code ? '' : 'none' }}>
			{imgPath && (
				<span className="coin__icon">
					<img src={imgPath} alt={code} />
				</span>
			)}
			<span className="coin__text-wrap">
				<span className="coin__text upper__text">
					{code}
					<span className="coin__text-more">{name}</span>
				</span>
			</span>
		</span>
	);
};
const WalletSelectCustomList: FC<IWalletSelectCustomList> = ({ arr, onChange }) => (
	<>
		{arr &&
			arr.map((el) => (
				<li key={el.asset.code}>
					<button type="button" onClick={() => onChange(el)}>
						<span className="coin coin--type3">
							{el.asset.img_path && (
								<span className="coin__icon">
									<img src={el.asset.img_path} alt={el.asset.code} />
								</span>
							)}
							<span className="coin__text-wrap">
								<span className="coin__text upper__text coin__text---pt-0">
									{el.asset.code} <br />
									<span className="coin__text-more coin__text-more--type2">{el.asset.name}</span>
								</span>
								<span className="coin__text-balance">
									<span className="coin__text-balance-title">Total Balance</span>
									<span className="coin__text-balance-value">
										{' '}
										{roundingNumber(el.balance, el.asset.code)}
									</span>
								</span>
							</span>
						</span>
					</button>
				</li>
			))}
	</>
);

const WalletSelect: FC<IWalletSelect> = ({
	arr,
	resetCustomSelect,
	setResetCustomSelect,
	onChange,
	activeValue,
	...props
}) => {
	const initActiveWallet = useMemo(
		() => ({
			asset: {
				code: '',
				name: '',
			},
		}),
		[],
	);

	const [activeWallet, setActiveWallet] = useState<IWalletItem>(initActiveWallet);
	const [searchCurrency, setSearchCurrency] = useState<IWalletItem[]>([]);

	useEffect(() => {
		if (arr) {
			setSearchCurrency(arr);
		}
	}, [arr]);

	useEffect(() => {
		if (resetCustomSelect) {
			setActiveWallet(initActiveWallet);
			!!setResetCustomSelect && setResetCustomSelect(false);
		}
	}, [initActiveWallet, resetCustomSelect, setResetCustomSelect]);

	useEffect(() => {
		setActiveWallet(activeValue || initActiveWallet);
	}, [activeValue, initActiveWallet]);

	const onChangeHandler = (el: IWalletItem) => {
		setActiveWallet(el);
		!!onChange && onChange(el);
	};

	const customList = {
		list: <WalletSelectCustomList arr={searchCurrency} onChange={onChangeHandler} />,
		activeElement: activeWallet.asset.code,
	};

	return (
		<Select
			{...props}
			customInput={<WalletSelectCustomInput {...activeWallet} />}
			customList={customList}
			searchField
			setSearchCurrency={setSearchCurrency}
			arr={arr}
		/>
	);
};

export default WalletSelect;
