/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getBankAccounts,
	getBankAccountsPageInfo,
	getBankAccountsPagesCount,
} from 'redux/reducers/bankAccounts/selectors';
import {
	changeBankAccountsPage,
	deleteBankAccountRequest,
	getBankAccountsRequest,
} from 'redux/reducers/bankAccounts/reducer';
import Pagination from 'components/Pagination/Pagination';
import TableHeader from 'components/PaymentMethods/PaymentMethodsTable/TableHeader/TableHeader';
import TableBodyNoData from 'components/PaymentMethods/PaymentMethodsTable/TableBodyNoData/TableBodyNoData';
import TableBodyBankAccount from 'components/PaymentMethods/PaymentMethodsTable/TableBodyBankAccount/TableBodyBankAccount';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';

export interface IBankAccountsList {
	onUpdate: (id: number) => void;
}

const BankAccountsList: FC<IBankAccountsList> = ({ onUpdate }) => {
	const bankAccountsData = useSelector(getBankAccounts);
	const bankAccountsPageInfo = useSelector(getBankAccountsPageInfo);
	const bankAccountsPagesCount = useSelector(getBankAccountsPagesCount);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			changeBankAccountsPage({
				per_page: 5,
				current_page: 1,
			}),
		);
		// dispatch(getBankAccountsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [countries, setCountries] = useState<Array<{ value: string; label: string }> | []>([]);

	useEffect(() => {
		fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code')
			.then((response) => response.json())
			.then((data) => {
				setCountries(data.countries);
			});
	}, []);

	const handlePageClick = ({ selected }: { selected: number }) => {
		const newPageInfo = { ...bankAccountsPageInfo, current_page: selected + 1 };
		dispatch(changeBankAccountsPage(newPageInfo));
	};

	const onDelete = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				message: `Your bank account "${nickname}" was deleted successfully.`,
				confirmButtonTitle: 'Close',
			}),
		);
		dispatch(deleteBankAccountRequest({ id }));
	};

	const deleteHandler = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				title: 'Delete Bank Account',
				message: 'Are you sure you want to delete the account?',
				confirmButtonTitle: 'Delete',
				confirmHandler: () => onDelete(id, nickname),
			}),
		);
		dispatch(popUpOpen('warningPopUp'));
	};

	useEffect(() => {
		if (
			bankAccountsPageInfo.current_page !== 1 &&
			bankAccountsPageInfo.current_page >
				Math.ceil(bankAccountsPagesCount / bankAccountsPageInfo.per_page)
		) {
			const newPageInfo = {
				...bankAccountsPageInfo,
				current_page: bankAccountsPageInfo.current_page - 1,
			};
			dispatch(changeBankAccountsPage(newPageInfo));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bankAccountsPageInfo]);

	return (
		<>
			{bankAccountsData.length ? (
				<>
					{bankAccountsData.map((el) => {
						const bankCountry =
							countries.find((item) => item.value === el.bank_country)?.label || el.bank_country;
						const beneficiaryCountry =
							countries.find((item) => item.value === el.beneficiary_country)?.label ||
							el.beneficiary_country;
						return (
							<div className="payment-methods" key={el.id}>
								<TableHeader
									onUpdate={() => onUpdate(el.id)}
									onDelete={() => deleteHandler(el.id, el.bank_nickname)}
								>
									<p>{el.bank_nickname}</p>
								</TableHeader>
								<TableBodyBankAccount
									{...el}
									bank_country={bankCountry}
									beneficiary_country={beneficiaryCountry}
								/>
							</div>
						);
					})}
					<div className="pagination-block">
						<Pagination
							pageCount={Math.ceil(bankAccountsPagesCount / bankAccountsPageInfo.per_page)}
							onPageChange={handlePageClick}
							forcePage={bankAccountsPageInfo.current_page}
						/>
					</div>
				</>
			) : (
				<div className="payment-methods">
					<TableHeader empty /> <TableBodyNoData />
				</div>
			)}
		</>
	);
};

export default BankAccountsList;
