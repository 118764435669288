import React from 'react';
import CreatePassword from './CreatePassword/CreatePassword';

const Confirmation = () => {
	return (
		<>
			<CreatePassword />
		</>
	);
};
export default Confirmation;
