import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTradeHistoryRequest } from 'redux/reducers/transactions/reducer';
import { getTradeHistoryList } from 'redux/reducers/transactions/selectors';
import { Link } from 'react-router-dom';
import TradeTableBody from '../TradeHistory/TradeTableBody/TradeTableBody';
import TradeTableHeader from '../TradeHistory/TradeTableHeader/TradeTableHeader';

const TradeHistoryWithoutPagination = () => {
	const dispatch = useDispatch();

	const itemList = useSelector(getTradeHistoryList);

	useEffect(() => {
		dispatch(getTradeHistoryRequest({ apiParams: { per_page: 5 } }));
	}, [dispatch]);

	return (
		<div className="table-block table-block--mt-0">
			<div className="table-wrapper">
				<div className="table table--transaction-history-trade">
					<TradeTableHeader />
					<TradeTableBody itemList={itemList} />
				</div>
				{!!itemList?.length && (
					<div className="table-button">
						<Link to="transactions-trade/trade" className="button button--size1 button--type2">
							View more
						</Link>
					</div>
				)}
			</div>
		</div>
	);
};

export default TradeHistoryWithoutPagination;
