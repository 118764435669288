import { FC, useEffect, useState } from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { setInit } from 'redux/reducers/app/reducer';
// import { setCountriesCurrent } from '../../redux/country/current/operations';
// import { setCountriesList } from '../../redux/country/list/operations';
import Init from './Init';
// import setLanguage from '../../redux/language/action';
// import { authLogout } from '../../redux/auth/operations';
// import notification from '../../services/notification';
// import routes from '../../routes';
// import { SEND_MESSAGE_TO_PHONE_STARTED } from '../../redux/types';
/* eslint-disable no-debugger */
import { ILoacalSrtorage } from './type';

// const err = { err: true };
const LocaleStorage: FC<ILoacalSrtorage> = ({ children }) => {
	// const dispatch = useDispatch();
	// const history = useHistory();
	let timeoutId: any;

	useEffect(() => {
		return () => {
			clearTimeout(timeoutId);
		};
	}, [timeoutId]);

	// axios.interceptors.request.use(
	// 	(config) => {
	// 		const newConfig = config;
	// 		if (config.url === '/api/current_country') {
	// 			delete newConfig.headers.common.Authorization;
	// 			return newConfig;
	// 		}
	// 		return newConfig;
	// 	},
	// 	(error) => {
	// 		return Promise.reject(error);
	// 	},
	// );

	// axios.interceptors.response.use(
	// 	(response) => {
	// 		if (response.config.url === '/api/login') {
	// 			const bearerToken = response.data.bearer_token;
	// 			if (bearerToken) {
	// 				axios.defaults.headers.common.Authorization = `Bearer ${bearerToken}`;
	// 				history.replace(routes.Marketplace.path);
	// 			}
	// 		}
	// 		if (response.config.url === '/api/logout' && response.status === 204) {
	// 			delete axios.defaults.headers.common.Authorization;
	// 		}
	// 		if (response.config.url === '/api/user/confirm_deactivate' && response.status === 204) {
	// 			history.replace(routes.AuthLogin.path);
	// 			dispatch(authLogout());
	// 		}
	// 		return response;
	// 	},
	// 	(error) => {
	// 		if (error.response && error.response.status === 401) {
	// 			delete axios.defaults.headers.common.Authorization;
	// 			if (err.err) {
	// 				dispatch(
	// 					authLogout(
	// 						{
	// 							beck: false,
	// 							deactivation: false,
	// 						},
	// 						history,
	// 					),
	// 				);
	// 				notification({
	// 					type: 'info',
	// 					message: L.translate('t_0306'),
	// 				});

	// 				err.err = false;
	// 				timeoutId = setTimeout(() => {
	// 					err.err = true;
	// 				}, 8000);
	// 			}
	// 		}
	// 		return Promise.reject(error);
	// 	},
	// );
	const [start, setStart] = useState<boolean>();
	const trottle = () => {
		setStart(true);
	};
	useEffect(() => {
		setTimeout(trottle, 400);
	}, []);
	return (
		<>
			{/* <ListenerElement name="socketToken" setStore={setSocketToken} /> */}
			{start && (
				<>
					{/* <ListenerElement name="language" setStore={setLanguage} /> */}
					{/* <ListenerElement name="bearerToken" setStore={setBearerToken} /> */}
					<Init setStore={setInit} />
					{children}
				</>
			)}
		</>
	);
};

const init = () => <></>;

LocaleStorage.defaultProps = {
	children: init,
};

// LocaleStorage.propTypes = {
// 	children: PropTypes.node,
// };

export default LocaleStorage;
// eslint-disable-next-line
