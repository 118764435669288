import { call, put, takeEvery, select } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import {
	changeBankAccountsPage,
	getBankAccountsRequest,
	updateBankAccountRequest,
	deleteBankAccountRequest,
	createBankAccountRequest,
	getBankAccountsSuccess,
} from './reducer';
import {
	IBankAccountGetResponse,
	IBankAccountsPageInfo,
	IBankAccountRequestPayload,
	IBankAccountRequestPayloadWithId,
} from './types';
import { getBankAccountsPageInfo } from './selectors';
import { notificationsMessagesError } from '../../../services/utils/notificationsMessages/notificationsMessagesError';
import { notificationsMessagesPopUp } from '../../../services/utils/notificationsMessages/notificationsMessagesPopUp';

// =============================================================:

// =============================================================:
function* getBankAccountsRequestWorker() {
	try {
		yield put(showLoading());
		const getPageInfo: IBankAccountsPageInfo = yield select(getBankAccountsPageInfo);
		const response: IBankAccountGetResponse = yield call(
			api.bankAccounts.getBankAccounts,
			getPageInfo,
		);
		yield put(getBankAccountsSuccess(response));
	} catch (error) {
		notificationContainer(notificationsMessagesError.bankAccounts, 'error');
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* deleteBankAccountsRequestWorker(action: PayloadAction<{ id: number }>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.bankAccounts.deleteBankAccount, payload.id);
		yield put(getBankAccountsRequest());
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bankDelete }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* createBankAccountsRequestWorker(action: PayloadAction<IBankAccountRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.bankAccounts.createBankAccount, payload);
		yield put(getBankAccountsRequest());
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bankSuccessfully }));
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bankNotCreated }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* updateBankAccountsRequestWorker(action: PayloadAction<IBankAccountRequestPayloadWithId>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.bankAccounts.updateBankAccount, payload);
		yield put(getBankAccountsRequest());
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bankSuccessfully }));
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bankNotUpdate }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
export function* bankAccountsSaga() {
	yield takeEvery(getBankAccountsRequest.type, getBankAccountsRequestWorker);
	yield takeEvery(updateBankAccountRequest.type, updateBankAccountsRequestWorker);
	yield takeEvery(deleteBankAccountRequest.type, deleteBankAccountsRequestWorker);
	yield takeEvery(createBankAccountRequest.type, createBankAccountsRequestWorker);
	yield takeEvery(changeBankAccountsPage.type, getBankAccountsRequestWorker);
}
