import { Redirect, Switch, useRouteMatch } from 'react-router';
import HistoryHeader from './HistoryHeader/HistoryHeader';

const TransactionHistory = () => {
	const { path: matchPath } = useRouteMatch();

	return (
		<div className="wallet-operations">
			<HistoryHeader />
			<Switch>
				{/* <Route exact path={`${matchPath}/crypto`} component={CryptoHistory} /> */}
				{/* <Route exact path={`${matchPath}/fiat`} component={FiatHistory} /> */}
				{/* <Route exact path={`${matchPath}/trade`} component={TradeHistory} /> */}

				<Redirect to={`${matchPath}/crypto`} />
			</Switch>
		</div>
	);
};

export default TransactionHistory;
