export const notificationsInfoFields = {
	// login
	email: {
		required: 'Please enter your email.',
		invalid: 'Please enter a valid email address.',
		maxLength: 'Max characters must be not more than 60.',
	},
	totp: {
		required: 'Please enter 2FA code.',
		invalid: 'Only numbers.',
		length: 'Please enter 6 character 2FA code.',
		matches: 'Only numbers',
	},
	password: {
		required: 'Please enter your password.',
		invalid:
			'Password must include: Minimum length of 8 characters; 1 uppercase letter; 1 lowercase letter; 1 number; and 1 special character.',
		maxLength: 'Max characters must be not more than 25.',
	},
	// deposit fiat
	currency: {
		required: 'Please enter your Other Currency.',
	},
	amount: {
		required: 'Please enter Amount.',
		min: `Minimum amount`,
		max: `Maximum amount`,
	},
	// create password
	passwordValidation: {
		required: 'Please enter your password',
		matches:
			'Password must include: Minimum length of 8 characters; 1 uppercase letter; 1 lowercase letter; 1 number; and 1 special character.',
		maxLength: 'Max characters must be not more than 25.',
		passwordsMatch: 'Passwords must match.',
	},
	// create password form
	validationTexts: {
		passwordRequired: 'Please enter your password.',
		passwordMatches:
			'Password must include: Minimum length of 8 characters; 1 uppercase letter; 1 lowercase letter; 1 number; and 1 special character.',
		passwordMax: 'Max characters must be not more than 25.',
		confirmPasswordRequired: 'Passwords must match.',
		confirmPasswordOneOf: 'Passwords must match.',
	},
	// BankAccountForm // AddBankAccount
	validationMessages: {
		bank_nickname: {
			required: 'Please enter your Bank Name.',
			min: `Too short. Minimum ${1} characters.`,
			max: `Too long. Maximum ${100} characters.`,
		},
		beneficiary_name: {
			required: 'Please enter your Beneficiary Name.',
			min: `Too short. Minimum ${1} characters.`,
			max: `Too long. Maximum ${100} characters.`,
		},
		beneficiary_address: {
			required: 'Please enter your Beneficiary Address.',
			min: `Too short. Minimum ${1} characters.`,
			max: `Too long. Maximum ${300} characters.`,
		},
		beneficiary_country: {
			required: 'Please, select your Beneficiary Country.',
		},
		bank_name: {
			required: 'Please enter your Bank Name.',
			min: `Too short. Minimum ${1} characters.`,
			max: `Too long. Maximum ${100} characters.`,
		},
		bank_address: {
			required: 'Please enter your Bank Address.',
			min: `Too short. Minimum ${1} characters.`,
			max: `Too long. Maximum ${200} characters.`,
		},
		bank_country: {
			required: 'Please, select your Bank Country.',
		},
		swift_bic: {
			required: 'Please enter your SWIFT / BIC.',
			min: `Too short. Minimum ${1} characters.`,
			max: 'The swift bic must not be greater than 11 characters.',
		},
		account_number: {
			required: 'Please enter your Account Number.',
			min: `Too short. Minimum ${1} characters.`,
			max: 'The account number must not be greater than 16 characters.',
		},
		note: {
			max: `Too long. Maximum ${100} characters.`,
		},
	},
	// WalletAddressForm
	nickname: {
		required: 'Please enter your Wallet Nickname.',
		matches: 'Please enter valid nickname',
		min: `Too short. Minimum ${1} characters.`,
		max: `Too long. Maximum ${100} characters.`,
	},
	address: {
		required: 'Please enter your Wallet Address.',
		min: `Too short. Minimum ${1} characters.`,
		max: `Too long. Maximum ${100} characters.`,
		matches: 'Please enter valid address',
	},
	// reset password
	reason: {
		required: 'Please explain reason for password reset.',
		max: 'Max characters must be not more than 120.',
	},
	// to wa setting
	digit_code: {
		matches: 'Please enter only numbers for the 2FA code.',
		length: 'Please enter a 6 digit 2FA code.',
	},
	// add wallet address
	// nickname: {
	// 	required: 'Please enter your Wallet Nickname.',
	// 	matches: 'Please enter valid nickname',
	// },
	// currency: {
	// 	required: 'Please enter Currency.',
	// },
	// address: {
	// 	required: 'Please enter your Wallet Address.',
	// 	matches: 'Please enter valid address',
	// },
	network: {
		required: 'Please enter Network.',
	},
	bank: {
		required: 'Please enter your Other Bank Account.',
	},
	fullName: {
		min: `Too short. Minimum ${1} characters.`,
		max: `Too long. Maximum ${50} characters.`,
		required: 'Please enter your full name.',
	},
	phone: {
		required: 'Please enter your phone.',
		matches: 'Incorrect number format',
	},
	message: {
		min: 'Too Short.',
		max: `Too long. Maximum ${255} characters.`,
	},
	// withdraw crypto page
	required: 'Please fill in this field.',
	wallet: {
		required: 'Please enter your Other Wallet.',
	},
};
