/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-duplicates
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICurrencyDataItem, ICurrencyStore, IFeesData } from 'redux/reducers/currency/types';

// ==========================================:
export const initialState: ICurrencyStore = { data: [], fees: null, loading: false };

// ==========================================:2
const currency = createSlice({
	name: '@@currency',
	initialState,
	reducers: {
		currencyDataRequest: (state) => {
			state.loading = true;
		},
		currencyDataSuccess: (state, action: PayloadAction<ICurrencyDataItem[]>) => {
			const { payload } = action;
			state.data = payload.sort((a, b) => {
				if (a.code > b.code) return 1;
				if (a.code < b.code) return -1;
				return 0;
			});
			state.loading = false;
		},
		feeDataRequest: (state) => {
			state.loading = true;
		},
		feeDataSuccess: (state, { payload }: PayloadAction<IFeesData>) => {
			state.fees = payload;
			state.loading = false;
		},
	},
});

export default currency.reducer;
export const { currencyDataRequest, currencyDataSuccess, feeDataRequest, feeDataSuccess } =
	currency.actions;
