import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';

import Auth from 'layouts/Auth';
import IconSvg from 'ui/Svg/IconSvg';
import Input from 'ui/Formik/Input';
import { getWalletsFiatList } from 'redux/reducers/wallets/selectors';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import WalletSelect from 'ui/Formik/Select/WalletSelect';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import { getBankAccounts } from 'redux/reducers/bankAccounts/selectors';
import {
	changeBankAccountsPage,
	getBankAccountsRequest,
} from 'redux/reducers/bankAccounts/reducer';
import BankSelect from 'ui/Formik/Select/BankSelect';
import ConfirmFiatWithdrawal from 'layouts-elements/PopUp/ConfirmFiatWithdrawal/ConfirmFiatWithdrawal';
import SuccessFiatWithdrawal from 'layouts-elements/PopUp/SuccessFiatWithdrawal/SuccessFiatWithdrawal';
import { getFees } from 'redux/reducers/currency/selectors';
import { feeDataRequest } from 'redux/reducers/currency/reducer';
import { IBankAccount } from 'redux/reducers/bankAccounts/types';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { roundingNumber } from 'services/utils/roundingNumber';
import AddBankAccount from 'layouts-elements/PopUp/AddBankAccount/AddBankAccount';
import { feeRequest } from '../../../redux/reducers/deposits/reducer';
import { getFee } from '../../../redux/reducers/deposits/selectors';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';

const WithdrawFiatPage = () => {
	const dispatch = useDispatch();
	const walletsFiat = useSelector(getWalletsFiatList);
	const bankAccounts = useSelector(getBankAccounts);
	const fees = useSelector(getFees);
	const history = useHistory();
	const [selectedWallet, setSelectedWallet] = useState<IWalletItem | null>(null);
	const [selectedBank, setSelectedBank] = useState<IBankAccount | null>(null);
	const [resetCustomSelect, setResetCustomSelect] = useState(false);

	const withdrawMin = Number(selectedWallet?.asset.withdraw_min);
	const withdrawMax =
		Number(selectedWallet?.balance) < Number(selectedWallet?.asset.withdraw_max)
			? Number(selectedWallet?.balance)
			: Number(selectedWallet?.asset.withdraw_max);
	const code = selectedWallet?.asset.code.toUpperCase() || '';
	const assetName = selectedWallet?.asset.name || '';

	const getCalcAmount = useCallback(
		(value: string) => {
			const numValue = Number(value);
			let percent = 0;
			let receivedFee = 0;
			if (selectedWallet && fees) {
				percent = fees[selectedWallet?.asset.id].withdraw_fee_percent;
				const percentFix = fees[selectedWallet?.asset.id].withdraw_fee_fixed;
				receivedFee = (percent * numValue) / 100;
				if (receivedFee < percentFix) {
					receivedFee = percentFix;
				}
			}
			const fee = receivedFee > 0 ? roundingNumber(receivedFee, 'fiat') : '0';
			const receive =
				numValue - receivedFee > 0 ? roundingNumber(numValue - receivedFee, 'fiat') : '0';
			return { fee, receive };
		},
		[fees, selectedWallet],
	);

	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);
	const [openModalSuccess, setOpenModalSuccess] = useState(false);
	const [openBankAccountModal, setOpenBankAccountModal] = useState(false);

	const handleOpenModalSuccess = () => setOpenModalSuccess(true);
	const handleCloseModalSuccess = () => {
		setOpenModalSuccess(false);
		history.push('/wallets');
	};

	const initialValues = {
		currency: '',
		bank: '',
		amount: '',
	};

	const validationSchema = yup.object().shape({
		currency: yup.string().required(notificationsInfoFields.currency.required),
		bank: yup.string().required(notificationsInfoFields.bank.required),
		amount: yup
			.number()
			.required(notificationsInfoFields.amount.required)
			.min(withdrawMin, `${notificationsInfoFields.amount.min} ${withdrawMin}`)
			.max(withdrawMax, `${notificationsInfoFields.amount.max} ${withdrawMax}`),
	});

	useEffect(() => {
		dispatch(getWalletsRequest());
		dispatch(changeBankAccountsPage({ per_page: 0, current_page: 1 }));
		dispatch(getBankAccountsRequest());
		dispatch(feeDataRequest());
		if (localStorage.asset && walletsFiat) {
			const currentCoin =
				walletsFiat.find(
					(el: IWalletItem) => el.id === Number(JSON.parse(localStorage.asset).asset_id),
				) || null;

			setSelectedWallet(currentCoin);
		}
		return () => {
			localStorage.removeItem('asset');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedWallet) setResetCustomSelect(true);
	}, [selectedWallet]);

	useEffect(() => {
		if (!localStorage.asset && walletsFiat) {
			const currentCoin =
				walletsFiat.find(
					(el: IWalletItem) => el.asset.code.toLowerCase() === 'eur' && el.asset.withdrawable,
				) || null;
			setSelectedWallet(currentCoin);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [walletsFiat]);

	const handleAddAccountClick = () => {
		setOpenBankAccountModal(true);
	};

	const closeBankAccountModal = () => {
		setOpenBankAccountModal(!openBankAccountModal);
	};
	const fee = useSelector(getFee);
	return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					<div className="wallet-operations">
						<div className="wallet-operations-header-wrap">
							<div className="wallet-operations-header">
								<Link to="/wallets" className="wallet-operations-header__back">
									<IconSvg name="arrow-left" w="24" h="24" />
								</Link>
								<p>Withdraw Fiat</p>
							</div>
							<div className="wallet-operations-header-right">
								<Link to="/withdraw-crypto" className="button button--size2 button--type2">
									Withdraw Crypto
								</Link>
								<Link to="/withdraw-crypto" className="wallet-operations-header__back">
									<IconSvg name="arrow-right" w="24" h="24" />
								</Link>
							</div>
						</div>
						<Formik
							validationSchema={validationSchema}
							initialValues={initialValues}
							onSubmit={() => {
								setOpenModal(true);
							}}
						>
							{({ values, resetForm, setFieldTouched, touched, errors }) => {
								const clearData = () => {
									setResetCustomSelect(true);
									setSelectedWallet(null);
									resetForm();
								};
								if (Number(values.amount) !== 0) {
									const payload = {
										asset_id: selectedWallet?.asset.id,
										type: 'withdraw',
										amount: values.amount,
									};
									dispatch(feeRequest(payload));
								}
								return (
									<Form className="form">
										<div className="wallet-operations-item coin__text-normal">
											<div className="wallet-operations-item__title">
												<span>1</span>
												<p>Select Fiat Currency</p>
											</div>
											<Field
												type="text"
												placeholder="Select Currency"
												dropdownTitle="Select Currency"
												name="currency"
												required
												component={WalletSelect}
												arr={walletsFiat?.filter((el) => el.asset.withdrawable)}
												onChange={setSelectedWallet}
												activeValue={selectedWallet || undefined}
											/>

											<div className="wallet-operations-item__title">
												<span>2</span>
												<p>Select Bank Account</p>
											</div>
											<Field
												setTouched={() => setFieldTouched('bank')}
												touched={touched.bank}
												type="text"
												placeholder="Choose Bank Account"
												dropdownTitle="Bank Account"
												name="bank"
												required
												component={BankSelect}
												buttonName="Add Bank Account"
												buttonClick={handleAddAccountClick}
												arr={bankAccounts}
												onChange={setSelectedBank}
												resetCustomSelect={resetCustomSelect}
												setResetCustomSelect={setResetCustomSelect}
												activeValue={{ value: selectedBank?.bank_name }}
											/>
											{touched.bank && !values.bank && (
												<div
													style={{ top: '-5px' }}
													className="input-notify input-notify--absolute"
												>
													<span className="input-notify__char">*</span>
													<span className="input-notify__text">{errors.bank}</span>
												</div>
											)}
										</div>
										{selectedWallet && (
											<>
												<div className="wallet-operations-item amount-input">
													<div className="wallet-operations-item__title">
														<span>3</span>
														<p>Withdrawal Amount</p>
													</div>
													<Field
														placeholder={
															selectedWallet
																? `Enter ${roundingNumber(withdrawMin, code)} - ${roundingNumber(
																		withdrawMax,
																		code,
																  )}`
																: ''
														}
														name="amount"
														type="number"
														required
														disabled={!code}
														component={Input}
														addonRight={code}
														buttonMax={withdrawMax}
													/>

													{!!selectedWallet?.balance && (
														<div className="input__notification input__notification--type4">
															<p>
																Available Balance:{' '}
																<span className="fz-16">
																	{roundingNumber(selectedWallet.balance, code)}{' '}
																	{code.toUpperCase()}
																</span>
															</p>
														</div>
													)}
												</div>
												<div className="wallet-operations-item">
													<div className="receive">
														<div className="wallet-operations-item__title">
															<span>4</span>
															<p>Withdrawal Confirmation</p>
														</div>
														<div className="receive-amount">
															<p>
																{roundingNumber(Number(values.amount) - Number(fee), code)} {code}
															</p>
															<span>
																Withdrawal Fee:{' '}
																<strong>
																	{roundingNumber(Number(fee), code)} {code.toUpperCase()}
																</strong>
															</span>
														</div>
													</div>
													<button type="submit" className="button button--full-width">
														Confirm Withdrawal
													</button>
												</div>
											</>
										)}
										{openBankAccountModal && (
											<AddBankAccount
												open={openBankAccountModal}
												closeModal={closeBankAccountModal}
											/>
										)}

										<ConfirmFiatWithdrawal
											open={openModal}
											closeModal={closeModal}
											openSuccess={handleOpenModalSuccess}
											indicatedAmount={values.amount}
											bankNickname={values.bank}
											code={code}
											assetName={assetName}
											clearParentForm={clearData}
											asset_id={selectedWallet?.asset.id}
											bank_id={selectedBank?.id}
											amount={values.amount}
											fee={Number(fee)}
										/>
										<SuccessFiatWithdrawal
											open={openModalSuccess}
											closeModal={handleCloseModalSuccess}
											amount={values.amount}
											bankNickname={values.bank}
											fee={Number(fee)}
											code={code}
											assetName={assetName}
										/>
									</Form>
								);
							}}
						</Formik>
					</div>
				</div>
			</section>
		</Auth>
	);
};

export default WithdrawFiatPage;
