import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from '../../types';
import { IWalletAddressStore } from './types';

// ==========================================:
const getAuthState = (state: IStoreState): IWalletAddressStore => state.walletAddresses;
export const getWalletAddressesData = createSelector(
	[getAuthState],
	(walletAddresses: IWalletAddressStore) => walletAddresses,
);

export const getWalletAddresses = createSelector(
	[getAuthState],
	(walletAddresses: IWalletAddressStore) => walletAddresses.data.data,
);

export const getWalletAddressesPageInfo = createSelector(
	[getAuthState],
	(walletAddresses: IWalletAddressStore) => ({
		per_page: walletAddresses.data.per_page,
		current_page: walletAddresses.data.current_page,
	}),
);

export const getWalletAddressesPagesCount = createSelector(
	[getAuthState],
	(walletAddresses: IWalletAddressStore) => walletAddresses.data.last_page,
);
