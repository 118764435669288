import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Confirmation from 'components/Confirmation/Registration';

const ConfirmationPage: FC = () => {
	return (
		<Dashboard title="Registration">
			<Confirmation />
		</Dashboard>
	);
};
export default ConfirmationPage;
