import React, { FC } from 'react';

import { useFormatDateTime } from 'hooks/useFormatDateTime';
import { capitalize, splitString } from 'services/utils/strings';
import { roundingNumber } from 'services/utils/roundingNumber';
import { ITradeTableRowProps } from '../type';

const TradeTableRow: FC<ITradeTableRowProps> = ({ item }) => {
	const [date, time] = useFormatDateTime(item.created_at);
	const idx1 = item.side === 'sell' ? 0 : 1;
	const idx2 = item.side === 'sell' ? 1 : 0;

	const assetFrom = splitString(item.pair, '_')[idx1].toUpperCase();
	const assetTo = splitString(item.pair, '_')[idx2].toUpperCase();

	// const rate = item.side === 'buy' ? 1 / item.rate : item.rate;

	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}
	const [oneText, twoText] = splitText(item.pair || 'btc_eur');
	return (
		<div key={item.id} className="tr">
			<div className="td td--timestamp">
				<div className="td-hidden-name">Timestamp</div>
				<p className="td--timestamp-date">{date}</p>
				<span className="td--timestamp-time">{time.replaceAll('-', ':')}</span>
			</div>
			<div className="td">
				<div className="td-hidden-name">Sell</div>
				<p>{assetFrom}</p>
			</div>
			<div className="td">
				<div className="td-hidden-name">Buy</div>
				<p>{assetTo}</p>
			</div>
			<div className="td">
				<div className="td-hidden-name">Amount Sold</div>
				<p>{roundingNumber(item.quantity, assetFrom)}</p>
			</div>
			<div className="td">
				<div className="td-hidden-name">Amount Received</div>
				<p>{roundingNumber(item.amount, assetTo)}</p>
			</div>
			<div className="td">
				<div className="td-hidden-name">Exchange Rate</div>
				<p>
					1 {oneText?.toUpperCase()} = {roundingNumber(item.price_b2c2 || 0, twoText)}{' '}
					{twoText.toUpperCase()}
				</p>
			</div>
			<div className="td td--nowrap">
				<div className="td-hidden-name">Fee</div>
				<p>{`${roundingNumber(item.fee, assetFrom)} ${capitalize(assetFrom)}`}</p>
			</div>
			<div className="td td--right td--nowrap">
				<div className="td-hidden-name">Tx ID</div>
				<p>{item.id}</p>
			</div>
		</div>
	);
};

export default TradeTableRow;
