import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';

export interface IPagination {
	pageCount: number;
	onPageChange: (selected: { selected: number }) => void;
	forcePage: number;
}

const Pagination: FC<IPagination> = ({ pageCount, onPageChange, forcePage }) => {
	return (
		<ReactPaginate
			breakLabel="..."
			onPageChange={onPageChange}
			pageCount={pageCount}
			forcePage={forcePage - 1}
			className="pagination"
			activeClassName="active"
			previousClassName="arrow"
			nextClassName="arrow"
			previousLabel={
				<svg
					width="10"
					height="16"
					viewBox="0 0 10 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M8.5 1L1.5 8L8.5 15"
						stroke="#777E90"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			}
			nextLabel={
				<svg
					width="10"
					height="16"
					viewBox="0 0 10 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1.5 1L8.5 8L1.5 15"
						stroke="#777E90"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			}
		/>
	);
};

export default Pagination;
