import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import Content from 'layouts-elements/Content';
import Footer from 'layouts-elements/Footer';
import HeaderTop from 'layouts-elements/Header';
import { useSelector } from 'react-redux';
import { IDashboard } from './types';

// ==========================================:
const Dashboard: FC<IDashboard> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>
			<div className="wrapper">
				{isAuthenticated && <HeaderTop />}
				<Content>{children}</Content>
				<Footer />
			</div>
		</>
	);
};

export default Dashboard;
